import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonIcon, IonFabButton, IonList, IonItem, IonLabel, IonToast, IonToggle } from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { briefcaseOutline, chevronDownOutline, chevronUpOutline, globeOutline, keyOutline, locationOutline, logOutOutline, mailOutline, notificationsOutline, pencilOutline, phonePortraitOutline } from "ionicons/icons";
import FormData from "form-data";

import {
    Camera,
    CameraResultType,
    CameraSource,
} from "@capacitor/camera";


import { Header } from "../../components";
import { logoutUser, updateUser, updateUserImage } from "../../redux/user/user.actions";
import { RootState } from "../../redux/reducers";
import { ProfileEditModal, ProfileImageModal, ProfilePasswordModal } from "./profileComponents";
import useWindowDimensions from "../../utils/screen-width";

import "./Profile.css";
import http from "../../utils/http-common";
import { EditFields, EditPasswordFields } from "./ProfileInterfaces";
import { ErrorToast } from "../statistics/StatisticsInterfaces";
import { mapProfession } from "./profileComponents/profile-helper";
import { format } from "date-fns";
import { isObjectEmpty } from "../../utils/general-helpers";

const Profile: React.FC = () => {
    const user = useSelector((state: RootState) => state.user.user, shallowEqual);
    const dispatch = useDispatch();
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [profileEditOpen, setProfileEditOpen] = useState<boolean>(false)
    const [profilePasswordOpen, setProfilePasswordOpen] = useState<boolean>(false);
    const [errorToast, setErrorToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" })
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const errorsFromApi = useSelector((state: RootState) => state.errors)

    const logout = async (e: any) => {
        e.preventDefault();
        dispatch(logoutUser());
    }

    useEffect(() => {
        if (!isObjectEmpty(errorsFromApi)) {
            setErrorToast({ open: true, message: errorsFromApi.message, color: "danger" })
        } else {
            setErrorToast({ open: false, message: "", color: "success" })
        }
    }, [errorsFromApi])

    const takePhoto = async () => {
        try {
            const cameraPhoto = await Camera.getPhoto({
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Photos,
                quality: 70,
                allowEditing: true,
                promptLabelHeader: "Foto",
                promptLabelCancel: "Abbrechen",
                promptLabelPhoto: "Galerie",
                promptLabelPicture: "Kamera",
                width: 480,
                height: 480,
            })

            let blob = await fetch(cameraPhoto.dataUrl).then(r => r.blob());
            uploadProfilePic(blob);

        } catch (e) {
            console.log(e)
        }
    }


    const uploadProfilePic = async (base64) => {
        let data = new FormData();
        data.append("id", user.id);
        data.append("image", base64);
        dispatch(updateUserImage(data));
    }

    const removeProfilePic = async () => {
        let data = new FormData();
        data.append("id", user.id);
        dispatch(updateUserImage(data));
    }

    const callbackModal = () => {
        setShowImageModal(false);
    }

    const onProfileSave = (values: EditFields) => {
        values.id = user.id

        setProfileEditOpen(false);
        let userPromise = new Promise<void>((resolve, reject) => {
            dispatch(updateUser(values, resolve, reject));
        })
        userPromise.then(() => {
            setErrorToast({ open: true, message: "Daten erfolgreich geändert", color: "success" });
        })
        userPromise.catch((error) => {
            console.log(error)
            setErrorToast({ open: true, message: error, color: "danger" });
        })
    }

    const onPasswordSave = async (values: EditPasswordFields) => {
        values.id = user.id;

        await http
            .put('/updatePassword', values)
            .then(response => {
                setProfilePasswordOpen(false);
                setErrorToast({ open: true, message: "Passwort erfolgreich geändert.", color: "success" })
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 400) {
                    setErrorToast({ open: true, message: error.response.data, color: "danger" })
                } else { console.log(error) }
            })
    }

    const getExpertise = (expert) => {
        let expertArray = []
        expert.forEach(expertise => {
            user.expert_values.forEach((value) => {
                if (value.id === expertise) {
                    expertArray.push(value.name)
                }
            })
        });
        return (expertArray.join(", "))
    }

    const toggleSMSNotifications = (val: boolean) => {
        console.log(val);
        let data = user;

        if (val === true) {
            data.no_sms = 0;
        } else {
            data.no_sms = 1;
        }

        onProfileSave(data);
        /* @ts-ignore */
        /* onProfileSave({no_sms: 1, first_name: user.first_name, name: user.name, email: user.email}); */
    }

    return (
        <IonPage>
            <Header name="Profil" />
            <IonContent>
                <div className="content">
                    <IonGrid style={{ padding: width < 576 ? 0 : 5, margin: width < 576 ? 0 : "-15px -15px 0" }} className="higherNewsGrid">
                        <IonRow>
                            {/* Profilbild */}
                            <IonCol size="12" style={{ padding: width < 576 ? "0 0 10px" : 10 }}>
                                <IonCard className="profileCard centering">
                                    <div className="imageWrapper" >
                                        <img
                                            src={user.image ? user.image : "/assets/images/profile_placeholder.svg"}
                                            onClick={() => setShowImageModal(true)}
                                            className="profileImage"
                                            alt="Eigenes Profilbild"
                                        />
                                        <IonFabButton color="secondary" onClick={() => takePhoto()}>
                                            <IonIcon icon={pencilOutline}></IonIcon>
                                        </IonFabButton>
                                    </div>
                                    <IonCardHeader>
                                        <IonCardTitle>{user.first_name || ""} {user.name || ""}</IonCardTitle>
                                        {/* <IonCardSubtitle>{user.organisation ? user.organisation : "Abteilung"}</IonCardSubtitle> */}
                                        <div className="profileContainer">
                                            {user.city && <span className="profileElement"><IonIcon icon={locationOutline} />{user.city}{user.country && ", " + user.country}</span>}
                                            {user.email && <span className="profileElement"><IonIcon icon={mailOutline} /><a href={"mailto:" + user.email}>{user.email}</a></span>}
                                            {user.organisation && <span className="profileElement"><IonIcon icon={briefcaseOutline} />{user.organisation}</span>}
                                        </div>
                                    </IonCardHeader>
                                    <IonCardContent style={{ textAlign: "left", marginTop: 20 }}>
                                        <IonItem detail={false} button onClick={() => setDropdownOpen(!dropdownOpen)} className="ion-no-padding collapsibleItem" style={{ width: "100%!important" }}>
                                            <IonLabel>Mehr Informationen</IonLabel>
                                            <IonIcon icon={dropdownOpen ? chevronUpOutline : chevronDownOutline} />
                                        </IonItem>
                                        {dropdownOpen &&
                                            <IonGrid className="noPaddingGrid" style={{ paddingTop: 20 }}>
                                                <IonRow >
                                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                                        <div className="profileSectionHeader">Persönliche Angaben</div>
                                                        <table width="100%" style={{ color: "black" }} className="profileTable">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>{user.first_name} {user.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Geburtstag</td>
                                                                    <td>{(user.birthdate && user.birthdate !== "0000-00-00") ? format(new Date(user.birthdate), "dd.MM.yyyy") : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>E-Mail</td>
                                                                    <td>{user.email ? <a href={"mailto:" + user.email}>{user.email}</a> : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.-Nr.</td>
                                                                    <td>{user.phone ? <a href={"tel:" + user.phone}>{user.phone}</a> : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mobil</td>
                                                                    <td>{user.mobile ? <a href={"tel:" + user.mobile}>{user.mobile}</a> : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fax</td>
                                                                    <td>{user.fax ? <a href={"fax:" + user.fax}>{user.fax}</a> : "-"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                                        <div className="profileSectionHeader profileSectionHeaderResponsive">Adresse</div>
                                                        <table width="100%" style={{ color: "black" }} className="profileTable">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Straße und Hsnr.</td>
                                                                    <td>{user.street ? user.street : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PLZ</td>
                                                                    <td>{user.zip_code ? user.zip_code : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Stadt</td>
                                                                    <td>{user.city ? user.city : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Land</td>
                                                                    <td>{user.country ? user.country : "-"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </IonCol>

                                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6" style={{ marginTop: 30 }}>
                                                        <div className="profileSectionHeader">Fachliche Angaben</div>
                                                        <table width="100%" style={{ color: "black" }} className="profileTable">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Funktion</td>
                                                                    <td>{user.function ? mapProfession(user.function) : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Andere Funktion</td>
                                                                    <td>{user.function_other ? user.function_other : "-"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Expertise</td>
                                                                    <td>{user.expert_user_values && getExpertise(user.expert_user_values) !== "" ? getExpertise(user.expert_user_values) : "-"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </IonCol>

                                                </IonRow>
                                            </IonGrid>}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Sägewerk */}
                            {user.contact &&
                                <IonCol style={{ padding: width < 576 ? "0 0 10px" : 10 }} sizeXl="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                    <IonCard className="profileCard">
                                        <IonCardHeader >
                                            <IonRow>
                                                <IonCardTitle>{user.contact.contact_name}</IonCardTitle>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <div>
                                                {user.contact.contact_street}<br />
                                                {user.contact.contact_zip_code} {user.contact.contact_city}<br />
                                                {user.contact.contact_country}<br />
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                {user.contact.contact_email && <span className="contactElement"><IonIcon icon={mailOutline} /> <a href={"mailto:" + user.contact.contact_email}>{user.contact.contact_email}</a></span>}
                                                {user.contact.contact_phone && <span className="contactElement"><IonIcon icon={phonePortraitOutline} /> <a href={"tel:" + user.contact.contact_phone}>{user.contact.contact_phone}</a></span>}
                                                {user.contact.contact_web && <span className="contactElement"><IonIcon icon={globeOutline} /> <a href={user.contact.contact_web} rel="noopener noreferrer" target="_blank">{user.contact.contact_web}</a></span>}
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>}

                            {/* Aktionen */}
                            <IonCol style={{ padding: width < 576 ? "0 0 10px" : 10 }} sizeXl="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" >
                                <IonCard className="profileCard">
                                    <IonCardHeader >
                                        <IonRow>
                                            <IonCardTitle>Aktionen</IonCardTitle>
                                        </IonRow>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonList lines="none" className="userActionsList">
                                            <IonItem onClick={() => setProfileEditOpen(true)} button style={{ background: "#fff" }} className="userActionsListItem">
                                                <IonIcon icon={pencilOutline} slot="start" />
                                                <IonLabel>Konto bearbeiten</IonLabel>
                                            </IonItem>
                                            {user.no_sms !== undefined &&
                                                <IonItem style={{ background: "#fff" }} className="userActionsListItem">
                                                    <IonIcon icon={notificationsOutline} slot="start" />
                                                    <IonLabel>SMS Benachrichtigungen</IonLabel>
                                                    <IonToggle checked={user.no_sms === 0 ? true : false} onIonChange={(e) => toggleSMSNotifications(e.detail.checked)}>SMS Benachrichtigungen</IonToggle>
                                                </IonItem>
                                            }
                                            <IonItem onClick={() => setProfilePasswordOpen(true)} button style={{ background: "#fff" }} className="userActionsListItem">
                                                <IonIcon icon={keyOutline} slot="start" />
                                                <IonLabel>Passwort ändern</IonLabel>
                                            </IonItem>
                                            <IonItem onClick={logout} button style={{ background: "#fff" }} className="userActionsListItem">
                                                <IonIcon icon={logOutOutline} slot="start" />
                                                <IonLabel>Ausloggen</IonLabel>
                                            </IonItem>
                                        </IonList>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>

            {/* Dialogs */}
            <ProfileImageModal isOpen={showImageModal} callback={callbackModal} photo={user.image ? user.image : "/assets/images/profile_placeholder.svg"} updatePhoto={takePhoto} removePhoto={removeProfilePic} title="Profilbild" />
            <ProfileEditModal isOpen={profileEditOpen} closeModal={setProfileEditOpen} user={user} onSave={onProfileSave} />
            <ProfilePasswordModal isOpen={profilePasswordOpen} closeModal={setProfilePasswordOpen} onSave={onPasswordSave} />

            {/* Message Toast */}
            <IonToast
                isOpen={errorToast.open}
                onDidDismiss={() => setErrorToast({ open: false, message: "", color: "" })}
                message={errorToast.message}
                duration={3000}
                color={errorToast.color}
            />

        </IonPage>
    )
}

export default Profile;