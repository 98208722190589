/**
 *
 * Dialog Fenster für Selektion der Datenreihen bei den Aggregaten
 *
 */

import React, { useState, useEffect } from "react";
import { IonModal, IonButton, IonHeader, IonTitle, IonToolbar, IonCheckbox, IonContent, IonButtons, IonIcon, IonInfiniteScroll, IonItem, IonLabel, IonSearchbar, IonGrid, IonRow, IonCol, IonFooter } from "@ionic/react";
import { closeOutline } from 'ionicons/icons';

import "../Statistics.css";
import http from "../../../utils/http-common";
import { Aggregate } from "../StatisticsInterfaces";

type AggregateSelectDialogProps = {
    id: number,
    name: string,
    isOpen: boolean,
    callback: () => void,
    aggregates: Aggregate[],
    setAggregates: (checked: object) => void,
    aggregatableIndex: number,
    currentAggregateIndex: number | undefined,
    setReadableAggregateNames: (readableAggregateNames: string[][]) => void,
}

const AggregateSelectDialog: React.FC<AggregateSelectDialogProps> = ({ id, name, isOpen, callback, aggregates, setAggregates, aggregatableIndex, currentAggregateIndex, setReadableAggregateNames }: AggregateSelectDialogProps) => {
    const [items, setItems] = useState([]);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const amount = 25;
    const [page, setPage] = useState<number>(1);
    const [searchStr, setSearchStr] = useState<string>("");
    const [finalSearch, setFinalSearch] = useState<string>("")

    useEffect(() => {
        if (isOpen) {
            if (id && name) {
                fetchData(true);
            }
        }

    }, [finalSearch, id, name, isOpen]);

    const fetchData = async (reset?: boolean) => {
        if (id && name && isOpen) {
            const data = reset ? [] : items;
            const url: string = finalSearch !== "" ? `/fetchStatisticEvenMoreFilter?statistics_attribute_id=${id}&name=${name}&page=${page}&search=${finalSearch}` :
                `/fetchStatisticEvenMoreFilter?statistics_attribute_id=${id}&name=${name}&page=${page}`

            await http
                .get(url)
                .then(function (response) {
                    if (response.data.data.length > 0) {
                        //console.log(response)
                        setItems([...data, ...response.data.data]);
                        setDisableInfiniteScroll(response.data.data.length < amount || response.data.total === amount)
                        setPage((p) => p + 1)
                    } else {
                        setItems([])
                        setDisableInfiniteScroll(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

    }

    const searchOnEnter = (e) => {
        if (e.key === 'Enter') {
            setPage(1);
            setFinalSearch(searchStr);
        }
    }

    async function searchNext($event: CustomEvent<void>) {
        await fetchData();
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    const handleCheckbox = (code, content) => {
        if (aggregates[currentAggregateIndex]["filter_" + aggregatableIndex] && aggregates[currentAggregateIndex]["filter_" + aggregatableIndex].includes(code)) {
            //remove
            setAggregates((aggregates) => {
                aggregates[currentAggregateIndex]["filter_" + aggregatableIndex] = aggregates[currentAggregateIndex]["filter_" + aggregatableIndex].filter(item => { return item !== code })
                return ([...aggregates])
            })
            //@ts-ignore
            setReadableAggregateNames((readableAggregateNames: string[][]) => {
                readableAggregateNames[currentAggregateIndex] = readableAggregateNames[currentAggregateIndex].filter(item => { return item !== content })
                return ([...readableAggregateNames])
            })
        } else {
            //add
            setAggregates((aggregates) => {
                if (aggregates[currentAggregateIndex]["filter_" + aggregatableIndex]) {
                    aggregates[currentAggregateIndex]["filter_" + aggregatableIndex].push(code)
                } else {
                    aggregates[currentAggregateIndex]["filter_" + aggregatableIndex] = [code]
                }
                return ([...aggregates])
            })
            //@ts-ignore
            setReadableAggregateNames((readableAggregateNames: string[][]) => {
                readableAggregateNames[currentAggregateIndex].push(content)
                return ([...readableAggregateNames])
            })
        }
    }

    const handleClose = () => {
        setPage(1);
        callback();
    }

    return (
        <IonModal isOpen={isOpen} className="customModal" >
            <IonHeader >
                <IonToolbar color="primary">
                    <IonTitle>Alle Datenreihen</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handleClose}>
                            <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar value={searchStr} onIonChange={e => setSearchStr(e.detail.value!)} enterkeyhint="search" placeholder="Suche" onKeyDown={e => searchOnEnter(e)} debounce={0}></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonGrid className="noPaddingGrid">
                    {items.length > 0 ?
                        <IonRow>
                            {items.map((item, index) => (
                                <IonCol size="12" key={index} className="checkboxCol">
                                    <IonItem lines="none" className="checkboxItemModal ion-no-padding">
                                        <IonLabel className="ion-text-wrap" style={{ paddingTop: 2 }}>{item.content}</IonLabel>
                                        <IonCheckbox
                                            color="secondary"
                                            slot="start"
                                            value={item.code}
                                            checked={aggregates[currentAggregateIndex]["filter_" + aggregatableIndex] && aggregates[currentAggregateIndex]["filter_" + aggregatableIndex].includes(item.code)}
                                            className="checkbox"
                                            onClick={(e) => handleCheckbox(item.code, item.content)}
                                        />
                                        <IonLabel className="ion-text-wrap" style={{ paddingTop: 2 }} slot="end">{item.code}</IonLabel>
                                    </IonItem>
                                </IonCol>

                            ))}
                        </IonRow> : "Keine Datenreihen gefunden."}
                </IonGrid>
                <div>
                    <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                        <div style={{ textAlign: "center", padding: "15px 0", opacity: 0.5 }}>Weitere Datenreihen werden geladen...</div>
                    </IonInfiniteScroll>
                </div>
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={handleClose}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default AggregateSelectDialog;
