import {
    SET_CUSTOM_STATISTICS,
    SET_DESTATIS_STATISTICS,
    SET_STATISTICS_LOADING
} from './statistics.types';

const initialState = {
    destatisStatistics: [],
    customStatistics: {},
    customStatisticCategories: [],
    statisticsLoading: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_DESTATIS_STATISTICS:
            return {
                ...state,
                destatisStatistics: payload,
            }
        case SET_CUSTOM_STATISTICS:
            return {
                ...state,
                customStatistics: payload,
                customStatisticCategories: payload.custom_categories,
            }
        case SET_STATISTICS_LOADING:
            return {
                ...state,
                statisticsLoading: payload,
            }
        default:
            return state;
    }
}