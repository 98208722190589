import { useDispatch } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from "redux-thunk";

import rootReducer from './reducers'

const middleware = [thunk];
const initialState = {};

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;

