/**
 *
 * News-Artikel Detailseite 
 * - Artikel-Inhalt
 * - Download
 * - Kommentare
 * - Ähliche Artikel
 * - Favorisieren
 * - Anpinnen
 *
 */

import React, { useEffect, useState } from "react";
import { IonCard, IonCardHeader, IonContent, IonPage, IonCardSubtitle, IonCardTitle, IonCardContent, IonButton, IonIcon, IonList, IonItem, IonLabel, IonAvatar, IonRouterLink, IonRow, IonCol, IonTextarea, IonGrid, IonToast, useIonRouter } from "@ionic/react";
import { heartOutline, bookmarkOutline, heart, bookmark, downloadOutline } from 'ionicons/icons';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import "./Newsfeed.css";
import { CustomLoading, Header, Tooltip } from "../../components";
import http from "../../utils/http-common";
import { Comment } from "./NewsfeedInterfaces";
import { formatDate, isObjectEmpty } from "../../utils/general-helpers";
import useWindowDimensions from "../../utils/screen-width";
import { RootState } from "../../redux/reducers";
import { ErrorToast } from "../statistics/StatisticsInterfaces";
import { AuthorInfo, Slideshow } from "./newsfeedComponents";
import { getArticle } from "../../redux/articles/article.actions";



//Haupt-Komponente
const NewsfeedDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Article ID
    const [commentValue, setCommentValue] = useState<string>("");
    const [isFavorite, setIsFavorite] = useState<number>(0);
    const [favoriteCounter, setFavoriteCounter] = useState<number>(0);
    const { height, width } = useWindowDimensions();
    const [commentCount, setCommentCount] = useState<number>(0)
    const [comments, setComments] = useState<Comment[]>([]);
    const [errorToast, setErrorToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" });
    const [isBookmark, setIsBookmark] = useState<number>(0);
    const [bookmarkCounter, setBookmarkCounter] = useState<number>(0);
    let history = useIonRouter();

    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin, shallowEqual);
    const article = useSelector((state: RootState) => state.article.article, shallowEqual);
    const user = useSelector((state: RootState) => state.user.user, shallowEqual);
    const loading = useSelector((state: RootState) => state.user.loading, shallowEqual);

    const dispatch = useDispatch();

    //on initial load article needs to be loaded
    useEffect(() => {
        let active = true;
        let articlePromise = new Promise<void>((resolve, reject) => {
            dispatch(getArticle(id, resolve, reject))
        })
        articlePromise.then(() => { })
        articlePromise.catch((error) => {
            if (error.response.status === 404) {
                history.push('/404');
            }
        })

        return () => {
            active = false;
        };
    }, [])

    useEffect(() => {
        if (!isObjectEmpty(article)) {
            setOtherArticleValues()
        }
    }, [article])


    const setOtherArticleValues = () => {
        setIsFavorite(article.useful_for_me);
        setFavoriteCounter(article.counted_useful);
        setCommentCount(article.comments.length);
        setComments(article.comments);
        setIsBookmark(article.bookmark_for_me);
        setBookmarkCounter(article.counted_bookmarks);
    }

    // add and remove to/from favorites
    const handleFavorite = async () => {
        let fav;
        if (isFavorite === 0) {
            fav = 1;
            setFavoriteCounter((f) => f + 1)
        } else {
            fav = 0
            setFavoriteCounter((f) => f - 1);
        }
        setIsFavorite(fav)
        await http
            .post('/usefulArticle', { article_id: parseInt(id), useful: fav })
            .then((response) => {
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleBookmark = async () => {
        let bm;
        if (isBookmark === 0) {
            bm = 1;
            setBookmarkCounter((b) => b + 1)
        } else {
            bm = 0;
            setBookmarkCounter((b) => b - 1)
        }
        setIsBookmark(bm);
        await http
            .post('/bookmarkArticle', { article_id: parseInt(id), bookmark: bm })
            .then((response) => {
            })
            .catch((error) => {
                console.log(error)
            })
    }


    // Post Comment
    const postComment = async () => {
        await http
            .post(`/addCommentToArticle`, {
                article_id: id,
                comment: commentValue,
            })
            .then((response) => {
                setComments(comments => [...comments, {
                    name: user.name,
                    comment: commentValue,
                    id: response.data.comment.id,
                    timestamp: new Date().toString(),
                    vorname: user.first_name,
                    image: user.image,
                }])
                setCommentCount((c) => c + 1)
                setCommentValue("");
            })
            .catch((error) => {
                console.log(error);
                openErrorToast("Fehler beim Speichern Ihres Kommentars.", "danger")
            })
    }

    // Format Comment so its readable for user
    const formatCommentString = (text) => {
        let formattedText: string = "";
        formattedText = text.replaceAll("\\r", " ");
        formattedText = formattedText.replaceAll("\\n", "<br/>");
        return formattedText;
    }

    // File Download
    const getFile = async (fileId, fileName) => {
        await http
            .get(`/getFile`, {
                responseType: "blob",
                params: {
                    id: fileId,
                    article_id: article.id
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // Error Handling für User Feedback mit Message Toast
    const openErrorToast = (message: string, color: string) => {
        setErrorToast({ open: true, message: message, color: color })
    }
    const closeErrorToast = () => {
        setErrorToast({ open: false, message: "", color: "" })
    }

    const renderHeaderImage = (images) => {
        if (images.length === 0) {
            return null
        } else if (images.length === 1) {
            return (
                <div style={{ textAlign: "center" }}>
                    <img src={images[0].img} loading="lazy" />
                </div>
            )
        } else {
            return (
                <Slideshow images={images} />
            )
        }
    }

    const downloadArticle = async () => {
        await http
            .get(`/generatePdf`, {
                responseType: "blob",
                params: {
                    ids: article.id,
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "desh-mitglieder-artikel.pdf");
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <IonPage>
            <Header back={true} name="Newsfeed" />
            <IonContent>
                {!isObjectEmpty(article) &&
                    <div className="content" style={width < 1800 ? { maxWidth: 1200 + 'px', margin: "auto" } : {}}>
                        <IonGrid className="ion-no-padding">
                            <IonRow>
                                <IonCol size={width > 1800 ? "8" : "12"}>
                                    <div className="ion-float-right" style={{ marginRight: 5, display: "inline-flex" }}>
                                        <div className="reactionElement" style={{ marginRight: 24 }}>
                                            {temporaryLoggedIn === 1 ?
                                                <Tooltip content="Login erforderlich">
                                                    <IonButton size="small" fill="clear" onClick={handleFavorite} disabled>
                                                        <IonIcon slot="icon-only" icon={isFavorite === 1 ? heart : heartOutline} />
                                                    </IonButton>
                                                </Tooltip> :
                                                <IonButton size="small" fill="clear" onClick={handleFavorite} >
                                                    <IonIcon slot="icon-only" icon={isFavorite === 1 ? heart : heartOutline} />
                                                </IonButton>
                                            }
                                            <div className="reactionCount">{favoriteCounter}</div>
                                        </div>
                                        <div className="reactionElement">
                                            {temporaryLoggedIn === 1 ?
                                                <Tooltip content="Login erforderlich">
                                                    <IonButton size="small" fill="clear" onClick={handleBookmark} disabled>
                                                        <IonIcon slot="icon-only" icon={isBookmark === 1 ? bookmark : bookmarkOutline} />
                                                    </IonButton>
                                                </Tooltip> :
                                                <IonButton size="small" fill="clear" onClick={handleBookmark}>
                                                    <IonIcon slot="icon-only" icon={isBookmark === 1 ? bookmark : bookmarkOutline} />
                                                </IonButton>
                                            }
                                            <div className="reactionCount">{bookmarkCounter}</div>
                                        </div>
                                        <div className="reactionElement" style={{ marginLeft: 24 }}>
                                            <span slot="separator" style={{ fontSize: 30, color: "#73737380", fontWeight: 300, lineHeight: "30px", marginRight: 2 }}>|</span>
                                            <IonButton size="small" fill="clear" onClick={downloadArticle}>
                                                <IonIcon slot="icon-only" icon={downloadOutline} />
                                            </IonButton>

                                        </div>
                                    </div>

                                    <div style={{ clear: "right" }}></div>

                                    <IonCard style={{ background: "#ffffff" }} className="articleDetailCard">
                                        {(article.images && article.images !== null) && renderHeaderImage(article.images)}
                                        <IonCardHeader>
                                            {width < 1800 &&
                                                <div style={{ margin: "20px 0" }}>
                                                    <IonItem className="relatedArticlesItem ion-no-padding" lines="none">
                                                        <IonAvatar slot="start">
                                                            <img src={article.author.profile_pic ? article.author.profile_pic : "./assets/images/profile_placeholder.svg"} loading="lazy" />
                                                        </IonAvatar>
                                                        <IonLabel>
                                                            <h3>{article.author.vorname} {article.author.name}</h3>
                                                            <p>{article.author.position || ""}</p>
                                                        </IonLabel>
                                                    </IonItem>
                                                </div>
                                            }
                                            <IonCardSubtitle>{formatDate(article.date)}</IonCardSubtitle>
                                            <IonCardTitle className="articleDetailTitle">{article.headline}</IonCardTitle>
                                            {(article.hashtags && article.hashtags.length > 0) &&
                                                <div className="hashtags">
                                                    {article.hashtags.map((hashtag) => {
                                                        return (
                                                            <IonRouterLink className="hashtagLink" key={hashtag.id} href={`mitgliederbereich/newsfeed/${hashtag.id}/${hashtag.hashtag}`}>#{hashtag.hashtag}</IonRouterLink>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </IonCardHeader>
                                        <IonCardContent className="articleText">
                                            <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
                                            {/* File Download */}
                                            {article.files.length > 0 &&
                                                <div className="downloadContainer">
                                                    <div className="downloadHeadline">Downloads</div>
                                                    <table width="100%" className="downloadTable">
                                                        <tbody>
                                                            {article.files.map((file) => {
                                                                return (
                                                                    <tr key={file.id}>
                                                                        <td>
                                                                            {file.text !== "" ? file.text : file.dateiname} <br />
                                                                        </td>
                                                                        <td className="ion-float-right">
                                                                            <button className="downloadBtn" onClick={() => getFile(file.id, file.dateiname)}>Download</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {/* <div style={{ height: 40 }}>
                                                <IonButton className="ion-float-right" onClick={downloadArticle}>
                                                    <IonLabel>Download</IonLabel>
                                                </IonButton>
                                            </div> */}
                                        </IonCardContent>

                                        {/* Comments */}
                                        <IonRow className="commentSection">

                                            {comments &&
                                                <IonCol size="12">
                                                    <div className="commentHeadline">Kommentare ({commentCount})</div>
                                                    <IonList className="relatedArticlesList" >
                                                        {comments.map(comment => {
                                                            return (
                                                                <IonItem lines="none" key={comment.id} style={{ alignItems: "start", margin: "10px -16px 12px 0" }} className="whiteItem ion-no-padding">
                                                                    <IonAvatar slot="start">
                                                                        <img src={comment.image ? comment.image : "./assets/images/profile_placeholder.svg"} loading="lazy" />
                                                                    </IonAvatar>
                                                                    <div className="comment">
                                                                        <p><span style={{ fontWeight: 500 }}>{comment.vorname} {comment.name}</span> - {formatDate(comment.timestamp)}</p>
                                                                        <div className="commentText" dangerouslySetInnerHTML={{ __html: formatCommentString(comment.comment) }}></div>
                                                                    </div>
                                                                </IonItem>
                                                            )
                                                        })}
                                                    </IonList>
                                                </IonCol>
                                            }

                                            {/* Write own Comment */}
                                            <IonCol size="12">
                                                <div>
                                                    <div style={{ display: "inline-flex", width: "100%" }}>
                                                        <IonAvatar slot="start" style={{ width: 42, height: 40, margin: "8px 0 8px" }}>
                                                            <img width="40px" height="40px" src="./assets/images/profile_placeholder.svg" loading="lazy" />
                                                        </IonAvatar>
                                                        <IonItem className="commentTextAreaItem" lines="none">
                                                            <IonTextarea
                                                                placeholder="Zur Diskussion beitragen"
                                                                className="commentTextArea"
                                                                inputMode="text"
                                                                spellcheck={true}
                                                                autoGrow={true}
                                                                value={commentValue}
                                                                onIonChange={e => setCommentValue(e.detail.value!)}
                                                            ></IonTextarea>
                                                        </IonItem>
                                                    </div>
                                                    <div className="ion-float-right">
                                                        <IonButton onClick={postComment} disabled={temporaryLoggedIn === 1 ? true : false}>Senden</IonButton>
                                                    </div>
                                                    <div style={{ clear: "both" }}></div>
                                                    {temporaryLoggedIn === 1 && <div style={{ textAlign: "right" }}><i>Melden Sie sich an, um einen Kommentar zu posten.</i></div>}

                                                </div>
                                            </IonCol>

                                        </IonRow>
                                    </IonCard>
                                </IonCol>




                                <IonCol className="ion-no-padding" size={width > 1800 ? "4" : "12"} style={width > 1800 ? { paddingLeft: 50 } : { paddingLeft: 0 }}>
                                    <IonRow>
                                        {/* Autor */}
                                        <IonCol >
                                            <AuthorInfo width={width} author={article.author} />
                                        </IonCol>

                                        {/* Ähnliche Artikel */}
                                        {(article.related && article.related.length > 0) &&
                                            <IonCol sizeXl={width > 1800 ? "12" : "6"} sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                                                <IonCard className="articleDetailCard">
                                                    <IonCardHeader>
                                                        <IonCardTitle>Ähnliche Artikel</IonCardTitle>
                                                    </IonCardHeader>
                                                    <IonCardContent className="newsCardContent">
                                                        <IonList className="relatedArticlesList">
                                                            {article.related.map((relatedArticle) => {
                                                                return (
                                                                    <IonItem key={Math.random()} className="relatedArticlesItem ion-no-padding">
                                                                        {width > 540 &&
                                                                            <IonAvatar slot="start" className="relatedArticlesImage">
                                                                                <img src={relatedArticle.image ? relatedArticle.image.img : "./assets/images/example-image.jpg"} />
                                                                            </IonAvatar>}
                                                                        <IonRouterLink color="black" href={`/mitgliederbereich/newsfeed-artikel/${relatedArticle.id}`}>
                                                                            <IonLabel style={{ whiteSpace: "normal" }}>
                                                                                {relatedArticle.headline}
                                                                                <div style={{ fontSize: 14 }}>{formatDate(relatedArticle.date)} {/* | Kategorie? */}</div>
                                                                            </IonLabel>
                                                                        </IonRouterLink>
                                                                    </IonItem>
                                                                )
                                                            })}

                                                        </IonList>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        }
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                }

                <IonToast
                    isOpen={errorToast.open}
                    onDidDismiss={() => closeErrorToast()}
                    message={errorToast.message}
                    duration={3000}
                    color={errorToast.color}
                />
            </IonContent>
            <CustomLoading isOpen={loading}/>
        </IonPage >
    )
}

export default NewsfeedDetail;
