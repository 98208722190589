/**
 *
 * Vordefinierte Objekte für Statistiken
 *
 */

export const options = {
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      align: "start",
      labels: {
        font: {
          weight: 500,
          family: "Roboto",
          size: 12,
        },
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
      position: "bottom",
    },
  },
  scales: {
    x: {
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        font: {
          size: 12,
        },
      },
    },
    y: {
      min: undefined,
      max: undefined,
      display: true,
      ticks: {
        font: {
          size: 12,
        },
      },
    },
  },
  animation: {
    duration: 0,
  },
};

export const radioList = [
  { label: "Die letzten 12 Monate", val: "month12" },
  { label: "Die letzten 24 Monate", val: "month24" },
  /* { label: "Dieses Jahr", val: "yearThis" }, */
  { label: "Letztes Jahr", val: "yearLast" },
  { label: "Freier Zeitraum", val: "customDate" },
];

export const initialAggregates = [
  {
    checked: false,
    initial_name: "Aggregatfunktion #1",
    aggregate_name: "",
    aggregate_method: "",
  }, {
    checked: false,
    initial_name: "Aggregatfunktion #2",
    aggregate_name: "",
    aggregate_method: "",
  }, {
    checked: false,
    initial_name: "Aggregatfunktion #3",
    aggregate_name: "",
    aggregate_method: "",
  }
]

export const colorPalette = [
  '#1d405a',
  '#ee8000',
  '#b71c1c',
  '#880e4f',
  '#1565c0',
  '#006064',
  '#558b2f',
  '#fbc02d'
]