import { SET_NETWORKSTATE } from "./network.types";

const initialState = {
    online: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_NETWORKSTATE:
            return {
                ...state,
                online: action.payload
            };
        default: return state;
    }
}