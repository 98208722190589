import { IonChip } from "@ionic/react";
import { useEffect, useState } from "react"

interface ActivityStateProps {
    state: string,
}

const mapStateToColor = (state: string) => {
    switch (state) {
        case "offen": return "success";
        case "in Bearbeitung": return "warning";
        case "abgeschlossen": return "primary";
        case "storno": return "danger";
        default: return "primary";
    }
}

const ActivityState: React.FC<ActivityStateProps> = ({ state }: ActivityStateProps) => {
    const [color, setColor] = useState<string>(mapStateToColor(state));

    useEffect(() => {
        setColor(mapStateToColor(state));
    }, [state])


    return (
        <IonChip className="activityStateChip" color={color} >{state}</IonChip>
    )
}

export default ActivityState