import React from 'react';
import { IonAvatar, IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonTitle, IonToolbar, useIonPopover, useIonRouter } from "@ionic/react";
import { logOutOutline, personOutline } from 'ionicons/icons';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { User } from "../../pages/profile/ProfileInterfaces";
import { logoutUser } from "../../redux/user/user.actions";
import { RootState } from "../../redux/reducers";
import "./Header.css";

interface HeaderProps {
    back?: boolean,
    name: string,
    children?: JSX.Element,
}


const PopoverList: React.FC<{
    onLogout: (e: any) => void;
    navigate: (page: string) => void;
    user: User;
}> = ({ onLogout, user, navigate }) => (
    <div className="userActionsMenu">
        <div className="profileHeader">
            <IonAvatar>
                <img src={user.image ? user.image : "./assets/images/profile_placeholder.svg"} loading="lazy" />
            </IonAvatar>
            <IonTitle className="profileHeaderImage">{user.first_name || ""} {user.name || ""}</IonTitle>
            {/* {user.profession && <IonCardSubtitle>user.profession</IonCardSubtitle>} */}
        </div>
        <IonList>
            <IonItem button lines="none" onClick={() => navigate(`/mitgliederbereich/profil`)}>
                <IonLabel>Profil</IonLabel>
                <IonIcon icon={personOutline} slot="start"></IonIcon>
            </IonItem>
            <IonItem button onClick={onLogout} lines="none">
                <IonLabel>Logout</IonLabel>
                <IonIcon icon={logOutOutline} slot="start"></IonIcon>
            </IonItem>
        </IonList>
    </div>
);

const Header: React.FC<HeaderProps> = ({ back, name, children }: HeaderProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user, shallowEqual)
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin, shallowEqual);
    const [popover, dismissPopover] = useIonPopover(PopoverList, { onLogout: (e: any) => logout(e), user: user, navigate: (page: string) => navigate(page) });
    let history = useIonRouter();

    const logout = async (e: any) => {
        e.preventDefault();
        await dismissPopover();
        dispatch(logoutUser());
    }

    const navigate = (page: string) => {
        history.push(page);
        dismissPopover();
    }


    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
                    {(back && history.routeInfo.lastPathname && !history.routeInfo.lastPathname.startsWith("/newsletter")) && <IonBackButton text="" defaultHref="/mitgliederbereich/newsfeed" />}
                </IonButtons>
                <IonButtons slot="end">
                    {temporaryLoggedIn !== 1 ? <IonButton onClick={(e) => popover({ event: e.nativeEvent })}>
                        <IonAvatar className="headerProfileAvatar" >
                            <img alt="Benutzer Profilbild" src={user.image ? user.image : "./assets/images/profile_placeholder.svg"} className="headerProfileImage" />
                        </IonAvatar>
                    </IonButton> :
                        <IonButton onClick={(e) => {
                            e.preventDefault();
                            history.push('/login');
                        }}>Login</IonButton>
                    }
                    {children && children}
                </IonButtons>
                <IonTitle>{name}</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
}


export default Header;
