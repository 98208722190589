import React from "react";
import "./CustomLoading.css";

interface CustomLoadingProps {
    isOpen: boolean,
}

const LoadingSpinner: React.FC<CustomLoadingProps> = ({ isOpen }: CustomLoadingProps) => {
    return isOpen ? (
        <>
            <div className="backdropContainer"></div>
            <div className="spinnerContainer">
                <div className="spinnerDialogContainer">
                    <div className="loading-spinner">
                    </div>
                </div>
            </div>
        </>) : null;

}

export default LoadingSpinner;
