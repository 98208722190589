import { INCREASE_MEDIA_COUNTER, MEDIA_LOADING, SET_MEDIA, SET_MEDIA_CATEGORIES, SET_MEDIA_LIKE, SET_SELECTED_MEDIA_ENTRY } from "./media.types";

const initialState = {
    media: [],
    categories: [],
    loading: false,
    currentMediaEntry: {},
    mediaFile: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_MEDIA:
            return {
                ...state,
                media: action.payload,
            };
        case SET_SELECTED_MEDIA_ENTRY:
            return {
                ...state,
                currentMediaEntry: action.payload.currentMediaEntry,
                mediaFile: action.payload.mediaFile
            };
        case SET_MEDIA_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        case MEDIA_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case INCREASE_MEDIA_COUNTER:
            return {
                ...state,
                media: state.media.map((entry) => {
                    if (entry.id === action.payload) {
                        return { ...entry, downloadCounter: entry.downloadCounter + 1 };
                    } else {
                        return entry;
                    }
                }),
                //@ts-ignore
                currentMediaEntry: { ...state.currentMediaEntry, downloadCounter: state.currentMediaEntry.downloadCounter + 1 }            }
        case SET_MEDIA_LIKE:
            return {
                ...state,
                media: state.media.map((entry) => {
                    if (entry.id === action.payload.id) {
                        return { ...entry, ...action.payload };
                    } else {
                        return entry;
                    }
                }), 
                currentMediaEntry: { ...state.currentMediaEntry, ...action.payload }
            }
        default: return state;
    }
}