import { MediaEntry } from "../../pages/mediaArchive/MediaArchiveInterfaces";
import http from "../../utils/http-common";

import { INCREASE_MEDIA_COUNTER, MEDIA_LOADING, SET_MEDIA, SET_MEDIA_CATEGORIES, SET_MEDIA_LIKE, SET_SELECTED_MEDIA_ENTRY } from "./media.types";

export const getMedia = (search: string) => async (dispatch) => {
    dispatch(setMediaLoading(true));
    try {
        const res = await http.get(`/getMediaList?search=${search}`);
        //console.log(res)
        /* if (res.data.result[11]) {
            dispatch(setMedia(res.data.result[11]));
            return Promise.resolve(res.data.result[11])
        } else { */
        let categories = []
        res.data.result.forEach((result) => {
            if (!categories.find(x => x.id === result.category_id)) {
                categories.push({
                    id: result.category_id,
                    name: result.category
                })
            }
        })
        dispatch(setCategories(categories))
        dispatch(setMedia(res.data.result));
        return Promise.resolve(res.data.result)
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    } finally {
        dispatch(setMediaLoading(false));
    }
}

export const getMediaDetails = (id) => async (dispatch) => {
    dispatch(setMediaLoading(true));

    const res = await http.get(`/getMediaFileDetails?id=${id}`)
    if (res) {
        const fileRes = await http.get(`/getMediaFile`, {
            responseType: "blob",
            params: {
                id: id,
                document_id: res.data.result.document_id,
            }
        })

        const file = await processFile(fileRes);

        if (file) {
            //@ts-ignore
            dispatch(setSelectedMediaEntry(res.data.result, file))
            dispatch(setMediaLoading(false));
        }
        dispatch(setMediaLoading(false));

    } else {
        console.log("Fehler")
        
    }

    dispatch(setMediaLoading(false));

}

const processFile = (fileRes) => {
    const reader = new FileReader()
    //console.log(fileRes)
    let base64String;
    const blob = new Blob([fileRes.data]);
    let file;

    return new Promise((resolve, reject) => {
        reader.readAsDataURL(blob);
        reader.onload = (fileEvent) => {
            base64String = reader.result;
            file = `data:${fileRes.data.type};base64,${base64String.substr(base64String.indexOf(',') + 1)}`;
            resolve(file)
        }
        reader.onerror = () => {
            reject('oops, something went wrong with the file reader.')
        }
    })
}



export const setSelectedMediaEntry = (entry, file: string) => {
    return {
        type: SET_SELECTED_MEDIA_ENTRY,
        payload: {
            currentMediaEntry: entry,
            mediaFile: file,
        }
    }
}

export const setCategories = (categories) => {
    return {
        type: SET_MEDIA_CATEGORIES,
        payload: categories
    }
}


export const setMedia = (media: MediaEntry[]) => {
    return {
        type: SET_MEDIA,
        payload: media
    }
}

export const setMediaLoading = (value: boolean) => {
    return {
        type: MEDIA_LOADING,
        payload: value,
    };
};

export const increaseDownloadCounter = (id: number) => {
    return {
        type: INCREASE_MEDIA_COUNTER,
        payload: id
    }
}

export const toggleLike = (id: number, document_id: number) => async (dispatch) => {
    try {
        const res = await http.get(`/likeMediaFile?id=${id}&document_id=${document_id}`);
        if (res) {
            dispatch(setLike({
                id: id,
                likedByUser: res.data.log.like,
                likedCounter: res.data.log.allLikes
            }))
        }
    } catch (err) {
        console.log(err)
    }
}

export const setLike = (data) => {
    return {
        type: SET_MEDIA_LIKE,
        payload: data
    }
}

