import React from "react";
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

/* import "../Example.css"; */

type FilterModalProps = {
    open: boolean,
    setOpen: (open: boolean) => void,
    filterFields: () => any,
    search: () => void,
    toggleBtns: () => any,
}

const FilterDialog: React.FC<FilterModalProps> = ({ open, setOpen, filterFields, search, toggleBtns }: FilterModalProps) => {

    const applyFilter = () => {
        search();
        setOpen(false);
    }

    return (
        <IonModal isOpen={open} className='profileEditModal'>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Filter</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setOpen(false)}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonGrid >
                    {filterFields()}
                    <IonCol size="12">
                        <IonItem lines="none">
                            <IonLabel style={{ color: "#0009" }}>Suchmethode</IonLabel>
                            {toggleBtns()}
                        </IonItem>
                    </IonCol>
                </IonGrid>
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => setOpen(false)}>Abbrechen</IonButton>
                        <IonButton onClick={applyFilter} color="primary" fill="solid">Anwenden</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>

        </IonModal>
    )
}

export default FilterDialog;