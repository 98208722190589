import React from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import { closeOutline, pencilOutline, trashOutline } from 'ionicons/icons';

type ProfileImageModalProps = {
    isOpen: boolean,
    callback: Function,
    photo: string,
    updatePhoto: () => void,
    removePhoto: () => void,
    title: string,
}

const ProfileImageModal: React.FC<ProfileImageModalProps> = ({ isOpen, callback, photo, updatePhoto, removePhoto, title }: ProfileImageModalProps) => {
    const [present] = useIonAlert(); 

    return (
        <IonModal isOpen={isOpen} className='profileImageModal' >
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton onClick={() => callback()}>
                            <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={updatePhoto}>
                            <IonIcon icon={pencilOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                        <IonButton
                            onClick={() =>
                                present({
                                    header: 'Profilbild entfernen',
                                    message: 'Möchten Sie Ihr Profilbild wirklich entfernen?',
                                    buttons: [
                                        'Nein',
                                        { text: 'Ja', handler: (d) => removePhoto() },
                                    ],
                                })}
                            disabled={photo !== undefined ? false : true}>
                            <IonIcon icon={trashOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <div className="imageContent">
                    <div>
                        <img src={photo} loading="lazy"/>
                    </div>
                </div>

            </IonContent>
        </IonModal >
    )
}
export default ProfileImageModal;