import React, { ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        /* console.log(error, errorInfo); */
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <p>Es gab einen Fehler beim Laden der Komponente</p>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;