// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD23JVrRJfcbA2vBOpc1nxd-OPbENnBrX0",
  authDomain: "desh-mitglieder-v2.firebaseapp.com",
  projectId: "desh-mitglieder-v2",
  storageBucket: "desh-mitglieder-v2.appspot.com",
  messagingSenderId: "154892235588",
  appId: "1:154892235588:web:4aee974d981eb4efc75d11"
};

// Initialize Firebase
/* let messaging = null;
console.log(isSupported())
if (isSupported()) {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}
export {messaging}; */

/* console.log("support?",isSupported())
let messaging = new Promise((rej, res) => { });
if (isSupported()) {
  console.log("Hier")
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
} else {
  // dummy methods on messaging object
  messaging.getToken = () => { };
  messaging.onMessage = () => { };
} */

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      const app = initializeApp(firebaseConfig);
      return getMessaging(app);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();


/* export const getFCMToken = () => {
  return getToken(messaging, { vapidKey: "BMKrL-__oYoDc4HGkxSwce6HDZtFajRIaSVvVo6RlFsUvnnF0u7BzrnMGw-nwa4N6c8RrcqPOaXQ7EYjMr1zRHQ" })
    .then((currentToken) => {
      if (currentToken) {
        //console.log("current token for client: ", currentToken);
        return (currentToken)

      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
}; */

export const getFCMToken = async (dispatch) => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      const currentToken = await getToken(messagingResolve, {
        vapidKey: "BMKrL-__oYoDc4HGkxSwce6HDZtFajRIaSVvVo6RlFsUvnnF0u7BzrnMGw-nwa4N6c8RrcqPOaXQ7EYjMr1zRHQ",
      });
      if (currentToken) {
        return currentToken;
      }
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

/* export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  }); */

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        onMessage(messagingResolve, (payload) => {
          //console.log('On message: ', messaging, payload);
          resolve(payload);
        });
      }
    })()
  );
