import React, { useEffect, useState } from "react";
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonPage, IonRow, IonSearchbar, IonText } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { CustomLoading, Header } from "../../components";
import { getMedia } from "../../redux/media/media.actions";
import { RootState } from "../../redux/reducers";
import useWindowDimensions from "../../utils/screen-width";
import { VerticalMediaCard } from "./mediaArchiveComponents";
import { MediaEntry } from "./MediaArchiveInterfaces";

const MediaArchive: React.FC<RouteComponentProps> = ({ history }) => {
    const dispatch = useDispatch();
    const media: MediaEntry[] = useSelector((state: RootState) => state.media.media);
    const categories = useSelector((state: RootState) => state.media.categories);
    const loading: boolean = useSelector((state: RootState) => state.media.loading);
    const [search, setSearch] = useState<string>("");
    const [size, setSize] = useState<string>();
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        dispatch(getMedia(search));
    }, [])

    useEffect(() => {
        const calcBreaktpoints = () => {
            if (width > 2020) {
                setSize("2")
            }
            else if (width > 1600) {
                setSize("3")
            }
            else if (width > 1408) {
                setSize("4")
            }
            else if (width > 600) {
                setSize("6")
            }
            else { setSize("12") }
        }

        calcBreaktpoints()
    }, [width])

    const updateSearchValue = (value) => {
        if (value === "Enter") {
            dispatch(getMedia(search));
        }
    }

    return (
        <IonPage>
            <Header name="Medien-Archiv" />
            <IonContent fullscreen>
                <div className="content">
                    <IonGrid className="higherNewsGrid" style={{ margin: width < 576 ? 0 : "-5px" }}>
                        <IonRow>
                            <IonCol size="12" >
                                <IonCard className="newsFilterCard">
                                    <IonCardContent>
                                        <IonSearchbar
                                            value={search}
                                            onIonChange={e => setSearch(e.detail.value!)}
                                            placeholder="Suche"
                                            enterkeyhint="search"
                                            className="articleSearch"
                                            onIonClear={() => setSearch("")}
                                            mode="md"
                                            debounce={0}
                                            onKeyPress={e => updateSearchValue(e.key)}
                                        />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        {categories.map((category) => {
                            return (
                                <div key={category.id} style={{ marginTop: 40 }}>
                                    <IonRow style={{ padding: 5 }}>
                                        <IonText>
                                            <h3 style={{ margin: 0 }}>{category.name}</h3>
                                        </IonText>
                                    </IonRow>
                                    <IonRow >

                                        {media.map((mediaEntry) => {
                                            if (category.id === mediaEntry.category_id) {
                                                return (
                                                    <IonCol
                                                        size={size}
                                                        key={mediaEntry.id}
                                                        onClick={() => history.push("/mitgliederbereich/medien-archiv/" + mediaEntry.id)}>
                                                        <VerticalMediaCard mediaEntry={mediaEntry} />
                                                    </IonCol>
                                                )
                                            } return null

                                        })}
                                    </IonRow>
                                </div>
                            )
                        })}


                    </IonGrid>

                </div>
            </IonContent>
            <CustomLoading isOpen={loading} />
        </IonPage>
    )
}

export default MediaArchive;