/**
 *
 * Legende für Tabelle
 *
 */

import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";


const TableLegend: React.FC = () => {
    const text = [
        {
            sign: "0",
            description: "weniger als die Hälfte von 1 in der letzten besetzten Stelle, jedoch mehr als nichts"
        },
        {
            sign: "-",
            description: "nichts vorhanden"
        },
        {
            sign: "...",
            description: "Angabe fällt später an"
        },
        {
            sign: "/",
            description: "keine Angaben, da Zahlenwert nicht sicher genug"
        },
        {
            sign: ".",
            description: "Zahlenwert unbekannt oder geheimzuhalten"
        },
        {
            sign: "x",
            description: "Tabellenfach gesperrt, weil Aussage nicht sinnvoll"
        },
        {
            sign: "()",
            description: "Aussagewert eingeschränkt, da der Zahlenwert statistisch relativ unsicher ist"
        },
        {
            sign: "p",
            description: "vorläufige Zahl"
        },
        {
            sign: "r",
            description: "berichtigte Zahl"
        },
        {
            sign: "s",
            description: "geschätzte Zahl"
        },
    ];

    return (
        <IonGrid style={{ marginTop: 20, fontSize: 14 }}>

            {text.map((row) => (
                <IonRow key={row.sign}>
                    <IonCol size="1" className="ion-no-padding">{row.sign}</IonCol>
                    <IonCol className="ion-no-padding">{row.description}</IonCol>
                </IonRow>
            ))}

        </IonGrid>
    )
}

export default TableLegend;