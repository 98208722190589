/**
 *
 * Auflistung der vordefinierten Statistiken
 *
 */

import React, { useEffect } from "react"
import {
    IonButton,
    IonIcon,
} from "@ionic/react";
import { enterOutline } from "ionicons/icons";

import { CategoriesInterface, StatisticsInterface } from "../StatisticsInterfaces";
import AccordionCard from "./AccordionCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { setDestatisStatistics } from "../../../redux/statistics/statistics.actions";

type Tab1Props = {
    destatisState: boolean,
}

const Tab1: React.FC<Tab1Props> = ({ destatisState }: Tab1Props) => {
    const dispatch = useDispatch();
    const categories: CategoriesInterface = useSelector((state: RootState) => state.statistics.destatisStatistics)

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            dispatch(setDestatisStatistics());
        }
        return function cleanup() {
            mounted = false
        }
    }, []);


    return (
        <div>
            <div style={{ overflow: 'hidden' }}>
                <IonButton className="ion-float-right" routerLink="./statistik-kategorien">
                    Kategorien
                    <IonIcon slot="end" icon={enterOutline} />
                </IonButton>
            </div>
            {categories.map((category) => {
                const statistics = category.statistics as StatisticsInterface;
                return (
                    <AccordionCard isDestatisStatistic={true} key={category.id} label={category.label} statistics={statistics} destatisState={destatisState} />
                )
            })}
        </div>
    )
}

export default Tab1;