import { JobFields } from "../../pages/jobPages/JobInterfaces";
import http from "../../utils/http-common";
import {
  ADD_JOB,
  DELETE_JOB,
  JOB_LOADING,
  SET_JOBS,
  SET_JOB_COMPANIES,
  SET_JOB_LABELS,
  UPDATE_JOB,
} from "./jobs.types";

export const setJobs = () => async (dispatch) => {
  dispatch(setJobLoading(true));
  try {
    const res = await http.get("/jobs");
    dispatch({
      type: SET_JOBS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setJobLoading(false));
  }
};

export const setJobLoading = (value: boolean) => {
  return {
    type: JOB_LOADING,
    payload: value,
  };
};

export const setJobLabels = () => async (dispatch) => {
  try {
    const res = await http.get("/jobLabels");
    dispatch({
      type: SET_JOB_LABELS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setJobCompanies = (organisation_id?) => async (dispatch) => {
  let url: string
  if (organisation_id) {
    url = `/contacts?organisation_id=${organisation_id}`
  } else {
    url = `/contacts`
  }
  try {
    const res = await http.get(url);
    dispatch({
      type: SET_JOB_COMPANIES,
      payload: res.data.contacts
    })
  } catch (err) {
    console.log(err);
  }
}

export const addAndEditJob = (values: JobFields) => async (dispatch) => {
  dispatch(setJobLoading(true));
  try {
    const res = await http.post("/saveJob", values);
    if (values.id) {
      //edit
      dispatch({
        type: UPDATE_JOB,
        payload: res.data.job[0],
      });
    } else {
      //add
      dispatch({
        type: ADD_JOB,
        payload: res.data.job[0],
      });
    }
    return Promise.resolve(res.data.job[0]);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(setJobLoading(false));
  }
};

export const deleteJob = (id: number) => async (dispatch) => {
  dispatch(setJobLoading(true));
  try {
    await http.delete(`/deleteJob?id=${id}`);

    dispatch({
      type: DELETE_JOB,
      payload: { id },
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(setJobLoading(false));
  }
};

export const andAndEditJobImage = (data) => async (dispatch) => {
  dispatch(setJobLoading(true));
  try {
    const res = await http.post("/addLogoToJob", data, {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    });
    //console.log(res);
    dispatch({
      type: UPDATE_JOB,
      payload: res.data.job
    })
    return Promise.resolve(res.data.job);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(setJobLoading(false));
  }
};
