import React from "react";
import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle } from "@ionic/react";
import { Header } from "../../components";

import "./Questionaire.css"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { RouteComponentProps, useParams } from "react-router";

const Questionaire: React.FC<RouteComponentProps> = () => {
    const isSignedIn = useSelector((state: RootState) => state.user.isAuthenticated);
    const { id } = useParams<{ id: string }>(); // Survey ID
    const { receiverId } = useParams<{ receiverId: string }>(); // Receiver ID
    const { hash } = useParams<{ hash: string }>(); // Hash

    return (
        <IonPage>
            {isSignedIn ?
                <Header name="DeSH Umfrage" />
                :
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>DeSH Umfrage</IonTitle>
                    </IonToolbar>
                </IonHeader>
            }
            <IonContent >
                <div className="iframeContainer">
                    <iframe src={"https://schnittholz.saegeindustrie.de/survey/" + id + "/" + receiverId + "/" + hash} frameBorder="0"></iframe>
                    {/*  <iframe src={"https://marge6-php82-desh-mb.grips.local/survey/" + id + "/" + receiverId + "/" + hash} frameBorder="0"></iframe> */}
                    {/*<iframe src={"https://192.168.0.234:32769/grips-marge-startseite/web/"} frameBorder="0"></iframe>*/}
                </div>
            </IonContent>
        </IonPage >
    )
}

export default Questionaire;