import React, { useRef } from 'react';
import { CreateAnimation, IonAvatar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { chatbox, heart } from 'ionicons/icons';

import { formatDate, trimHtml } from '../../../utils/general-helpers';
import '../Newsfeed.css';
import { Article } from '../NewsfeedInterfaces';

interface HorizontalNewsfeedCardProps {
    article: Article
}

const HorizontalNewsfeedCard: React.FC<HorizontalNewsfeedCardProps> = ({ article }: HorizontalNewsfeedCardProps) => {
    const animationRef = useRef<CreateAnimation>(null);

    if (article) {
        return (
            <IonCard className="horizontalNewsCard">
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol size="7">
                            <IonCardHeader>
                                <IonCardSubtitle>{formatDate(article.date)}</IonCardSubtitle>
                                <IonCardTitle className="articleCardTitle">{article.headline}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <div dangerouslySetInnerHTML={{ __html: trimHtml(article.content_short, { limit: 200 }).html }}></div>
                            </IonCardContent>
                            <IonRow className="cardFooter">
                                <IonCol className="cardFooterCol">
                                    <span className="cardFooterElement">
                                        <IonAvatar className="profileImage">
                                            <img src={article.profile_pic ? article.profile_pic : "./assets/images/profile_placeholder.svg"} alt="Profilbild Autor" loading="lazy" />
                                        </IonAvatar>
                                        <IonLabel>{article.vorname} {article.name}</IonLabel>
                                    </span>
                                    <span className="ion-float-right cardFooterElement">
                                        <IonIcon className="cardFooterIcon" icon={heart} color={article.useful > 0 ? "secondary" : ""} /> <span style={article.useful > 0 ? { color: "#ef7f00" } : { color: "#737373" }}>{article.useful}</span>
                                        <IonIcon className="cardFooterIcon" icon={chatbox} color={article.comments > 0 ? "secondary" : ""} /> <span style={article.comments > 0 ? { color: "#ef7f00" } : { color: "#737373" }}>{article.comments}</span>
                                    </span>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol style={{ overflow: "hidden" }}>
                            <CreateAnimation
                                ref={animationRef}
                                duration={100}
                                fromTo={{
                                    property: "transform",
                                    fromValue: "scale(1.0)",
                                    toValue: `scale(1.05)`,
                                }}
                                easing="linear"
                            >
                                <img
                                    onMouseOver={() => {
                                        if (animationRef.current !== null) {
                                            animationRef.current.animation.direction("normal");
                                            animationRef.current.animation.play();
                                        }
                                    }}
                                    onMouseOut={() => {
                                        if (animationRef.current !== null) {
                                            animationRef.current.animation.direction("reverse");
                                            animationRef.current.animation.play();
                                        }
                                    }}
                                    className="cardImage"
                                    src={article.image && article.image.img ? article.image.img : "./assets/images/example-image.jpg"}
                                    alt={article.image && article.image.text ? article.image.text : "Holz Querschnitt in Herz-Form"}
                                    loading="lazy"
                                />

                            </CreateAnimation>
                        </IonCol>
                    </IonRow>
                </IonGrid>


            </IonCard>
        );
    } else { return null }

};

export default HorizontalNewsfeedCard;