import { format, isSameDay } from "date-fns";

interface ActivityDateProps {
    von: number,
    bis: number,
    whole_end_day: number,
    whole_start_day: number
}

const ActivityDate: React.FC<ActivityDateProps> = ({ von, bis, whole_end_day, whole_start_day }: ActivityDateProps) => {

    if (bis !== 0) {
        if (isSameDay(new Date(von * 1000), new Date(bis * 1000))) {
            return <>{format(new Date(von * 1000), "dd.MM.yyyy HH:mm")}-{format(new Date(bis * 1000), "HH:mm 'Uhr'")}</>
        } else {
            let ausgabe = "";

            if (whole_start_day === 1) {
                ausgabe += format(new Date(von * 1000), "dd.MM.yyyy");
            } else {
                ausgabe += format(new Date(von * 1000), "dd.MM.yyyy HH:mm ' Uhr");
            }

            ausgabe += " - "

            if (whole_end_day === 1) {
                ausgabe += format(new Date(bis * 1000), "dd.MM.yyyy");
            } else {
                ausgabe += format(new Date(bis * 1000), "dd.MM.yyyy HH:mm ' Uhr");
            }

            return <>{ausgabe}</>
        }
    } else {
        if (whole_start_day === 1) {
            return <>{format(new Date(von * 1000), "dd.MM.yyyy")}</>
        } else {
            return <>{format(new Date(von * 1000), "dd.MM.yyyy HH:mm 'Uhr'")}</>
        }
    }

}

export default ActivityDate;