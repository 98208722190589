import React, { useState } from 'react';
import { IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonList, useIonAlert } from '@ionic/react';
import { alertCircleOutline, chevronDownOutline, chevronUpOutline, pencilOutline, trashOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { User } from '../../profile/ProfileInterfaces';
import { CustomStatisticInterface, StatisticsInterface } from '../StatisticsInterfaces';

interface AccordionCardProps {
  label: string,
  statistics: StatisticsInterface | CustomStatisticInterface[],
  destatisState: boolean,
  isDestatisStatistic: boolean,
  deleteHandler?: (id) => void,
  user?: User,
  temporaryLoggedIn?: boolean,
}

const AccordionCard: React.FC<AccordionCardProps> = ({ label, statistics, destatisState, isDestatisStatistic, deleteHandler, user, temporaryLoggedIn }: AccordionCardProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [present] = useIonAlert();

  return (
    <IonCard className="normalCard">
      <IonCardContent>
        <IonItem button onClick={() => setExpanded(!expanded)} className="collapsibleItem ion-no-padding" style={{ paddingLeft: 0 }} mode='md'>
          <IonLabel><strong>{label}</strong> ({statistics.length})</IonLabel>
          <IonIcon icon={expanded ? chevronUpOutline : chevronDownOutline} />
        </IonItem>
        {expanded &&
          <div className="callapsibleContainer">
            <IonList className="normalList" lines="inset">
              {statistics.map((statistic) => {
                return (
                  <IonItem className="statisticOverviewItem" key={statistic.id} >
                    {destatisState === false &&
                      <IonIcon className="alertIcon" color="danger" icon={alertCircleOutline} />
                    }
                    {!temporaryLoggedIn && user && (user.id === statistic.user_id || user.admin) &&
                      <div slot="end">
                        <IonButton fill="clear" routerLink={`/mitgliederbereich/statistik-bearbeiten/${statistic.id}`}>
                          <IonIcon slot="icon-only" color="primary" icon={pencilOutline} />
                        </IonButton>
                        <IonButton fill="clear" onClick={() =>
                          present({
                            header: 'Löschen',
                            message: 'Möchten Sie die Statistik wirklich löschen?',
                            buttons: [
                              'Nein',
                              { text: 'Ja', handler: () => deleteHandler(statistic.id)},
                            ],
                          })
                        }>
                          <IonIcon slot="icon-only" color="primary" icon={trashOutline} />
                        </IonButton>
                      </div>
                    }
                    {isDestatisStatistic ?
                      <Link style={{ color: "#000", textDecoration: "none" }} to={`/mitgliederbereich/statistik-templates/${statistic.identifier}`}>
                        <IonLabel className="ion-text-wrap">{statistic.label}</IonLabel>
                      </Link> :
                      <Link style={{ color: "#000", textDecoration: "none" }} to={`/mitgliederbereich/statistik/${statistic.id}`}>
                        <IonLabel className="ion-text-wrap">{statistic.title}</IonLabel>
                      </Link>
                    }
                  </IonItem>
                )
              })}
            </IonList>
          </div>
        }
      </IonCardContent>
    </IonCard>
  );
};

export default AccordionCard;
