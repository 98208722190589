import {
  ADD_STATISTIC_CATEGORY,
  DELETE_STATISTIC_CATEGORY,
  SET_STATISTIC_CATEGORIES,
  UPDATE_STATISTIC_CATEGORY,
} from "./statisticCategories.types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_STATISTIC_CATEGORY:
      return [...state, payload];
    case SET_STATISTIC_CATEGORIES:
      return payload;
    case UPDATE_STATISTIC_CATEGORY:
      return state.map((category) => {
        if (category.id === payload.id) {
          return { ...category, ...payload };
        } else {
          return category;
        }
      });
    case DELETE_STATISTIC_CATEGORY:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
}
