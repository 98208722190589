import React, { useEffect, useRef, useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, useIonViewWillLeave } from "@ionic/react";
import { format } from "date-fns";
import { heart, heartOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { CustomLoading, Header } from "../../components";
import { getMediaDetails, increaseDownloadCounter, setSelectedMediaEntry, toggleLike } from "../../redux/media/media.actions";
import { RootState } from "../../redux/reducers";
import { MediaEntry } from "./MediaArchiveInterfaces";
import PDFViewer from "./mediaArchiveComponents/PDFViewer";
import { OrderDialog } from "./mediaArchiveComponents";
import { b64toBlob, isObjectEmpty } from "../../utils/general-helpers";
import http from "../../utils/http-common";


type MediaArchiveDetailProps = {

}


const MediaArchiveDetail: React.FC<MediaArchiveDetailProps> = ({ }: MediaArchiveDetailProps) => {
    const { id } = useParams<{ id: string }>(); // Media ID
    const loading: boolean = useSelector((state: RootState) => state.media.loading);
    const mediaDetail: MediaEntry = useSelector((state: RootState) => state.media.currentMediaEntry);
    const mediaFile: string = useSelector((state: RootState) => state.media.mediaFile);
    const dispatch = useDispatch();
    const [orderDialogOpen, setOrderDialogOpen] = useState<boolean>(false);
    const pdfColRef = useRef(null);

    useEffect(() => {
        dispatch(getMediaDetails(id))
    }, [id])

    const downloadFile = async () => {
        const blob = await b64toBlob(mediaFile);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', mediaDetail.document.name + "." + mediaDetail.document.extension);
        document.body.appendChild(link);
        link.click();
        increaseDownloadCounterRequest(mediaDetail.id)
        dispatch(increaseDownloadCounter(mediaDetail.id));
    }

    const increaseDownloadCounterRequest = async (id: number) => {
        await http.post("/addDownloadCounter", { download_id: id })
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }


    const like = (id: number, document_id: number) => {
        dispatch(toggleLike(id, document_id));
    }

    useIonViewWillLeave(() => {
        dispatch(setSelectedMediaEntry({}, ""))
    });

    return (
        <IonPage>
            <Header name="Medien-Archiv" back />
            <IonContent>
                <div className="content">
                    {!isObjectEmpty(mediaDetail) &&
                        <div>
                            <div className="ion-float-right" style={{ marginRight: 5, display: "inline-flex" }}>
                                <div className="reactionElement" style={{ marginBottom: 10 }}>
                                    <IonButton size="small" fill="clear" type='button' onClick={() => like(mediaDetail.id, mediaDetail.document_id)} >
                                        <IonIcon slot="icon-only" icon={mediaDetail.likedByUser === 1 ? heart : heartOutline} />
                                    </IonButton>
                                    <div className="reactionCount">{mediaDetail.likedCounter}</div>
                                </div>
                            </div>
                            <div style={{ clear: "right" }}></div>
                            <IonCard className="mediaDetailCard" >
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol
                                                sizeXs="12"
                                                sizeSm="12"
                                                sizeMd="6"
                                                sizeLg="6"
                                                sizeXl="6"
                                                ref={pdfColRef}
                                            >
                                                {mediaFile.startsWith("data:application/pdf") ?
                                                    <PDFViewer base64String={mediaFile} /> :
                                                    <img src={mediaFile} />
                                                }
                                            </IonCol>
                                            <IonCol
                                                sizeXs="12"
                                                sizeSm="12"
                                                sizeMd="6"
                                                sizeLg="6"
                                                sizeXl="6"
                                            >
                                                <IonCardTitle>{mediaDetail.document.name}</IonCardTitle>
                                                <div className="mediaMeta">
                                                    <span className="cardFooterElement">
                                                        {mediaDetail.document.extension.toUpperCase()} | {Math.round(mediaDetail.document.size / 1000)} kB | {format(new Date(mediaDetail.document.timestamp), "dd.MM.yyyy")} | Downloads: {mediaDetail.downloadCounter}
                                                    </span>
                                                </div>
                                                <div className="mediaDescription">
                                                    <div dangerouslySetInnerHTML={{ __html: mediaDetail.description }}></div>
                                                </div>
                                                {mediaDetail.orderable === 1 && <IonButton expand="block" color="tertiary" onClick={() => setOrderDialogOpen(true)}>Bestellanfrage senden</IonButton>}
                                                <IonButton expand="block" onClick={() => downloadFile()}>Download</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </div>}

                </div>
            </IonContent>
            <OrderDialog isOpen={orderDialogOpen} handleClose={() => setOrderDialogOpen(false)} id={id} />
            <CustomLoading isOpen={loading} />

        </IonPage >
    )
}

export default MediaArchiveDetail;