import React, { useEffect } from "react";
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { Network } from '@capacitor/network';
import { setupIonicReact } from '@ionic/react';
import "./firebase";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './assets/fonts/roboto-font.css'

//Components
import { RootState } from './redux/reducers';
import { loadUserData, logoutUser } from "./redux/user/user.actions";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from "@mui/styles";
import { setNetworkOnline } from './redux/network/network.actions';

/* Pages */
import NewsletterLogin from './pages/login/NewsletterLogin';
import { Login } from "./pages";
import NotFoundPage from './pages/errorPages/404';
// import Survey from './pages/survey/Survey';
import ForgotPassword from './pages/login/ForgotPassword';
import AppMenu from './components/menu/AppMenu';
import Questionaire from "./pages/questionaire/Questionaire";


const theme = createTheme({
  palette: {
    primary: {
      main: "#1d405a",
    },
    secondary: {
      main: "#ef7f00",
    },
  },
});

setupIonicReact();

const App: React.FC = () => {
  const isSignedIn = useSelector((state: RootState) => state.user.isAuthenticated);
  const token = useSelector((state: RootState) => state.user.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSignedIn === null) {
      dispatch(loadUserData());
    }
  }, [dispatch, isSignedIn])

  useEffect(() => {
    const validateToken = () => {
      if (token) {
        const decodedToken = jwt_decode(token)
        const currentTime = Date.now() / 1000;
        //@ts-ignore
        if (decodedToken.exp < currentTime) {
          dispatch(logoutUser());
        }
      }
    }
    validateToken();
  }, [token, dispatch])


  useEffect(() => {

    const getInitialNetworkState = async () => {
      const status = await Network.getStatus();
      dispatch(setNetworkOnline(status.connected))
    }

    getInitialNetworkState();

    Network.addListener('networkStatusChange', status => {
      if (!status.connected) {
        alert("Sie Sind offline. Änderungen werden nicht gespeichert.")
      }
      dispatch(setNetworkOnline(status.connected))
    });

    return () => {
      Network.removeAllListeners();
    };

  }, [dispatch])


  if (isSignedIn === null) return null;

  return (
    <IonApp>
      <ThemeProvider theme={theme}>
        <IonReactRouter>
          <IonRouterOutlet id='container'>
              <Route
                path="/mitgliederbereich"
                render={(props) => {
                  return isSignedIn ? <AppMenu {...props} /> : <Login />;
                }}
              />
              {/*  <Route
              path="/mitgliederbereich"
              render={(props) => {
                return  <AppMenu {...props} /> ;
              }}
            /> */}
              <Route
                exact
                path="/login"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/passwort-vergessen"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/umfrage/:id/:receiverId/:hash"
                render={(props) => <Questionaire {...props} />}
              />
              <Route
                path="/newsletter/:userId/:articleId/:hash"
                render={(props) => <NewsletterLogin {...props} />}
              />
              <Redirect exact from="/" to="/mitgliederbereich/newsfeed" />
              <Route
                path={`/404`}
                render={(props) => <NotFoundPage {...props} />}
              />
              <Redirect to={`/404`} />
          </IonRouterOutlet>
        </IonReactRouter>
      </ThemeProvider>
    </IonApp>
  );
}


export default App;
