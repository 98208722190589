import React, { useState } from "react";
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { isObjectEmpty } from "../../../utils/general-helpers";
import { EditPasswordFields, FormInput } from "../ProfileInterfaces";

type ProfileEditModalProps = {
    isOpen: boolean;
    closeModal: Function,
    onSave: (values: EditPasswordFields) => void,
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ isOpen, closeModal, onSave }: ProfileEditModalProps) => {
    const [values, setValues] = useState<EditPasswordFields>({
        old_password: "",
        new_password1: "",
        new_password2: ""
    });
    const [errors, setErrors] = useState({});

    const form: FormInput[] = [
        {
            name: "old_password",
            label: "Aktuelles Passwort",
            type: "password",
            required: true,
        },
        {
            name: "new_password1",
            label: "Neues Passwort",
            type: "password",
            required: true,
        },
        {
            name: "new_password2",
            label: "Neues Passwort (Wiederholung)",
            type: "password",
            required: true,
        }
    ]

    const handleChange = (fieldname: string, value: string) => {
        setValues({ ...values, [fieldname]: value })
    }

    const handleBlur = (fieldName: string) => {
        form.forEach((inputField) => {
            if ((inputField.name === fieldName)) {
                //check if required and empty
                if (inputField.required && values[fieldName] === "") {
                    setErrors({ ...errors, [fieldName]: "Bitte füllen Sie das Feld aus." })
                }
                // check min length
                else if ((fieldName === "new_password1" || fieldName === "new_password2") && values[fieldName].length < 8) {
                    setErrors({ ...errors, [fieldName]: "Passwort muss mindestens 8 Zeichen lang sein." })
                }
                // check if passwords are equal
                else if (fieldName === "new_password1"  && values.new_password2 !== "" && values.new_password1 !== values.new_password2) {
                    setErrors({ ...errors, [fieldName]: "Passwörter stimmen nicht überein." })
                }
                else if (fieldName === "new_password2"  && values.new_password1 !== "" && values.new_password1 !== values.new_password2) {
                    setErrors({ ...errors, [fieldName]: "Passwörter stimmen nicht überein." })
                }
                // remove error
                else {
                    let tempErrors = { ...errors };
                    delete tempErrors[fieldName]
                    setErrors(tempErrors)
                }
            }
        })
    }

    const saveNewUserData = () => {
        if (isObjectEmpty(errors) && values.new_password1 === values.new_password2) {
            onSave(values)
        }
    }

    return (
        <IonModal isOpen={isOpen} className='profileEditModal'>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Passwort ändern</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={(e) => closeModal(false)}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {/*  <IonItemDivider className="ion-no-padding">Allgemein</IonItemDivider> */}
                <IonGrid>
                    <IonRow>
                        {form.map((field) => {
                            return (
                                <IonCol size="12" key={field.name}>
                                    <IonItem className="ion-no-padding">
                                        <IonLabel position="stacked">{field.label}{field.required && "*"}</IonLabel>
                                        <IonInput
                                            type={field.type}
                                            value={values[field.name]}
                                            required={field.required}
                                            onIonChange={(e) => handleChange(field.name, e.detail.value!)}
                                            onIonBlur={e => handleBlur(field.name)}
                                        ></IonInput>
                                    </IonItem>
                                    {errors[field.name] !== undefined && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors[field.name]}</div>}
                                </IonCol>
                            )
                        })}

                    </IonRow>
                </IonGrid>
                <div className="requiredLabel ion-float-right">*Pflichtfeld</div>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => closeModal(false)}>Abbrechen</IonButton>
                        <IonButton onClick={saveNewUserData} color="primary" fill="solid">Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default ProfileEditModal;