/**
 *
 * Card mit Button für das Fetchen der Statistik
 *
 */


import React from "react";
import { IonCardHeader, IonCard, IonGrid, IonRow, IonCol, IonCardTitle, IonButton } from "@ionic/react";

type ApplyFilterCardProps = {
    filterCount?: number,
    applyFilter: () => void,
    resultSet?: number,
    fromCompressedView?: boolean,
}

const ApplyFilterCard: React.FC<ApplyFilterCardProps> = ({ filterCount, applyFilter, resultSet, fromCompressedView }: ApplyFilterCardProps) => {

    return (
        <IonCard className="normalCard">
            <IonCardHeader>
                <IonGrid className="noPaddingGrid">
                    <IonRow className="ion-align-items-center">
                        <IonCol size="12" sizeSm="6">
                            <IonCardTitle className="cardTitle ">Filter anwenden{filterCount && "*"}</IonCardTitle>
                            {filterCount &&<div>*maximal {filterCount} Datenreihen in Graph darstellbar</div>}
                        </IonCol>
                        <IonCol size="12" sizeSm="6">
                            <IonButton className="ion-float-right" type="submit" onClick={applyFilter}>Diagramm {fromCompressedView ? "aktualisieren" : "anzeigen"}</IonButton>
                        </IonCol>
                    </IonRow>
                    {resultSet > filterCount &&
                        <IonRow>
                            <IonCol >
                                <div style={{ color: "#ef7f00" }}>Warnung: Die Ergebnismenge beläuft sich auf {resultSet} Datenreihen.</div>
                            </IonCol>
                        </IonRow>
                    }
                </IonGrid>
            </IonCardHeader>
        </IonCard>
    )
}

export default ApplyFilterCard;