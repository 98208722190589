import {
  ADD_JOB,
  DELETE_JOB,
  JOB_LOADING,
  SET_JOBS,
  SET_JOB_COMPANIES,
  SET_JOB_LABELS,
  UPDATE_JOB,
} from "./jobs.types";

//const initialState = [];
const initialState = {
  jobs: [],
  jobTypes: [],
  jobNames: [],
  loading: false,
  companies: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_JOBS:
      return {
        ...state,
        jobs: payload,
      };
    case JOB_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_JOB_LABELS:
      return {
        ...state,
        jobTypes: payload.types,
        jobNames: payload.jobs,
      };
    case SET_JOB_COMPANIES:
      return {
        ...state,
        companies: payload
      };
    case ADD_JOB:
      return {
        ...state,
        jobs: [...state.jobs, payload],
      };
    case UPDATE_JOB:
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.id === payload.id) {
            return { ...job, ...payload };
          } else {
            return job;
          }
        }),
      };
    case DELETE_JOB:
      return {
        ...state,
        jobs: state.jobs.filter(({ id }) => id !== payload.id),
      };
    default:
      return state;
  }
}
