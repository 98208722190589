import { combineReducers } from "redux";
import articleReducers from "./articles/article.reducers";
import errorReducer from "./errors/errorReducer";
import mediaReducers from "./media/media.reducers";
import userReducer from "./user/user.reducer";
import networkReducer from "./network/network.reducer";
import statisticCategoriesReducers from "./statisticCategories/statisticCategories.reducers";
import statisticsReducer from "./statistics/statistics.reducer";
import jobsReducer from "./jobs/jobs.reducer";
/* import surveyReducers from "./survey/survey.reducers"; */

const appReducer = combineReducers({
  errors: errorReducer,
  user: userReducer,
  article: articleReducers,
  media: mediaReducers,
  network: networkReducer,
  statisticCategories: statisticCategoriesReducers,
  statistics: statisticsReducer,
  jobs: jobsReducer, 
  /* survey: surveyReducers, */
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
