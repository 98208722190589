/**
 *
 * Card für die Auswahl des Zeitraumes
 * (klingt richtig abgespaced der Komponenten-Name)
 *
 */

import React, { useEffect } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonDatetime, IonGrid, IonInput, IonItem, IonLabel, IonPopover, IonRadio, IonRadioGroup, IonRow } from "@ionic/react";

import "../Statistics.css";
import { radioList } from "../StatisticsData";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import useWindowDimensions from "../../../utils/screen-width";


type SpaceOfTimeCardProps = {
    compare: boolean,
    setCompare: (compare: boolean) => void;
    startYear: Date,
    setStartYear: (startYear: Date) => void;
    endYear: Date,
    setEndYear: (endYear: Date) => void;
    selectedRadioButton: string,
    setSelectedRadioButton: (selectedRadioButton: string) => void;
    viewMode?: boolean,
    customTitle?: string,
    setCustomTitle?: (title: string) => void,
    interval?: string,
}

const SpaceOfTimeCard: React.FC<SpaceOfTimeCardProps> = ({ compare, setCompare, startYear, setStartYear, endYear, setEndYear, selectedRadioButton, setSelectedRadioButton, viewMode, customTitle, setCustomTitle, interval }: SpaceOfTimeCardProps) => {
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (interval === "y") {
            setSelectedRadioButton("customDate");
        }
    }, [interval])


    const handleRadioButtons = (value: string) => {
        setSelectedRadioButton(value);
        switch (value) {
            case "month12":
                setStartYear(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
                setEndYear(new Date(new Date().setMonth(new Date().getMonth() - 1)));
                break;
            case "month24":
                setStartYear(new Date(new Date().setFullYear(new Date().getFullYear() - 2)));
                setEndYear(new Date(new Date().setMonth(new Date().getMonth() - 1)));
                break;
            case "yearLast":
                setStartYear(new Date(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1)));
                setEndYear(new Date(new Date().setFullYear(new Date().getFullYear() - 1, 11, 1)));
                break;
            case "customDate":
                break;
        }
    }

    return (
        <IonCard className="normalCard">
            <IonCardHeader>
                <IonCardTitle className="cardTitle">Zeitraum</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {interval !== "y" ? <div>
                    <IonRadioGroup value={selectedRadioButton} onIonChange={e => handleRadioButtons(e.detail.value)}>
                        <IonGrid className="noPaddingGrid">
                            <IonRow>
                                {radioList.map(({ label, val }, i) => (
                                    <IonCol key={i} className="checkboxCol">
                                        <IonItem lines="none" className="radioItem ion-no-padding">
                                            <IonLabel>{label}</IonLabel>
                                            <IonRadio slot="start" value={val} className="radio" color="secondary" />
                                        </IonItem>
                                    </IonCol>
                                ))}
                            </IonRow>
                        </IonGrid>
                    </IonRadioGroup>
                    {selectedRadioButton === "customDate" &&
                        <IonGrid style={{ marginTop: "20px" }}>
                            <IonRow style={{ paddingLeft: "5px", color: "black" }}>Freier Zeitraum</IonRow>
                            <IonRow>
                                <IonCol size="2" sizeXs="12" sizeSm="4" sizeMd="2">
                                    <IonItem className="customSpaceOfTimeItem">
                                        <IonInput id="startYear" value={format(startYear, "MM-yyyy")}></IonInput>
                                        <IonPopover trigger="startYear" size="cover" arrow={false} id="birthdayPopover">
                                            <IonDatetime
                                                presentation="month-year"
                                                onIonChange={e => setStartYear(new Date(e.detail.value! as string))}
                                                locale="de-DE"
                                                value={startYear.toISOString()}
                                                max={new Date().toISOString()}
                                            />
                                        </IonPopover>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="1" sizeXs="12" sizeSm="2" sizeMd="1" style={{ textAlign: "center", paddingTop: "20px" }}>bis</IonCol>
                                <IonCol size="2" sizeXs="12" sizeSm="4" sizeMd="2">
                                    <IonItem className="customSpaceOfTimeItem">
                                        <IonInput id="endYear" value={format(endYear, "MM-yyyy")}></IonInput>
                                        {/* <IonDatetime value={String(endYear)} doneText="OK" cancelText="Abbrechen" locale="de-DE" placeholder="MM-YYYY" onIonChange={e => setEndYear(new Date(e.detail.value!))}></IonDatetime> */}
                                        <IonPopover trigger="endYear" size="cover" arrow={false} id="birthdayPopover">
                                            <IonDatetime
                                                presentation="month-year"
                                                onIonChange={e => setEndYear(new Date(e.detail.value! as string))}
                                                locale="de-DE"
                                                value={endYear.toISOString()}
                                                max={new Date().toISOString()}
                                            />
                                        </IonPopover>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>}
                    {interval === "m" ?
                        <div>{startYear && <p style={{ padding: "12px 5px 0 5px" }}>Gewählter Zeitraum: {format(startYear, "MMM yyyy", { locale: de })} - {format(endYear, "MMM yyyy", { locale: de })}</p>}</div> :
                        <div>{startYear && <p style={{ padding: "12px 5px 0 5px" }}>Die Auswertung erfolgt quartalsweise. Gewählter Zeitraum: {format(startYear, "QQQ yyyy", { locale: de })} - {format(endYear, "QQQ yyyy", { locale: de })}</p>}</div>
                    }
                </div> :
                    <IonGrid >
                        <IonRow>
                            <IonCol size="2" sizeXs="12" sizeSm="4" sizeMd="2">
                                <IonItem className="customSpaceOfTimeItem">
                                    <IonInput id="startYear" value={format(startYear, "yyyy")}></IonInput>
                                    <IonPopover trigger="startYear" size="cover" arrow={false} id="birthdayPopover">
                                        <IonDatetime
                                            presentation="year"
                                            onIonChange={e => setStartYear(new Date(e.detail.value! as string))}
                                            locale="de-DE"
                                            value={startYear.toISOString()}
                                            max={new Date().toISOString()}
                                        />
                                    </IonPopover>
                                </IonItem>
                            </IonCol>
                            <IonCol size="1" sizeXs="12" sizeSm="2" sizeMd="1" style={{ textAlign: "center", paddingTop: "20px" }}>bis</IonCol>
                            <IonCol size="2" sizeXs="12" sizeSm="4" sizeMd="2">
                                <IonItem className="customSpaceOfTimeItem">
                                    <IonInput id="endYear" value={format(endYear, "yyyy")}></IonInput>
                                    {/* <IonDatetime value={String(endYear)} doneText="OK" cancelText="Abbrechen" locale="de-DE" placeholder="MM-YYYY" onIonChange={e => setEndYear(new Date(e.detail.value!))}></IonDatetime> */}
                                    <IonPopover trigger="endYear" size="cover" arrow={false} id="birthdayPopover">
                                        <IonDatetime
                                            presentation="year"
                                            onIonChange={e => setEndYear(new Date(e.detail.value! as string))}
                                            locale="de-DE"
                                            value={endYear.toISOString()}
                                            max={new Date().toISOString()}
                                            min={startYear.toISOString()}
                                        />
                                    </IonPopover>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                }
                <IonGrid style={{ marginTop: "20px" }}>
                    <IonRow style={{ color: "black" }}>
                        <IonCol sizeXs={"12"} sizeSm={"12"} sizeMd={viewMode ? "6" : "12"} sizeXl={viewMode ? "6" : "12"} sizeLg={viewMode ? "6" : "12"} className="checkboxCol">
                            <div>
                                <b color="black">Vergleich</b><br />
                                <IonItem lines="none" className="checkboxItem ion-no-padding">
                                    <IonLabel className="ion-text-wrap" style={{ paddingTop: 4 }}>Vergleich mit Vorzeitraum</IonLabel>
                                    <IonCheckbox
                                        color="secondary"
                                        slot="start"
                                        value={"comparison"}
                                        checked={compare}
                                        className="checkbox"
                                        onIonChange={e => setCompare(e.detail.checked)}
                                    />
                                </IonItem>
                            </div>
                        </IonCol>
                        {viewMode && viewMode === true &&
                            <IonCol sizeXs={"12"} sizeSm={"12"} sizeMd={"6"} sizeXl={"6"} sizeLg={"6"}>
                                <div style={{ marginTop: width > 768 ? 0 : 20 }}>
                                    <b color="black">Anzeigetitel Diagramm-Download</b><br />
                                    <IonItem /* lines="none" */ className="checkboxItem ion-no-padding">
                                        <IonInput
                                            type="text"
                                            max="200"
                                            value={customTitle}
                                            onIonChange={(e) => setCustomTitle(e.detail.value!)}
                                        ></IonInput>
                                    </IonItem>
                                </div> </IonCol>}
                    </IonRow>

                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default SpaceOfTimeCard;