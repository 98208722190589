
/**
 *
 * Card für die Anzeige der Datenreihen und zugehörigen Columns
 *
 */

import React from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

import { AttributeInterface, AttributeValueInterface } from "../StatisticsInterfaces";
import useWindowDimensions from "../../../utils/screen-width";

type DatarowCardProps = {
    attribute: AttributeInterface, /* datarow values with meta information */
    index: number, /* index of datarow */
    //expanded: boolean[], /* state -> is show more expanded? */
    //setExpanded: (expanded: boolean[]) => void, /* change expanded state */
    columnExpanded: boolean[], /* state -> is show columns  expanded? */
    setColumnExpanded: (columnExpanded: boolean[]) => void, /* change column expanded state */
    openShowAllModal: (id: number, name: string) => void, /* show modal for all datarow values */
    checked: object, /* checked datarow values */
    setChecked: (checked: object) => void, /* change checked datarow values state*/
    checkedColumns: object, // checked columns values
    setCheckedColumns: (checked: object) => void, // change checked columns values state
    isModalOpen: boolean,
    handleUserFeedback: () => void,
}

const DatarowCard: React.FC<DatarowCardProps> = ({ attribute, index, /* expanded, setExpanded, */ columnExpanded, setColumnExpanded, openShowAllModal, checked, setChecked, checkedColumns, setCheckedColumns, isModalOpen, handleUserFeedback }: DatarowCardProps) => {
    const { height, width } = useWindowDimensions();
    // Toggle for Second Row
    /* const toggleCollapsed = (index: number) => {
        let temp = expanded[index];
        if (temp === true) {
            temp = false;
        } else {
            temp = true;
        }
        setExpanded([...expanded.slice(0, index), temp, ...expanded.slice(index + 1)])
    } */

    //Toggle for Columns
    const toggleColumnCollapsed = (index: number) => {
        let temp = columnExpanded[index];
        if (temp === true) {
            temp = false;
        } else {
            temp = true;
        }
        setColumnExpanded([...columnExpanded.slice(0, index), temp, ...columnExpanded.slice(index + 1)])
    }

    //Checkboxes Datarows
    const handleCheckbox = (attributeName: string, key: string, valueName: string) => {
        if (!isModalOpen) {
            if (checked[attributeName] && checked[attributeName].some(e => e.id === key)) {
                //remove
                setChecked((obj) => {
                    obj[attributeName] = obj[attributeName].filter(item => {
                        return item.id !== key;
                    })
                    return ({ ...obj })
                })
            } else {
                //add
                setChecked((checked) => {
                    checked[attributeName].push({
                        id: key,
                        name: valueName
                    })
                    return ({ ...checked })
                })
            }
            handleUserFeedback()
        }

    }

    //Checkboxes Columns
    const handleColumnCheckbox = (attributeName: string, key: string, valueName: string) => {
        if (checkedColumns[attributeName].some(e => e.id === key)) {
            //remove
            setCheckedColumns((obj) => {
                obj[attributeName] = obj[attributeName].filter(item => {
                    return item.id !== key;
                })
                return ({ ...obj })
            })
        } else {
            //add
            setCheckedColumns((checked) => {
                checked[attributeName].push({
                    id: key,
                    name: valueName
                })
                return ({ ...checked })
            })
        }
    }

    // Show which Checkboxes are selected
    const printSelectedCheckboxes = (dataRow: string) => {
        if (checked[dataRow] && checked[dataRow].length > 0) {
            let temp = [];
            checked[dataRow].forEach((checkedValue) => {
                temp.push(checkedValue.name)
            })
            return (
                <p style={{ paddingTop: 20, paddingLeft: 16 }}>Gewählte Checkboxen: {temp.join(", ")}</p>
            )
        }
    }

    const isChecked = (name, code) => {
        if (name && checked[name]) {
            if (checked[name].some(e => e.id === code)) {
                return true
            }
            return false
        } return false;
    }

    return (
        <IonCard className="normalCard">
            <IonCardHeader>
                <IonCardTitle className="cardTitle">Datenreihen ({attribute.value})</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>

                {/* First Row */}
                <IonGrid className="noPaddingGrid">
                    <IonRow>
                        {attribute.values.map((value: AttributeValueInterface) => {
                            /* if (value.favorite === true) { */
                            return (
                                <IonCol key={value.code} size="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" className="checkboxCol">
                                    <IonItem lines="none" className="checkboxItem ion-no-padding" >
                                        <IonLabel className="ion-text-wrap" style={{ paddingTop: 5 }}>{value.content}</IonLabel>
                                        <IonCheckbox
                                            color="secondary"
                                            slot="start"
                                            value={value.code}
                                            checked={isChecked(attribute.value, value.code)}
                                            className="checkbox"
                                            onIonChange={() => handleCheckbox(attribute.value, value.code, value.content)}
                                        />
                                        {width > 576 && <IonLabel slot="end" style={{ paddingTop: 5 }}>{value.code || ""}</IonLabel>}
                                    </IonItem>
                                </IonCol>
                            )
                            /*  } */
                        })}
                    </IonRow>
                </IonGrid>

                {/* Second Row */}
                {/* {attribute.has_more &&
                    <div>
                        <IonItem lines="full" mode='md' button onClick={() => toggleCollapsed(index)} className="collapsibleItem collapsible">
                            <IonLabel>Mehr Datenreihen anzeigen</IonLabel>
                            <IonIcon icon={expanded[index] ? chevronUpOutline : chevronDownOutline} />
                        </IonItem>
                        {expanded[index] &&
                            <div className="callapsibleContainer">
                                <IonGrid>
                                    <IonRow>
                                        {attribute.values.map((value: AttributeValueInterface) => {
                                            if (value.favorite === false) {
                                                return (
                                                    <IonCol key={value.code} size="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" className="checkboxCol">
                                                        <IonItem lines="none" className="checkboxItem">
                                                            <IonLabel className="ion-text-wrap" style={{ paddingTop: 4 }}>{value.content}</IonLabel>
                                                            <IonCheckbox
                                                                color="secondary"
                                                                slot="start"
                                                                value={value.code}
                                                                checked={checked[attribute.value].some(e => e.id === value.code)}
                                                                className="checkbox"
                                                                onIonChange={e => handleCheckbox(attribute.value, value.code, value.content)}
                                                            />
                                                            <IonLabel slot="end">{value.code || ""}</IonLabel>
                                                        </IonItem>
                                                    </IonCol>
                                                )
                                            }
                                        })}
                                    </IonRow>

                                    {attribute.has_even_more &&
                                        <IonRow style={{ marginTop: 24 }}>
                                            <IonCol>
                                                <IonButton fill="clear" onClick={() => openShowAllModal(attribute.id, attribute.value)} className="ion-float-right">Alle anzeigen</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    }

                                </IonGrid>
                            </div>
                        }
                    </div>
                } */}

                {/* Columns */}
                {attribute.has_column_filter &&
                    <div>
                        <IonItem button onClick={() => toggleColumnCollapsed(index)} className="collapsibleItem collapsible" style={{ width: "100%!important" }}>
                            <IonLabel>Tabellenspalten</IonLabel>
                            <IonIcon icon={columnExpanded[index] ? chevronUpOutline : chevronDownOutline} />
                        </IonItem>
                        {columnExpanded[index] &&
                            <div className="callapsibleContainer">
                                <IonGrid className="noPaddingGrid">
                                    <IonRow>
                                        {attribute.columns.map((column) => {
                                            return (
                                                <IonCol key={column.code} size="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" className="checkboxCol">
                                                    <IonItem lines="none" className="checkboxItem">
                                                        <IonLabel className="ion-text-wrap" style={{ paddingTop: 5 }}>{column.content}</IonLabel>
                                                        <IonCheckbox
                                                            color="secondary"
                                                            slot="start"
                                                            value={column.code}
                                                            checked={checkedColumns[attribute.value].some(e => e.id === column.code)}
                                                            className="checkbox"
                                                            onIonChange={e => handleColumnCheckbox(attribute.value, column.code, column.content)}
                                                        />
                                                        {width > 576 && <IonLabel slot="end" style={{ paddingTop: 5 }}>{column.code || ""}</IonLabel>}
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })}
                                    </IonRow>
                                </IonGrid>
                            </div>
                        }
                    </div>
                }

                {/* Third Row */}
                {(!attribute.has_more && attribute.has_even_more) &&
                    <IonGrid>
                        <IonRow style={{ marginTop: 24 }}>
                            <IonCol>
                                <IonButton fill="clear" className="ion-float-right" onClick={() => openShowAllModal(attribute.id, attribute.value)}>Alle anzeigen</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                }
                {printSelectedCheckboxes(attribute.value)}
            </IonCardContent>
        </IonCard>
    )
}

export default DatarowCard;