import React from "react";
import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButton } from "@ionic/react";
import { Header } from "../../components";

import "./Error.css"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { RouteComponentProps } from "react-router";

const NotFoundPage: React.FC<RouteComponentProps> = ({history}) => {
    const isSignedIn = useSelector((state: RootState) => state.user.isAuthenticated);

    return (
        <IonPage>
            {isSignedIn ?
                <Header name="DeSH Mitgliederbereich" />
                :
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>DeSH Mitgliederbereich</IonTitle>
                    </IonToolbar>
                </IonHeader>
            }
            <IonContent >
                <div className="errorContainer">
                    <div>
                        <h1><strong>Fehler 404</strong> - Seite nicht gefunden</h1>
                        <p>Der gerodete Wald des Internets... Die angeforderte Seite konnte leider nicht gefunden werden!</p>
                        {isSignedIn ? <IonButton href="/mitgliederbereich/newsfeed" className="notFoundButton">Zurück zur Startseite</IonButton> :
                            <IonButton href="/login" className="notFoundButton">Zurück zum Login</IonButton>}
                    </div>
                </div>
                <div className="ax-container" >
                    <img src="/assets/images/not-found.png" alt="Axt steckt in Baumstumpf" className="ax-image" />
                </div>
            </IonContent>
        </IonPage >
    )
}

export default NotFoundPage;