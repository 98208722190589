import http from '../../utils/http-common';
import {
    SET_CUSTOM_STATISTICS,
    SET_DESTATIS_STATISTICS,
    SET_STATISTICS_LOADING
} from './statistics.types';

export const setDestatisStatistics = () => async (dispatch) => {
    dispatch(showLoading());
    try {
        const res = await http.get('/fetchStatisticCategories');
        dispatch({
            type: SET_DESTATIS_STATISTICS,
            payload: res.data.categories
        })
    } catch (err) {
        console.log(err)
    } finally {
        dispatch(hideLoading());
    }
}

export const setCustomStatistics = () => async(dispatch) => {
    dispatch(showLoading());
    try {
        const res = await http.get('/fetchCustomStatistics');
        dispatch({
            type: SET_CUSTOM_STATISTICS,
            payload: res.data.statistics
        })
    } catch (err) {
        console.log(err)
    } finally {
        dispatch(hideLoading());
    }
}

export const hideLoading = () => {
    return ({
        type: SET_STATISTICS_LOADING,
        payload: false
    })
}

export const showLoading = () => {
    return ({
        type: SET_STATISTICS_LOADING,
        payload: true
    })
}

