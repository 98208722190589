import React from "react";
import { IonAvatar, IonButton, IonIcon } from "@ionic/react";
import MUIDataTable from "mui-datatables";

import "../Jobs.css";
import { Job } from "../JobInterfaces";
import { imageOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

type JobTableProps = {
    jobs: Job[],
    isMember: boolean,
    editAction: (dataIndex: number) => void
    deleteAction: (id: number) => void
    onLogoClick: (dataIndexId: number) => void
}

const options = {
    print: false,
    download: false,
    selectableRows: "none",
    pagination: true,
    responsive: "standard",
    textLabels: {
        body: {
            noMatch: "Keine Einträge gefunden",
            toolTip: "Sortieren",
            columnHeaderTooltip: column => `Sortiere nach ${column.label}`
        },
        pagination: {
            next: "Nächste Seite",
            previous: "Vorherige Seite",
            rowsPerPage: "Zeilen pro Seite:",
            displayRows: "von",
        },
        toolbar: {
            search: "Suchen",
            viewColumns: "Spalten anzeigen",
            filterTable: "Tabelle filtern",
        },
        filter: {
            all: "Alle",
            title: "FILTER",
            reset: "Zurücksetzen",
        },
        viewColumns: {
            title: "Spalten anzeigen",
            titleAria: "Tabellen-Spalten anzeigen/verstecken",
        },
    }
}



const JobTable: React.FC<JobTableProps> = ({ jobs, isMember, editAction, deleteAction, onLogoClick }: JobTableProps) => {
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin);

    const columns = [
        {
            label: "Id",
            name: "id",
            options: {
                display: "excluded",
                filter: false
            }
        },
        {
            label: "Logo",
            name: "logo",
            options: {
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <IonAvatar style={{ width: 40, height: 40 }}>
                            <img src={jobs[dataIndex].logo} alt={`Logo von ${jobs[dataIndex].org_name}`}/>
                        </IonAvatar>
                    );
                },
                setCellProps: () => ({
                    align: "center"
                })
            }
        },
        {
            label: "Titel",
            name: "name",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Beruf",
            name: "job_name",
            options: {
                sort: true,
                filter: true,
            }
        },
        {
            label: "Firma",
            name: "org_name",
            options: {
                display: isMember ? "excluded" : true,
                filter: !isMember,
            },
        },
        {
            label: "Datum",
            name: "date",
            options: {
                sort: true,
                filter: false,
                display: false,
                customBodyRender: date => { return format(new Date(date), "dd.MM.yyyy") },
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        let val1 = new Date(obj1.data);
                        let val2 = new Date(obj2.data);
                        //@ts-ignore
                        return (val1 - val2) * (order === 'asc' ? 1 : -1);
                    };
                }
            }
        },
        {
            label: "Aktionen",
            name: "actions",
            options: {
                sort: false,
                filter: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <div style={{ display: "inline-flex" }}>
                            <IonButton disabled={temporaryLoggedIn === 0 ? false : true} fill="clear" size='small' onClick={() => deleteAction(jobs[dataIndex].id)}>
                                <IonIcon slot="icon-only" icon={trashOutline} />
                            </IonButton>
                            <IonButton disabled={temporaryLoggedIn === 0 ? false : true} fill="clear" size='small' onClick={() => editAction(dataIndex)}>
                                <IonIcon slot="icon-only" icon={pencilOutline} />
                            </IonButton>
                            <IonButton disabled={temporaryLoggedIn === 0 ? false : true} fill="clear" size='small' onClick={() => onLogoClick(dataIndex)}>
                                <IonIcon slot="icon-only" icon={imageOutline} />
                            </IonButton>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "right"
                }),
                setCellHeaderProps: () => ({
                    align: "right",
                })
            }
        }
    ];
    //console.log(jobs)
    return (
        <MUIDataTable title="Jobs" columns={columns} data={jobs} options={options} />
    )
}

export default JobTable;