import React from "react";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import "../Newsfeed.css";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { ArticleImage } from "../NewsfeedInterfaces";
import { IonButton, IonIcon } from "@ionic/react";
import { MobileStepper } from "@mui/material";
type SlideshowProps = {
    images: ArticleImage[],
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Slideshow: React.FC<SlideshowProps> = ({ images }: SlideshowProps) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <div>
            <AutoPlaySwipeableViews
                axis="x"
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={8000}
                className="swipeableImageContainer"
                disableLazyLoading={true}
                springConfig={{ duration: '1.0s', easeFunction: 'ease', delay: '0s' }} 
            >
                {images.map((step, index) => (
                    <div key={step.id} >
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img
                                src={step.img}
                                alt={step.text}
                                height="auto"
                                width="100%"
                            />
                        ) : null}
                    </div>
                ))}

            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <IonButton
                        size="small"
                        fill="clear"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Weiter
                        <IonIcon icon={chevronForwardOutline} />
                    </IonButton>
                }
                backButton={
                    <IonButton size="small" onClick={handleBack} fill="clear" disabled={activeStep === 0}>
                        <IonIcon icon={chevronBackOutline} />
                        Zurück
                    </IonButton>
                }
            />
        </div>
    )
}

export default Slideshow;