import React, { useEffect } from 'react';
import { IonSplitPane, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonRouterOutlet, IonIcon, IonLabel, useIonToast, } from '@ionic/react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { homeOutline, statsChartOutline, personOutline, archiveOutline, briefcaseOutline, openOutline } from 'ionicons/icons';
import { useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from '../../redux/reducers';

import './AppMenu.css';
import { StatisticsOverview, StatisticsDetail, Profile, Newsfeed, NewsfeedDetail, StatisticsDetailCompressed, ForbiddenPage, StatisticCategories, JobsOverview, MediaArchive, MediaArchiveDetail, /* SurveyPage, */ ActivityPage } from '../../pages';
import { getFcmMessageToken, getUser } from '../../redux/user/user.actions';
import NotFoundPage from '../../pages/errorPages/404';
import { onMessageListener } from '../../firebase';


const AppMenu: React.FC<RouteComponentProps> = ({ match }) => {
    let path = useLocation().pathname;
    const user = useSelector((state: RootState) => state.user.user, shallowEqual)
    const dispatch = useDispatch();
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin);
    const messageToken = useSelector((state: RootState) => state.user.fcm_token)
    const [present, dismiss] = useIonToast();

    useEffect(() => {
        if (messageToken.token === "") {
            dispatch(getFcmMessageToken())
        }
    }, [messageToken])

    /* Push Notification Listener wenn App im Vordergrund ist */
    onMessageListener().then(payload => {
        //console.log("in appmenu", payload)
        present({
            buttons: [{
                icon: openOutline, handler: () => {
                    dismiss()
                    window.location.href = payload.data.link
                }
            }],
            message: '<b>' + payload.data.title + '</b><br/>' + payload.data.body + '',
            duration: 5000,
            color: 'light'
        })
    }).catch(err => console.log('failed: ', err));

    useEffect(() => {
        dispatch(getUser());
    }, [/* dispatch */])


    const pages = [
        {
            label: "Newsfeed",
            link: "newsfeed",
            icon: homeOutline,
            component: Newsfeed
        },
        {
            label: "Statistik",
            link: "statistik",
            icon: statsChartOutline,
            component: StatisticsOverview
        },
        {
            label: "Medien-Archiv",
            link: "medien-archiv",
            icon: archiveOutline,
            component: MediaArchive
        },
        {
            label: "Stellenanzeigen",
            link: "jobs",
            icon: briefcaseOutline,
            component: JobsOverview
        },
    ]

    return (
        <IonSplitPane contentId="main" className="splitPane">
            <IonMenu side="start" contentId="main">
                <IonHeader className="no-shadow">
                    <IonToolbar color="primary">
                        <IonTitle><img src="/assets/images/desh-Logo-weiss.png" height="32px" width="auto" style={{ marginTop: "4.4px" }} alt="DeSH Logo" /></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent color="primary">
                    <IonList lines="none" color="primary" className="menuList">
                        {pages.map((page) => (
                            <IonItem href={`${match.url}/${page.link}`} mode='md' className="menuItem" key={page.link}>
                                <IonIcon icon={page.icon} slot="start" className={path.startsWith(`${match.url}/${page.link}`) ? "menuIcon-active" : "menuIcon"} />
                                <IonLabel className={path.startsWith(`${match.url}/${page.link}`) ? "menuLabel-active" : "menuLabel"}>{page.label}</IonLabel>
                            </IonItem>
                        ))}
                        {temporaryLoggedIn !== 1 ?
                            <IonItem href={`${match.url}/profil`} mode='md' className="menuItem" >
                                <IonIcon icon={personOutline} slot="start" className={path.startsWith(`${match.url}/profil`) ? "menuIcon-active" : "menuIcon"} />
                                <IonLabel className={path.startsWith(`${match.url}/profil`) ? "menuLabel-active" : "menuLabel"}>Profil</IonLabel>
                            </IonItem> :

                            <IonItem href={`${match.url}/profil`} mode='md' className="menuItem" disabled>
                                <IonIcon icon={personOutline} slot="start" className={path.startsWith(`${match.url}/profil`) ? "menuIcon-active" : "menuIcon"} />
                                <IonLabel className={path.startsWith(`${match.url}/profil`) ? "menuLabel-active" : "menuLabel"}>Profil</IonLabel>
                            </IonItem>
                        }

                    </IonList>
                </IonContent>
            </IonMenu>
            <IonRouterOutlet id="main">
                {pages.map((page) => {
                    if (page.link === "newsfeed") {
                        return (
                            <Route path={`${match.url}/${page.link}/:hashtagId?/:hashtagLabel?`} key={page.link} component={page.component} />
                        )
                    } else {
                        return (
                            <Route exact path={`${match.url}/${page.link}`} key={page.link} component={page.component} />
                        )
                    }

                })}
                <Route exact path={`${match.url}/profil`}
                    render={() => {
                        return temporaryLoggedIn === 1 ? <ForbiddenPage /> : <Profile />
                    }}
                />

                <Route path={`${match.url}/statistik-templates/:id`} render={() => {
                    return user.member ? <ForbiddenPage /> : <StatisticsDetail editMode={false} />
                }} />

                <Route path={`${match.url}/statistik-bearbeiten/:id`} render={() => {
                    return user.member ? <ForbiddenPage /> : <StatisticsDetail editMode={true} />
                }} />

                <Route path={`${match.url}/statistik/:id`}>
                    <StatisticsDetailCompressed />
                </Route>

                <Route path={`${match.url}/newsfeed-artikel/:id`}>
                    <NewsfeedDetail />
                </Route>

                <Route path={`${match.url}/statistik-kategorien`}>
                    <StatisticCategories />
                </Route>

                <Route path={`${match.url}/medien-archiv/:id`}>
                    <MediaArchiveDetail />
                </Route>

                <Route path={`${match.url}/aktivitaet/:id`} render={(props) =><ActivityPage {...props}/>}>
                </Route>

                {/* <Route path={`${match.url}/umfrage/:surveyId/:receiverId`} render={(props) => { return <SurveyPage {...props} /> }} /> */}

                <Route component={NotFoundPage} />

            </IonRouterOutlet>
        </IonSplitPane >
    )
}

export default withRouter(AppMenu);
