import React from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonIcon, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { download, heart, imageOutline } from "ionicons/icons";
import { trimHtml } from "../../../utils/general-helpers";
import { MediaEntry } from "../MediaArchiveInterfaces";

import "../MediaArchive.css";

interface VerticalMediaCardProps {
    mediaEntry: MediaEntry,
}

const VerticalMediaCard: React.FC<VerticalMediaCardProps> = ({ mediaEntry }: VerticalMediaCardProps) => {

    return (
        <IonCard className="verticalMediaArchiveCard">
            {mediaEntry.preview ?
                <div className="mediaImageWrapper">
                    <img src={mediaEntry.preview.img}
                        alt="Vorschaubild"
                        className="mediaImage"
                    />
                </div>
                : <div className="mediaPlaceholderImageWrapper">
                    {mediaEntry.document.type === "PDF" ?
                        <img alt="PDF Platzhalter Bild" className="placeholderImage" src="/assets/images/download.svg" /> :
                        <IonIcon icon={imageOutline} className="imagePlaceholderIcon" />}
                </div>
            }
            <IonCardHeader>
                <IonCardTitle>{mediaEntry.text}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <div dangerouslySetInnerHTML={{ __html: trimHtml(mediaEntry.description, { limit: 200 }).html }}></div>
            </IonCardContent>
            <IonRow className="cardFooter">
                <IonCol className="cardFooterCol">
                    <span className="cardFooterElement">
                        {mediaEntry.document.extension.toUpperCase()} | {Math.round(mediaEntry.document.size / 1000)} kB | {format(new Date(mediaEntry.document.timestamp), "dd.MM.yyyy")}
                    </span>
                    <span className="ion-float-right cardFooterElement">
                        <IonIcon className="cardFooterIcon" icon={download} /><span>{mediaEntry.downloadCounter}</span>
                    </span>
                    <span className="ion-float-right cardFooterElement">
                        <IonIcon className="cardFooterIcon" icon={heart} /><span>{mediaEntry.likedCounter}</span>
                    </span>
                </IonCol>
            </IonRow>
        </IonCard>
    )
}

export default VerticalMediaCard;