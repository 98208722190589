// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { IonIcon, isPlatform } from "@ionic/react"
import { addOutline, chevronBackOutline, chevronForwardOutline, contractOutline, expandOutline, printOutline, removeOutline } from "ionicons/icons";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import printJS from 'print-js';
import useWindowDimensions from "../../../utils/screen-width";


//const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

pdfjs.GlobalWorkerOptions.workerSrc = `./pdf.worker.min.js`;


interface PDFViewerProps {
    base64String: string,
}

const PDFViewer: React.FC<PDFViewerProps> = ({ base64String }: PDFViewerProps) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const containerRef = useRef<HTMLDivElement>(null);
    const [fullscreen, setFullScreen] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(500);
    const [isFirefoxFullscreen, setIsFirefoxFullscreen] = useState<boolean>(false);
    const {height} = useWindowDimensions();


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function zoomOut() {
        setScale(prevScaleNumber => prevScaleNumber - 0.25);
    }

    function zoomIn() {
        setScale(prevScaleNumber => prevScaleNumber + 0.25);
    }


    const handlePrint = (event) => {
        event.preventDefault();
        let string = base64String.replace("data:application/pdf;base64,", "");
        printJS({ printable: string, type: "pdf", base64: true })
    }

    function removeTextLayerOffset() {
        const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
        textLayers.forEach(layer => {
            //@ts-ignore
            const { style } = layer;
            style.top = "0";
            style.left = "0";
            style.transform = "";
        });
    }

    function openFullscreen() {
        setFullScreen(true)
        const elem = document.getElementById("pdftest");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen()
        } else if (elem.mozRequestFullScreen) {
            setIsFirefoxFullscreen(true);
            elem.mozRequestFullScreen()
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen()
        }
    }

    function closeFullscreen() {
        setFullScreen(false)
        if (document.fullscreenElement) {
            document.exitFullscreen()
        } else if (document.webkitFullscreenElement) {
            document.webkitExitFullscreen()
        } else if (document.mozFullScreenElement) {
            document.mozCancelFullScreen();
            setIsFirefoxFullscreen(false);
        } else if (document.msFullscreenElement) {
            document.msExitFullscreen()
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth - 1)
            }
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [containerRef.current])

    useEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.offsetWidth - 1)
        }

        function exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                setFullScreen(false)
                setIsFirefoxFullscreen(false);
            }
        }

        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);

        return () => {
            document.removeEventListener("fullscreenchange", exitHandler);
            document.removeEventListener('webkitfullscreenchange', exitHandler);
            document.removeEventListener('mozfullscreenchange', exitHandler);
            document.removeEventListener('MSFullscreenChange', exitHandler);
        }

    }, [])



    return (
        <div id="pdftest" ref={containerRef} className={isPlatform("desktop") ? "pdfContainer desktop" : "pdfContainer mobile"}>

            <Document
                loading="PDF laden..."
                file={base64String}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}

            >
                {/* @ts-ignore */}
                <Page renderTextLayer={false} scale={scale} onLoadSuccess={removeTextLayerOffset} pageNumber={pageNumber} /* width={width} */ width={!fullscreen ? width : undefined} height={fullscreen ? height : undefined}/>
            </Document>

            {!isFirefoxFullscreen &&
                <div className={fullscreen ? "pageControls fullscreen" : "pageControls"}>
                    <button
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        <IonIcon icon={chevronBackOutline} />
                    </button>
                    <span>{pageNumber || (numPages ? 1 : '--')} von {numPages || '--'}</span>
                    <button
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        <IonIcon icon={chevronForwardOutline} />
                    </button>
                    <span>|</span>
                    <button
                        type="button"
                        onClick={zoomOut}
                        disabled={+scale.toFixed(2) <= 0.25 ? true : false}
                    >
                        <IonIcon icon={removeOutline} />
                    </button>
                    <span>{Math.round(scale * 100)}%</span>
                    <button
                        type="button"
                        onClick={zoomIn}
                        disabled={scale > 5 ? true : false}
                    >
                        <IonIcon icon={addOutline} />
                    </button>

                    <span>|</span>
                    {!fullscreen && <button
                        type="button"
                        onClick={openFullscreen}
                        id="open-pdf-fullscreen"
                    >
                        <IonIcon icon={expandOutline} />
                    </button>}
                    {fullscreen && <button
                        type="button"
                        onClick={closeFullscreen}
                        id="open-pdf-fullscreen"
                    >
                        <IonIcon icon={contractOutline} />
                    </button>}
                    <button
                        type="button"
                        onClick={(e) => handlePrint(e)}
                    >
                        <IonIcon icon={printOutline} />
                    </button>
                </div>}
        </div>
    )
}

export default PDFViewer;