import http from "../../utils/http-common";
import { get, remove, set } from "../../utils/storage";

import { SET_CURRENT_USER, SET_CURRENT_TOKEN, USER_LOADING, SET_MESSAGE_TOKEN } from "./user.types";
import { GET_ERRORS } from "../errors/errorTypes";
import { getFCMToken } from "../../firebase";

// get User Data from API
export const getUser = () => async (dispatch) => {
    let user = await get("user");
    if (user) {
        dispatch(setCurrentUser(user));
    } else {
        http
            .get("/user", { params: { with_contact_data: 1, /* with_assortment_data: 1 */ } })
            .then((res) => {
                //console.log("in user request")
                set("user", res.data.result);
                dispatch(setCurrentUser(res.data.result));
            })
            .catch((err) =>
                console.log(err)
            );
    }
}

// Set User
export const setCurrentUser = (user) => {
    return {
        type: SET_CURRENT_USER,
        payload: user,
    };
};

// Update User on Edit
export const updateUser = (data, resolve, reject) => {
    return dispatch => {
        http
            .put(`/updateUser`, data)
            .then(response => {
                //console.log(response);
                if (response.data.status) {
                    if (response.data.status === 'Bitte neu einloggen') {
                        reject(response.data.status);
                    }
                } else {
                    set("user", response.data.result);
                    dispatch(setCurrentUser(response.data.result));
                    return resolve(response.data.user);
                }
            })
            .catch(error => {
                console.log(error);
                return reject(error);
            })
    }
}

// update User Image
export const updateUserImage = data => {
    return dispatch => {
        http
            .post(`/updateProfileImage`, data, {
                headers: {
                    "Content-Type": `multipart/form-data`,
                }
            })
            .then(response => {
                set("user", response.data.result);
                dispatch(setCurrentUser(response.data.result));
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: GET_ERRORS,
                    payload: error.response.data,
                })
            });
    }
}

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
    http
        .post("/login", userData)
        .then((res) => {
            const { token } = res.data;
            return (token)
        })
        .then((token) => {
            set("token", token);
            set("temporaryLogin", 0);
            return token
        })
        .then((token) => {
            dispatch(setCurrentToken(token, 0));
            dispatch({
                type: GET_ERRORS,
                payload: {},
            })
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data,
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: err,
                })
            }
        }

        )

};



// Set logged in user
export const setCurrentToken = (decoded, tempLogin) => {
    return {
        type: SET_CURRENT_TOKEN,
        payload: { token: decoded, temporaryLogin: tempLogin },
    };
};

// User loading
export const setUserLoading = (isLoading: boolean) => {
    return {
        type: USER_LOADING,
        isLoading
    };
};

// logout
export const logoutUser = () => async (dispatch) => {
    removeUserDataFromStorage();
    dispatch(setCurrentToken("", 0));
    dispatch(setCurrentUser({}));
}


// load user Data from Storage (if exists)
export const loadUserData = () => async (dispatch) => {
    dispatch(setUserLoading(true));
    const { token, temporary_login } = await getStorageData();
    dispatch(setCurrentToken(token, temporary_login));
    dispatch(setUserLoading(false));
}

export const getFcmMessageToken = () => async (dispatch) => {
    let messageToken = await get("fcm_token");
    if (messageToken && validateMessageTokenDurability(messageToken.timestamp)) {
        dispatch(setFcmMessageToken(messageToken.token, messageToken.timestamp));
    } else {
        await getFCMToken()
            .then((token) => {
                if (token) {
                    http
                        .post(`/addMessageToken`, {
                            user_id: 0,
                            token: token
                        })
                        .then((res) => {
                            dispatch(setFcmMessageToken(token, new Date()));
                            set("fcm_token", { token: token, timestamp: new Date() })
                        })
                        .catch(err => console.log(err))
                }
            })
            .catch(err => console.log(err))
    }
}

const validateMessageTokenDurability = (date) => {
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

    return !(+twoMonthsAgo > +date)
}

export const setFcmMessageToken = (token, timestamp) => {
    return {
        type: SET_MESSAGE_TOKEN,
        payload: { token: token, timestamp: timestamp },
    };
}


// STORAGE FUNCTIONS

//retrieve user data from storage
export const getStorageData = async () => {
    const response = await Promise.all([
        get("token"),
        get("temporaryLogin"),]);
    const token = await response[0] || undefined;
    const temporary_login = await response[1] || 0
    const data = {
        token,
        temporary_login
    }
    return data;
}

export const removeUserDataFromStorage = async () => {
    await Promise.all([
        set("token", ""),
        remove("user"),
        set("temporaryLogin", 0)
    ]);
}

// login User if he clicks on newsletter link
export function loginUserViaNewsletter(
    userId,
    articleId,
    hash,
    resolve,
    reject
) {
    return function (dispatch) {

        http
            .get(`/newsletterArticle/${userId}/${articleId}/${hash}`)
            .then((res) => {
                const { token } = res.data;
                return (token)
            })
            .then((token) => {
                set("token", token);
                set("temporaryLogin", 1)
                return token
            })
            .then((token) => {
                dispatch(setCurrentToken(token, 1));
            })
            .then((token) => {
                resolve(token);
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data,
                })
                reject(err);
            }
            );
    };
}

// login User if he clicks on survey link
export function loginUserViaSurvey(
    userId,
    receiverId,
    surveyId,
    hash,
    resolve,
    reject
) {
    return function (dispatch) {

        http
            .get(`/survey/${surveyId}/${receiverId}/${userId}/${hash}`)
            .then((res) => {
                const { token } = res.data;
                return (token)
            })
            .then((token) => {
                set("token", token);
                set("temporaryLogin", 1)
                return token
            })
            .then((token) => {
                dispatch(setCurrentToken(token, 1));
            })
            .then((token) => {
                resolve(token);
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data,
                })
                reject(err);
            }
            );
    };
}