import { JobFields } from "./JobInterfaces";

export const initialJobValues: JobFields = {
    text: "",
    name: "",
    link: "",
    typ: null,
    job_name_id: null,
    contact_id: null,
    contact: null
}