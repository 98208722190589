/**
 *
 * abgespeckte Detailansicht für personalisierte Statistiken (nur Datum änderbar)
 *
 */

import React, { useEffect, useRef, useState } from "react";
import { IonButton, IonCardContent, IonCol, IonContent, IonIcon, IonPage, IonRow } from "@ionic/react";
import { useParams } from "react-router";
import domtoimage from 'dom-to-image';
import { downloadOutline } from "ionicons/icons";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';

//Eigene Importe
import "../Statistics.css";
import { CustomLoading, ErrorBoundary, Header } from "../../../components";
import http from "../../../utils/http-common";
import { CustomStatisticInterface, Statistic } from "../StatisticsInterfaces"; // Benötigte Interfaces für Typescript
import { ApplyFilterCard, DiagrammCard, SpaceOfTimeCard, StatisticsPrint, StatisticsTable } from "../statisticsComponents";
import { formatDateForAPI } from "../../../utils/general-helpers";
import { options } from "../StatisticsData";
import { RootState } from "../../../redux/reducers";
import { ForbiddenPage } from "../..";
import { timePeriodCalculation } from "../statisticsComponents/TimePeriodCalculation";
import useWindowDimensions from "../../../utils/screen-width";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



/* Haupt-Komponente */
const StatisticsDetailCompressed: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [customStatistic, setCustomStatistic] = useState<CustomStatisticInterface>();
    const [startYear, setStartYear] = useState<Date>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [endYear, setEndYear] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [compare, setCompare] = useState<boolean>(false);
    const [compareLineType, setCompareLineType] = useState<string>("transparent");
    const [loading, setLoading] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [data, setData] = useState<Statistic>({
        labels: [],
        datasets: []
    });
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const componentRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<ChartJS>(null);
    const [graphOptions, setGraphOptions] = useState(options);
    const defaultLabels = { title: "", subtitle: "", footer: "", };
    const user = useSelector((state: RootState) => state.user.user)
    const [selectedRadioButton, setSelectedRadioButton] = useState<string>("month12");
    const [customTitle, setCustomTitle] = useState<string>("");
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        fetchCustomStatistic();
    }, [])

    //console.log(dataTest)


    useEffect(() => {
        setGraphOptions((graphOptions) => {
            if (width > 576) {
                graphOptions.aspectRatio = 2;
            } else {
                graphOptions.aspectRatio = 1;
                //console.log("hier")
            }
            return ({ ...graphOptions })
        })
    }, [width])



    const fetchCustomStatistic = async () => {
        setLoading(true);
        await http
            .get(`/fetchCustomStatistic?statistic_id=${id}`)
            .then(function (response) {
                //console.log(response.data.statistic)
                setCustomStatistic(response.data.statistic)
                setGraphOptions((graphOptions) => {
                    response.data.statistic.y_scale_min !== null ? graphOptions.scales.y.min = parseInt(response.data.statistic.y_scale_min) : graphOptions.scales.y.min = undefined;
                    response.data.statistic.y_scale_max !== null ? graphOptions.scales.y.max = parseInt(response.data.statistic.y_scale_max) : graphOptions.scales.y.min = undefined;
                    return ({ ...graphOptions })
                })
                setCustomTitle(response.data.statistic.title);
                if (response.data.statistic.compare === 1) setCompare(true);
                if (response.data.statistic.compare_line_type) setCompareLineType(response.data.statistic.compare_line_type);
                if (response.data.statistic.period_type !== null) {
                    setSelectedRadioButton(response.data.statistic.period_type);
                    let { start, end } = timePeriodCalculation(response.data.statistic.period_type, response.data.statistic.period_start, response.data.statistic.period_end);
                    setStartYear(start);
                    setEndYear(end);
                    return ({ statistic: response.data.statistic, start: start, end: end })
                } else {
                    return ({ statistic: response.data.statistic, start: startYear, end: endYear })
                }
            })
            .then(({ statistic, start, end }) => {
                fetchStatistic(statistic, start, end, statistic.compare === 1 ? true : false, statistic.compare_line_type ? statistic.compare_line_type : "transparent")
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            })
    }

    const fetchStatistic = async (statistic, start, end, comp, compType) => {
        setLoading(true)
        await http
            .get(`/fetchStatistic?startyear=${formatDateForAPI(start)}&endyear=${formatDateForAPI(end)}&compare=${comp}&compare_line_type=${compType}`, {
                /* params: {
                    source: customStatistic.source,
                    base_identifier: customStatistic.base_identifier,
                    title: customStatistic.title,
                    subtitle: customStatistic.subtitle,
                    footer: customStatistic.footer,
                    y_scale_min: customStatistic.y_scale_min,
                    y_scale_max: customStatistic.y_scale_max,
                    aggregates: JSON.stringify(customStatistic.statisticAggregates),
                    filter: JSON.stringify(customStatistic.statisticFilters),
                } */
                params: {
                    source: statistic.source,
                    base_identifier: statistic.base_identifier,
                    title: statistic.title,
                    subtitle: statistic.subtitle,
                    footer: statistic.footer,
                    y_scale_min: statistic.y_scale_min,
                    y_scale_max: statistic.y_scale_max,
                    aggregates: JSON.stringify(statistic.statisticAggregates),
                    filter: JSON.stringify(statistic.statisticFilters),
                }
            })
            .then(function (response) {
                //console.log(response)
                if (response.data.data.length > 0) {
                    // preparing response for table
                    response.data.labels.forEach(function (label: string, index: number) {
                        if (statistic.interval !== "y") response.data.labels[index] = label.split('-');
                    });
                    data.labels = response.data.labels;
                    data.datasets = response.data.data;
                    data.copyright = response.data.copyright;

                    // don't know why - need to save in var before set...
                    let tmpTblColumns = response.data.tableData.labels;
                    let tmpTblData = response.data.tableData.data;

                    tmpTblColumns.forEach(function (col) {

                        if (col.options !== undefined && col.options.sticky !== undefined && col.options.sticky === true) {
                            col.options.setCellProps = () => ({
                                style: {
                                    position: "sticky",
                                    left: "0",
                                    background: "white",
                                    zIndex: 100
                                }
                            });

                            col.options.setCellHeaderProps = () => ({
                                style: {
                                    position: "sticky",
                                    left: 0,
                                    background: "white",
                                    zIndex: 101
                                }
                            })
                        }
                    })

                    setTableData(tmpTblData);
                    setTableColumns(tmpTblColumns);
                    setData(data);
                    setShowResults(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // diagramm download
    function printDocument() {
        setLoading(true);

        const node = componentRef.current;

        domtoimage.toBlob(node, {
            width: 1576,
            height: 1167
        })
            .then(function (blob) {
                domtoimage.toBlob(node, {
                    width: 1576,
                    height: 1167
                }).then(blob2 => {
                    saveAs(blob2, 'Statistik.png');
                }).catch(function (error) {
                    console.error('Fehler beim Download', error);
                })

            }).catch(function (error) {
                console.error('Fehler beim Download', error);
            })
            .finally(() => {
                setLoading(false)
            });

    }

    // for role-management
    const checkMemberCondition = () => {
        return (user && user.member && customStatistic && !customStatistic.member)
    }

    const checkEmployeeCondition = () => {
        return (user && customStatistic && user.id !== customStatistic.user_id && user.employee && !customStatistic.employee)
    }



    if (checkMemberCondition() || checkEmployeeCondition()) {
        return <ForbiddenPage />
    } else {
        return (
            <IonPage>
                <Header back={true} name="Statistik" />
                <IonContent>

                    {customStatistic &&
                        <>
                            <div className="content">
                                <h4 className="headline">{customStatistic.title || ""}</h4>
                                <div style={{ marginBottom: "40px" }}>{customStatistic.base_identifier}</div>
                                <div>
                                    <SpaceOfTimeCard compare={compare} setCompare={setCompare} startYear={startYear} setStartYear={setStartYear} endYear={endYear} setEndYear={setEndYear} selectedRadioButton={selectedRadioButton} setSelectedRadioButton={setSelectedRadioButton} viewMode={true} customTitle={customTitle} setCustomTitle={setCustomTitle} interval={customStatistic.interval} />
                                    <ApplyFilterCard applyFilter={() => fetchStatistic(customStatistic, startYear, endYear, compare, compareLineType)} fromCompressedView={true} />


                                    {showResults &&
                                        <>

                                            <DiagrammCard data={data} subtitle={customStatistic.subtitle}>
                                                <ErrorBoundary>
                                                    {/* @ts-ignore */}
                                                    <Line data={data} options={graphOptions} ref={chartRef} redraw />
                                                </ErrorBoundary>
                                                <IonCardContent>
                                                    <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                                        <IonButton style={{ margin: "20px 10px" }} onClick={printDocument}>
                                                            <IonIcon slot="start" icon={downloadOutline} /> Download
                                                        </IonButton>
                                                    </div>
                                                    <IonRow style={{ fontSize: 12 }}>
                                                        <IonCol size="6">
                                                            {customStatistic.footer}
                                                        </IonCol>
                                                        <IonCol size="6" style={{ textAlign: "right" }}>
                                                            © Deutsche Säge- und Holzindustrie Bundesverband e. V., {new Date().getFullYear()}<br />
                                                            Quelle: {data.copyright || ""}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </DiagrammCard>
                                        </>
                                    }

                                    {showResults &&
                                        <StatisticsTable tableData={tableData} tableColumns={tableColumns} />
                                    }
                                </div>
                            </div>

                            <div style={{ position: "absolute", left: "-9999px" }}>
                                <StatisticsPrint ref={componentRef} data={data} options={graphOptions} customLabels={customStatistic ? { title: customTitle, subtitle: customStatistic.subtitle, footer: customStatistic.footer, } : defaultLabels} copyright={data.copyright || ""} />
                            </div>
                        </>
                    }

                </IonContent>
                <CustomLoading isOpen={loading} />
            </IonPage>
        )
    }
}

export default StatisticsDetailCompressed;