import React from "react";
/* import ReactDOM from "react-dom/client"; */
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { createRoot } from 'react-dom/client';

import store from "./redux/store";


/* const render = () => {
  const App = require("./App").default;
  ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
  );
};

render();


if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
} */

const container = document.getElementById('root');
const root = createRoot(container!); 
const App = require("./App").default;
root.render(
  <Provider store={store}>
        <App />
    </Provider>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onUpdate: async (registration: ServiceWorkerRegistration) => {
    await registration.update();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
