import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from 'react-router-dom';
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useDispatch, useSelector } from "react-redux";
import { loadUserData, loginUserViaNewsletter } from "../../redux/user/user.actions";
import { RootState } from "../../redux/reducers";

interface NewsletterLoginProps extends RouteComponentProps { }

const NewsletterLogin: React.FC<NewsletterLoginProps> = ({ history }) => {
    const { userId, articleId, hash } = useParams<{ userId: string, articleId: string, hash: string }>();
    const dispatch = useDispatch();
    const isSignedIn = useSelector((state: RootState) => state.user.isAuthenticated);
    const [error, setError] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>("");

    useEffect(() => {
        dispatch(loadUserData());
    }, [dispatch])

    useEffect(() => {
        test();
    }, [isSignedIn])

    const test = () => {
        if (!isSignedIn) {
            let test = new Promise<void>((resolve, reject) => {
                dispatch(loginUserViaNewsletter(userId, articleId, hash, resolve, reject))
            })
            test.then(() => {
                history.push("/mitgliederbereich/newsfeed-artikel/" + articleId);
            })
            test.catch((error) => {
                setErrorMsg(error.response.data.error);
                setError(true);
            })
        } else { history.push("/mitgliederbereich/newsfeed-artikel/" + articleId); }
    }

    const renderContent = () => {
        if (error) {
            return (
                <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>DeSH Mitgliederbereich</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div className="errorContainer">
                            <div>
                                <p>{errorMsg}</p>
                                <IonButton href="/login" className="notFoundButton">Zurück zum Login</IonButton>
                            </div>
                        </div>
                    </IonContent>
                </IonPage>
            )
        } else { return null }
    }

    return renderContent();
};

export default NewsletterLogin;