import { ARTICLE_LOADING, SET_AUTHORS, SET_CURRENT_ARTICLE, SET_HASHTAGS, SET_LATEST_COMMENTS } from "./article.types";

const initialState = {
    article: {},
    authors: [],
    hashtags: [],
    articles: [],
    latestComments: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_ARTICLE:
            return {
                ...state,
                article: action.payload,
            };
        case SET_AUTHORS:
            return {
                ...state,
                authors: action.payload,
            };
        case SET_HASHTAGS:
            return {
                ...state,
                hashtags: action.payload,
            };
        case SET_LATEST_COMMENTS:
            return {
                ...state,
                latestComments: action.payload,
            };
        case ARTICLE_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}

