import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import './Login.css';
import '../Style.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from '../../redux/reducers';
import { validateEmail } from '../../utils/general-helpers';
import http from '../../utils/http-common';
import { ErrorToast } from '../statistics/StatisticsInterfaces';
import { CustomLoading } from '../../components';

const ForgotPassword: React.FC<RouteComponentProps> = ({ history }) => {
    const [email, setEmail] = useState<string>("");
    const [toast, setToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" });
    const [mailError, setMailError] = useState<boolean>(false);
    const [emptyMailError, setEmptyMailError] = useState<boolean>(false);
    const isSignedIn = useSelector((state: RootState) => state.user.isAuthenticated);
    const [toggleContent, setToggleContent] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin);

    useEffect(() => {
        checkIfAlreadySignedIn();
    }, [isSignedIn])

    const checkIfAlreadySignedIn = () => {
        if (isSignedIn === true && temporaryLoggedIn === 0) {
            history.push("/mitgliederbereich/newsfeed")
            history.go(0);
        }
    }

    const handleChange = (e) => {
        setEmptyMailError(false);
        setEmail(e.target.value);
    };

    const handleOnBlur = () => {
        //validate Email
        if (email === "") { setEmptyMailError(true) }
        else {
            if (validateEmail(email)) {
                setMailError(false)
            } else {
                setMailError(true);
            }
        }

    }

    const onKeyUpValue = (e: any) => {
        if (e.keyCode === 13) {
            resetPassword(e);
        }
    }

    const resetPassword = (e: any) => {
        e.preventDefault();
        if (!emptyMailError && !mailError && email !== "") {
            requestPasswortReset()
        };
    }

    const requestPasswortReset = async () => {
        setIsLoading(true);
        await http
            .post('/resetPassword', { email: email })
            .then(() => {
                setToggleContent(false);
            })
            .catch((error) => {
                console.log(error);
                setToast({open: true, message: error.response.data, color: "danger"})
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="" />
                    </IonButtons>
                    <IonTitle>Passwort vergessen?</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center ion-align-items-center ion-justify-items-center">
                <div className="loginContainer ion-justify-items-center">
                    <IonCard className="card">
                        <img src="/assets/images/desh-Logo-blau.png" className="logo" alt="DeSH Logo" />
                        <IonCardHeader>
                            <IonCardTitle>{toggleContent ? "Passwort vergessen?" : "Sie haben Post"}</IonCardTitle>
                        </IonCardHeader>
                        {toggleContent ? <IonCardContent >
                            <p style={{ textAlign: "left", marginBottom: 30 }}>Kein Problem. Geben Sie einfach die E-Mail-Adresse ein, die mit Ihrem Account verknüft ist, und wir senden Ihnen einen Link zu, mit dem Sie Ihr Passwort neu setzen können.</p>
                            <IonItem className="loginItem ion-no-padding">
                                <input
                                    value={email}
                                    type="email"
                                    onChange={(e) => handleChange(e)}
                                    placeholder="E-Mail"
                                    className="loginInput"
                                    onBlur={() => handleOnBlur()}
                                    required
                                    onKeyUp={onKeyUpValue.bind(this)}
                                />
                            </IonItem>
                            {mailError && <div style={{ textAlign: "left", color: "#eb445a" }}>Ungültige E-Mail-Adresse</div>}
                            {emptyMailError && <div style={{ textAlign: "left", color: "#eb445a" }}>Bitte geben Sie eine E-Mail-Adresse an</div>}

                            <IonButton className="loginButton" type="submit" onClick={(e) => resetPassword(e)}>Weiter</IonButton>
                        </IonCardContent>
                            :
                            <IonCardContent >
                                <p style={{ textAlign: "left" }}>Wir haben Ihnen eine E-mail mit Ihrem neuen Passwort geschickt. Wir empfehlen Ihnen, im Anschluss ein eigenes Passwort unter <b>Profil - Passwort ändern</b> zu setzen.</p>
                                <IonButton className="loginButton" type="submit" onClick={() => history.push("/login")}>Zurück zum Login</IonButton>
                            </IonCardContent>
                        }
                    </IonCard>
                </div>
                
            </IonContent>
            <IonToast
                isOpen={toast.open}
                onDidDismiss={() => setToast({open: false, message: "", color: "danger"})}
                message={toast.message}
                duration={3000}
                color={toast.color}
            />
            <CustomLoading isOpen={isLoading} />
        </IonPage>
    )
}

export default withRouter(ForgotPassword);
