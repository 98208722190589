import http from "../../utils/http-common";
import { USER_LOADING } from "../user/user.types";

// types
import { SET_ARTICLES, SET_AUTHORS, SET_CURRENT_ARTICLE, SET_HASHTAGS, SET_LATEST_COMMENTS } from "./article.types";

export const getArticle = (id, resolve, reject) => (dispatch) => {
    dispatch(setArticleLoading(true));
    http
        .get(`/article?id=${id}`)
        .then((res) => {
            //console.log(res)
            dispatch(setArticleLoading(false));
            dispatch(setCurrentArticle(res.data.article));
            return resolve(res.data.article);
        })
        .catch((err) => {
            dispatch(setArticleLoading(false));
            return reject(err);
        });
}

export const setCurrentArticle = (article) => {
    return {
        type: SET_CURRENT_ARTICLE,
        payload: article,
    };
};

export const removeCurrentArticle = () => {
    return {
        type: SET_CURRENT_ARTICLE,
        payload: {}
    }
}

export const getAuthors = () => (dispatch) => {
    http
        .get('/getAuthors')
        .then((response) => {
            //console.log(response)
            dispatch(setAuthors(response.data.authors))
            return Promise.resolve(response.data.authors)
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

export const setAuthors = (authors) => {
    return {
        type: SET_AUTHORS,
        payload: authors,
    };
}

export const getHashtags = () => (dispatch) => {
    http
        .get('/getHashtags')
        .then((response) => {
            //console.log(response)
            dispatch(setHashtags(response.data.hashtags))
            return Promise.resolve(response.data.hashtags)
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

export const setHashtags = (hashtags) => {
    return {
        type: SET_HASHTAGS,
        payload: hashtags,
    };
}

export const setArticles = (articles) => {
    return {
        type: SET_ARTICLES,
        payload: articles
    }
}

export const getLatestComments = () => (dispatch) => {
    http
        .get('/latestComments')
        .then((response) => {
            //console.log(response.data.comments);
            dispatch(setLatestComments(response.data.comments));
            Promise.resolve(response.data.comments);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

export const setLatestComments = (latestComments) => {
    return {
        type: SET_LATEST_COMMENTS,
        payload: latestComments,
    };
}

export const setArticleLoading = (value: boolean) => {
    return {
        type: USER_LOADING,
        payload: value
    }
}
