/* Salutation */
export const mapSalutation = (salutation: number) => {
    switch (salutation) {
        case 1: return "Herr";
        case 2: return "Frau";
        default: return "";
    }
}
export interface Salutation {
    id: number,
    name: string
}
export const saluations: Salutation[] = [
    { id: 1, name: "Herr" },
    { id: 2, name: "Frau" }
]


/* Title */
export const mapTitle = (title: number) => {
    switch (title) {
        case 4: return "Dr.";
        case 5: return "Dr. Dr.";
        case 6: return "Prof.";
        case 7:
        case 8: return "Prof. Dr.";
        default: return "";
    }
}
export interface Title {
    id: number,
    name: string
}
export const titles: Title[] = [
    { id: 4, name: "Dr." },
    { id: 5, name: "Dr. Dr." },
    { id: 6, name: "Prof." },
    { id: 7, name: "Prof. Dr." },
    { id: 8, name: "Prof. Dr." },
]



/* Profession */
export const mapProfession = (profession: number) => {
    switch (profession) {
        case 18: return "Präsident";
        case 19: return "Vorsitzender";
        case 20: return "2. Vorsitzender";
        case 21: return "Geschäftsführer";
        case 22: return "Mitarbeiter";
        case 24: return "Inhaber";
        case 25: return "Prokurist";
        case 26: return "Vorstandsvorsitzender";
        case 27: return "Vorstandsmitglied";
        case 57: return "Vizepräsident";
        case 58: return "Geschäftsführerin";
        case 59: return "Vorsitzende";
        case 67: return "Geschäftsführender Gesellschafter";
        case 72: return "Redakteur";
        case 73: return "Anzeigenverkauf";
        case 74: return "Chefredakteur";
        case 75: return "Betriebsleiter";
        case 76: return "Holzeinkauf";
        case 150: return "Abteilungsleiter";
        case 151: return "Referatsleiter";
        case 152: return "PR- & Öffentlichkeitsarbeit";
        case 153: return "Marketing";
        case 154: return "Kommunikation";
        case 155: return "Sekretariat";
        case 156: return "Assistenz";
        case 205: return "Personalleitung";
        default: return "";
    }
}
export interface Profession {
    id: number,
    name: string
}
export const professions: Profession[] = [
    { id: 18, name: "Präsident" },
    { id: 19, name: "Vorsitzender" },
    { id: 20, name: "2. Vorsitzender" },
    { id: 21, name: "Geschäftsführer" },
    { id: 22, name: "Mitarbeiter" },
    { id: 24, name: "Inhaber" },
    { id: 25, name: "Prokurist" },
    { id: 26, name: "Vorstandsvorsitzender" },
    { id: 27, name: "Vorstandsmitglied" },
    { id: 57, name: "Vizepräsident" },
    { id: 58, name: "Geschäftsführerin" },
    { id: 59, name: "Vorsitzende" },
    { id: 67, name: "Geschäftsführender Gesellschafter" },
    { id: 72, name: "Redakteur" },
    { id: 73, name: "Anzeigenverkauf" },
    { id: 74, name: "Chefredakteur" },
    { id: 75, name: "Betriebsleiter" },
    { id: 76, name: "Holzeinkauf" },
    { id: 150, name: "Abteilungsleiter" },
    { id: 151, name: "Referatsleiter" },
    { id: 152, name: "PR- & Öffentlichkeitsarbeit" },
    { id: 153, name: "Marketing" },
    { id: 154, name: "Kommunikation" },
    { id: 155, name: "Sekretariat" },
    { id: 156, name: "Assistenz" },
    { id: 205, name: "Personalleitung" },
]

