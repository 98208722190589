import React, { useEffect, useState } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonIcon, IonFooter, IonGrid, IonItem, IonInput, IonLabel, IonCol, IonRow } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { addStatisticCategory, updateStatisticCategory } from '../../../redux/statisticCategories/statisticCategories.actions';
import { useAppDispatch } from '../../../redux/store';
import { ErrorToast } from '../../statistics/StatisticsInterfaces';
import { StatisticCategory } from '../StatisticCategoriesInterfaces';
import { useDispatch } from 'react-redux';

type EditStatisticCategoryModalProps = {
    showModal: boolean,
    setShowModal: (value: boolean) => void,
    setMessageToast: (toast: ErrorToast) => void,
    category: StatisticCategory,
}

export const EditStatisticCategoryModal: React.FC<EditStatisticCategoryModalProps> = ({ showModal, setShowModal, setMessageToast, category }: EditStatisticCategoryModalProps) => {
    const initialLabel = '';
    const [label, setLabel] = useState<string>(initialLabel);
    const dispatch = useDispatch();

    useEffect(() => {
        if(category){
            setLabel(category.label)
        }
    }, [category])

    const onSave = () => {
        dispatch(updateStatisticCategory(category.id, label))
            //@ts-ignore
            .then((res) => {
                setMessageToast({ open: true, message: `Kategorie "${res.label}" geändert.`, color: 'success' })
            })
            .catch((err) => {
                console.log(err)
                setMessageToast({ open: true, message: `Fehler beim Ändern der Daten.`, color: 'danger' })
            })
            .finally(() => {
                setShowModal(false);
            })
    }

    return (
        <IonModal isOpen={showModal} className='addStatisticCategoryModal'>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle>Kategorie bearbeiten</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModal(false)}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size='12'>
                            <IonItem className='ion-no-padding'>
                                <IonLabel position='stacked' className='ion-text-wrap'>Titel</IonLabel>
                                <IonInput value={label} onIonChange={(e) => setLabel(e.detail.value!)}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton fill='clear' onClick={() => setShowModal(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color='primary' onClick={onSave}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>

    );
};