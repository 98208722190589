import React, { useEffect, useRef, useState } from "react";
import { IonCol, IonContent, IonGrid, IonLabel, IonPage, IonRow, IonIcon, IonFab, IonFabButton, IonInfiniteScroll, IonSearchbar, IonSegment, IonSegmentButton, IonCard, IonCardContent, IonButton, IonToolbar, IonPopover, IonDatetime } from "@ionic/react";
import { useParams } from "react-router-dom";
import { calendar, chevronBackOutline, chevronForwardOutline, chevronUpOutline, filterOutline } from "ionicons/icons";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import "./Newsfeed.css";
import { Header } from "../../components";
import useWindowDimensions from "../../utils/screen-width";
import http from "../../utils/http-common";
import { Article, AutocompleteAuthor, AutocompleteHashtag, ParamsObject } from "./NewsfeedInterfaces";
import { FilterDialog, Merkliste, NewsfeedGridLayout } from "./newsfeedComponents";
import { format } from "date-fns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { getAuthors, getHashtags } from "../../redux/articles/article.actions";
import { ToggleButton, ToggleButtonGroup, CircularProgress, Drawer, InputAdornment } from "@mui/material";
import { de } from "date-fns/locale";


const Newsfeed: React.FC = () => {

    const { hashtagId, hashtagLabel } = useParams<{ hashtagId: string, hashtagLabel: string }>();
    const { height, width } = useWindowDimensions();
    const [articles, setArticles] = useState<Article[]>([]);
    const amount = 30; // Anzahl der Artikel auf einer Seite
    const [page, setPage] = useState<number>(1);
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [filter, setFilter] = useState<string>("date"); // date or custom  
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [releaseDate, setReleaseDate] = useState<null | string | undefined>();
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [searchMethod, setSearchMethod] = useState<string>("and");
    const [displayMode, setDisplayMode] = useState<number>(2);

    const [author, setAuthor] = useState<AutocompleteAuthor | null>(null);
    const [authorOpen, setAuthorOpen] = useState(false);
    const authors = useSelector((state: RootState) => state.article.authors, shallowEqual)
    const authorLoading = authorOpen && authors.length === 0;

    const [hashtag, setHashtag] = useState<AutocompleteHashtag | null>(null);
    const hashtags = useSelector((state: RootState) => state.article.hashtags, shallowEqual)
    const [hashtagOpen, setHashtagOpen] = useState(false);
    const hashtagLoading = hashtagOpen && hashtags.length === 0;

    const [filterDialog, setFilterDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        getArticles(true)
    }, [filter, triggerSearch])

    useEffect(() => {
        if (hashtagId && hashtagLabel) {
            setHashtag({
                id: Number(hashtagId),
                hashtag: hashtagLabel,
                active: 1,
                visible: 1,
            })
        }
    }, [])

    useEffect(() => {
        let active = true;
        if (!authorLoading) {
            return undefined;
        }

        dispatch(getAuthors());

        return () => {
            active = false;
        };
    }, [authorLoading, dispatch])


    useEffect(() => {
        let active = true;
        if (!hashtagLoading) {
            return undefined;
        }

        dispatch(getHashtags());

        return () => {
            active = false;
        };
    }, [hashtagLoading, dispatch])

    const getArticles = async (reset?: boolean) => {
        if (reset) {
            setArticles([]);
        }
        const data = reset ? [] : articles;
        const url = buildURL();

        await http
            .post(url, buildParamsObject())
            .then((response) => {
                if (url.startsWith('/getPreferredArticles')) {
                    if (response.data.articles.length > 0) {
                        //console.log(response)
                        setArticles([...data, ...response.data.articles])
                        setDisableInfiniteScroll(response.data.articles.length < amount)
                        setPage((p) => p + 1)
                    } else {
                        setArticles([]);
                        setDisableInfiniteScroll(true);
                    }
                } else {
                    if (response.data.result.data.length > 0) {
                        //console.log(response)
                        setArticles([...data, ...response.data.result.data])
                        setDisableInfiniteScroll(response.data.result.data.length < amount || response.data.result.total === amount)
                        setPage((p) => p + 1)
                    } else {
                        setArticles([]);
                        setDisableInfiniteScroll(true);
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const buildURL = () => {
        let url: string;
        if (filter === "date") {
            url = `/articles?amount=${amount}&page=${page}`;
        } else if (filter === "custom") {
            url = `/getPreferredArticles?amount=${amount}&page=${page}`;
        }
        return url
    }

    const buildParamsObject = () => {
        let paramsObject: ParamsObject = {};
        if (search !== "") {
            paramsObject.search = search;
        }
        if (releaseDate !== undefined) {
            paramsObject.date = format(new Date(releaseDate), "yyyy-MM")
        }
        if (author !== null) {
            paramsObject.author = author.id;
        }
        if (hashtag !== null) {
            paramsObject.hashtag = hashtag.id;
        }
        if (searchMethod !== null) {
            paramsObject.searchMethod = searchMethod;
        }
        if (initialLoad) {
            if (hashtagId) {
                paramsObject.hashtag = Number(hashtagId);
            }
            setInitialLoad(false)
        }
        let tempFetchedArticles = [];
        articles.forEach(article => tempFetchedArticles.push(article.id));
        paramsObject.fetched_articles = tempFetchedArticles;
        return paramsObject
    }

    async function searchNext($event: CustomEvent<void>) {
        await getArticles();
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    const scrollToTop = () => {
        contentRef.current && contentRef.current.scrollToTop(1000);
    };

    const searchOnEnter = async () => {
        setPage(1);
        setTriggerSearch(!triggerSearch);
    }

    const resetSearch = () => {
        setSearch("");
    }

    const updateFilter = (e) => {
        setPage(1)
        setFilter(e.detail.value)
    }

    const handleAndOrSearch = (event: React.MouseEvent<HTMLElement>,
        newSearchMethod: string,) => {
        if (newSearchMethod !== null) {
            setSearchMethod(newSearchMethod)
        }
    }

    const updateSearchValue = (value) => {
        if (value === "Enter") {
            searchOnEnter();
        }
    }

    const filterFields = () => {
        return (
            <IonRow style={{ padding: "0 2px 0 8px" }}>

                <IonCol size="12" style={width > 576 ? { marginTop: 15.4 } : { marginTop: 0 }}>
                    <Autocomplete
                        value={hashtag}
                        disablePortal
                        options={hashtags}
                        getOptionLabel={(option: AutocompleteHashtag) => option.hashtag}
                        open={hashtagOpen}
                        onOpen={() => {
                            setHashtagOpen(true)
                        }}
                        loading={hashtagLoading}
                        onClose={() => {
                            setHashtagOpen(false)
                        }}
                        loadingText="Lädt..."
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <TextField {...params}
                                    label="Hashtag"
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {hashtagLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }} />
                            </div>
                        )}
                        onChange={(event: any, newValue: AutocompleteHashtag | null) => {
                            setHashtag(newValue);
                        }}
                        noOptionsText="Keine Einträge gefunden"
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.hashtag}
                                </li>
                            )
                        }}
                    />

                </IonCol>
                <IonCol size="12" style={width > 576 ? { marginTop: 15.4 } : { marginTop: 0 }}>
                    <Autocomplete
                        value={author}
                        disablePortal
                        loadingText="Lädt..."
                        options={authors}
                        open={authorOpen}
                        onOpen={() => {
                            setAuthorOpen(true)
                        }}
                        loading={authorLoading}
                        onClose={() => {
                            setAuthorOpen(false)
                        }}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <TextField {...params}
                                    label="Autor"
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {authorLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                        onChange={(event: any, newValue: AutocompleteAuthor | null) => {
                            setAuthor(newValue);
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.vorname} {option.name}
                                </li>
                            )
                        }}
                        getOptionLabel={(option: AutocompleteAuthor) => option.vorname + " " + option.name}
                        noOptionsText="Keine Einträge gefunden"
                    />
                </IonCol>
                <IonCol size="12" style={width > 576 ? { marginTop: 15.4 } : { marginTop: 0 }}>

                    <TextField
                        id="releaseDate"
                        value={releaseDate !== undefined ? format(new Date(releaseDate), "MMM yyyy", { locale: de }) : ""}
                        variant="standard"
                        label="Release Monat"
                        style={{ width: "100%" }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IonIcon icon={calendar} style={{ marginRight: 4 }} />
                                </InputAdornment>
                        }}
                    />
                    <IonPopover trigger="releaseDate" size="cover" arrow={false} id="birthdayPopover">
                        <IonDatetime
                            presentation="month-year"
                            onIonChange={e => setReleaseDate(e.detail.value! as string)}
                            locale="de-DE"
                            value={releaseDate}
                            max={new Date().toISOString()}
                            min={new Date("2009-05-01").toISOString()}
                            showDefaultButtons={true}
                            showClearButton={true}
                            doneText="OK"
                            cancelText="Abbrechen"
                            clearText="Zurücksetzen"
                        />
                    </IonPopover>
                </IonCol>
            </IonRow>
        )
    }

    const toggleBtns = () => {
        return (
            <ToggleButtonGroup
                color="primary"
                exclusive
                value={searchMethod}
                className="and-or-search"
                onChange={handleAndOrSearch}
            >
                <ToggleButton value="and">Und</ToggleButton>
                <ToggleButton value="or">Oder</ToggleButton>
            </ToggleButtonGroup>
        )
    }

    return (
        <IonPage>
            {width <= 1800 ?
                <Header name="Newsfeed" >
                    <IonButton onClick={() => setIsDrawerOpen(true)}>
                        <IonIcon slot="icon-only" icon={chevronBackOutline} />
                    </IonButton>
                </Header> :
                <Header name="Newsfeed" />
            }
            <IonToolbar>
                <IonSegment style={{ padding: "0 5px" }} onIonChange={e => updateFilter(e)} value={filter}>
                    <IonSegmentButton value="custom">
                        <IonLabel>Empfehlung</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="date">
                        <IonLabel>Neueste</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </IonToolbar>

            <IonContent ref={contentRef} scrollEvents={true} >

                <div className="content">
                    <IonGrid className="higherNewsGrid">
                        <IonRow>
                            <IonCol size={width > 1800 ? "8" : "12"} className="ion-no-padding">
                                <div style={width < 1800 ? { maxWidth: 1000 + 'px', margin: "auto" } : {}}>

                                    <IonRow>
                                        <IonCard className="newsFilterCard" style={{ overflow: "visible" }}>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol style={{ display: "inline-flex", alignItems: "center" }}>
                                                        <IonSearchbar
                                                            value={search}
                                                            onIonChange={e => setSearch(e.detail.value!)}
                                                            enterkeyhint="search"
                                                            placeholder="Suche"
                                                            onIonClear={resetSearch}
                                                            className="articleSearch"
                                                            onKeyPress={e => updateSearchValue(e.key)}
                                                            mode="md"
                                                            debounce={0}
                                                        />
                                                        <div>
                                                            <IonButton fill="clear" size="default" onClick={(e) => { e.preventDefault(); setFilterDialogOpen(true) }}>
                                                                <IonIcon slot="icon-only" icon={filterOutline}></IonIcon>
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonRow>

                                    {width > 720 && <IonRow style={{ marginTop: 20 }}>
                                        <IonCol style={{ paddingBottom: 0, paddingRight: 0}}>
                                            <div className="ion-float-right" style={{marginRight: "-2px" }}>
                                                <button className="newsFilterBtn" onClick={() => setDisplayMode(1)}>
                                                    <IonIcon
                                                        className={displayMode === 1 ? "displayBtnActive" : "displayBtnNotActive"}
                                                        style={{ width: 30, height: 30 }}
                                                        icon="/assets/images/filterIcons/newsfeed_filter_1.svg"
                                                        slot="icon-only"
                                                    />
                                                </button>
                                                <button className="newsFilterBtn" onClick={() => setDisplayMode(2)}>
                                                    <IonIcon
                                                        className={displayMode === 2 ? "displayBtnActive" : "displayBtnNotActive"}
                                                        style={{ width: 30, height: 30 }}
                                                        icon="/assets/images/filterIcons/newsfeed_filter_2.svg"
                                                        slot="icon-only" />
                                                </button>
                                                <button className="newsFilterBtn" onClick={() => setDisplayMode(3)}>
                                                    <IonIcon
                                                        className={displayMode === 3 ? "displayBtnActive" : "displayBtnNotActive"}
                                                        style={{ width: 30, height: 30 }}
                                                        icon="/assets/images/filterIcons/newsfeed_filter_3.svg"
                                                        slot="icon-only" />
                                                </button>
                                                <button className="newsFilterBtn" onClick={() => setDisplayMode(4)}>
                                                    <IonIcon
                                                        className={displayMode === 4 ? "displayBtnActive" : "displayBtnNotActive"}
                                                        style={{ width: 30, height: 30 }}
                                                        icon="/assets/images/filterIcons/newsfeed_filter_4.svg"
                                                        slot="icon-only" />
                                                </button>
                                            </div>
                                        </IonCol>
                                    </IonRow>}

                                    {/* News */}
                                    <NewsfeedGridLayout articles={articles} displayMode={displayMode} width={width} />

                                    <div>
                                        <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                                            <div style={{ textAlign: "center", padding: "15px 0", opacity: 0.5 }}>Artikel werden geladen...</div>
                                        </IonInfiniteScroll>
                                    </div>
                                </div>
                            </IonCol>



                            {/* Merkliste */}
                            {width > 1800 &&
                                <IonCol style={{ padding: "0 0 0 50px" }} size={width > 1800 && "4"}>
                                    <Merkliste />
                                </IonCol>}
                        </IonRow>
                    </IonGrid>
                </div>

                {/* Back To Top Button (FAB) */}
                <IonFab onClick={() => scrollToTop()} vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon icon={chevronUpOutline} />
                    </IonFabButton>
                </IonFab>

                {/* Drawer */}
                {width <= 1800 &&
                    <Drawer
                        anchor="right"
                        open={isDrawerOpen}
                        onClose={() => setIsDrawerOpen(false)}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <div className="drawerHeader">
                            <IonButton fill="clear" size="large" onClick={() => setIsDrawerOpen(false)}><IonIcon icon={chevronForwardOutline} /></IonButton>
                        </div>
                        <Merkliste setIsDrawerOpen={setIsDrawerOpen} />
                    </Drawer>
                }

                {/* Filter Modal */}
                <FilterDialog open={filterDialog} setOpen={setFilterDialogOpen} filterFields={filterFields} search={searchOnEnter} toggleBtns={toggleBtns} />
            </IonContent>
        </IonPage>
    )
}

export default Newsfeed;