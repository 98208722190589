import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { format, isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { CustomLoading, Header } from "../../components";
import http from "../../utils/http-common";
import { Activity } from "./ActivityInterfaces";

import "./Activity.css";
import { ActivityDate, ActivityState } from "./activityComponents";

interface ActivityPageProps extends RouteComponentProps<{
    id?: string
}> { }


const ActivityPage: React.FC<ActivityPageProps> = ({ match }: ActivityPageProps) => {
    const id = match.params.id;
    const [activity, setActivity] = useState<Activity | null>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        const getActivity = async () => {
            setLoading(true);
            await http.get(`/activity?id=${id}`)
                .then((res) => {
                    setError(undefined);
                    setActivity(res.data.activity)
                })
                .catch((err) => {
                    if (err.response.data.msg) {
                        setError(err.response.data.msg)
                    } else {
                        setError("Unbekannter Fehler")
                    }



                })
                .finally(() => setLoading(false))

        }

        getActivity();

        return () => {
            setActivity(undefined);
            setError(undefined);
        }
    }, [id])

    return (
        <IonPage>
            <Header back={false} name="Aktivität" />
            <IonContent>
                <div className="content" style={{ maxWidth: 1200 + 'px', margin: "auto" }}>
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                            {(activity !== undefined && activity !== null) &&
                                <IonCol size="12">
                                    <IonCard>
                                        <IonCardHeader className="activityCardHeader">
                                            <IonCardTitle>{activity.betreff}</IonCardTitle>
                                            <IonCardSubtitle>{format(new Date(activity.von * 1000), "dd.MM.yyyy")}
                                                {(activity.bis !== 0 && !isSameDay(new Date(activity.von * 1000), new Date(activity.bis * 1000))) && " - " + format(new Date(activity.bis * 1000), "dd.MM.yyyy")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <div className="activityCardUpperContent">
                                                <IonGrid className="ion-no-padding">
                                                    <IonRow className="ion-justify-content-between">
                                                        <IonCol size="12" sizeMd="6">
                                                            <h3>Details</h3>
                                                            {/*  <IonCardTitle>Details</IonCardTitle> */}
                                                            <div className="activityDetails">
                                                                {/* <strong>Datum:</strong> {format(new Date(activity.von * 1000), "dd.MM.yyyy")}<br /> */}
                                                                <strong>Datum:</strong> <ActivityDate von={activity.von} bis={activity.bis} whole_end_day={activity.whole_end_day} whole_start_day={activity.whole_start_day} /><br />
                                                                <strong>Ort:</strong> {activity.ort !== "" ? activity.ort : "-"}<br />
                                                                <strong>Art:</strong> {activity.art_label}<br />
                                                                <strong>Betreff-Typ:</strong> {activity.betrefftyp_label}<br />
                                                                <strong>Typ:</strong> {activity.typ_label}<br />
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size="12" sizeMd="6" className="activityStateCol">
                                                            <h3>Status</h3>
                                                            <ActivityState state={activity.status_label} />
                                                        </IonCol>

                                                    </IonRow>
                                                    {activity.ergebnis !== "" &&
                                                        <IonRow className="activityDescription">
                                                            <div dangerouslySetInnerHTML={{ __html: activity.ergebnis }}></div>
                                                        </IonRow>
                                                    }
                                                </IonGrid>
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            }
                            {activity === null && <Redirect to="/404" />}
                            {error !== undefined && <div>Fehler beim Laden der Aktivität: {error}</div>}
                        </IonRow>
                    </IonGrid>

                </div>

                <CustomLoading isOpen={loading} />
            </IonContent>

        </IonPage>
    )
}

export default ActivityPage;