/**
 *
 * Diagramm für PNG Download
 * Hinweis: Download erwartet fixe Pixel-Werte, daher sind alle Größen exakt berechnet
 *
 */

import React, { useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Line } from 'react-chartjs-2';

// Benötigte Interfaces für Typescript
import { ComponentToPrintProps } from "../StatisticsInterfaces";
import { ErrorBoundary } from "../../../components";

const StatisticsPrint = React.forwardRef<HTMLDivElement, ComponentToPrintProps>((props, ref) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (props.options) {
            setOptions({
                responsive: true,
                plugins: {
                    legend: {
                        align: "start",
                        labels: {
                            font: {
                                weight: 500,
                                family: "Roboto",
                                size: 24,
                            },
                            usePointStyle: true,
                            pointStyle: "circle",
                            padding: 20,
                        },
                        position: "bottom",
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            font: {
                                size: 20,
                            },
                        },
                    },
                    y: {
                        min: props.options.scales.y.min || undefined,
                        max: props.options.scales.y.max || undefined,
                        display: true,
                        ticks: {
                            font: {
                                size: 20,
                            },
                        },
                    },
                },
                animation: {
                    duration: 0,
                },
            });
        }
    }, [props.options])

    return (
        <div ref={ref} >
            {/* <div style={{ backgroundColor: "#E9ECEF", padding: 86, width: 1748, height: 1308, display: "flex", flex: "0 0 100%", justifyContent: "center", alignItems: "center" }}> */}
            <div style={{ backgroundColor: "#ffffff", /* padding: 86, */  width: 1576, height: 1167, display: "flex", flex: "0 0 100%", justifyContent: "center", alignItems: "center" }}>
                <div style={{ backgroundColor: "white" }}>
                    <div style={{ backgroundColor: "#ef7f00", height: 24, width: 240, marginLeft: 90 }}></div>
                    <IonGrid style={{ paddingLeft: 90, paddingTop: 60, paddingRight: 90, paddingBottom: 60, width: 1576 }}>
                        <IonRow>
                            <IonCol size="9" style={{ width: "1396px" }}>
                                <div style={{ fontFamily: "Roboto Slab", color: "#1d405a", fontSize: 38, fontWeight: "bold" }}>{props.customLabels.title}</div>
                            </IonCol>
                            <IonCol size="3">
                                <img style={{ float: "right", marginTop: "8px" }} src="/assets/images/desh-Logo-blau.png" height="90px" alt="DeSH Logo" />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol style={{ marginBottom: 50 }}>
                                <p style={{ fontSize: 24, fontFamily: "Roboto Slab" }}>{props.customLabels.subtitle}</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <ErrorBoundary>
                                {/* @ts-ignore */}
                                <Line data={props.data} options={options} redraw />
                            </ErrorBoundary>
                        </IonRow>
                        <IonRow style={{ marginTop: 20 }}>
                            <IonCol size="6" style={{ fontSize: 20, color: "rgb(115, 115, 115)" }}>{props.customLabels.footer}</IonCol>
                            <IonCol size="6" style={{ fontSize: 20, textAlign: "right", color: "rgb(115, 115, 115)" }}>
                                © Deutsche Säge- und Holzindustrie Bundesverband e. V., {new Date().getFullYear()}<br />
                                Quelle: {props.copyright}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </div>
        </div>
    );
});

export default StatisticsPrint;