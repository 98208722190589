/**
 *
 * Card für das Öffnen der erweiterten Einstellungen
 *
 */

import React from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from "@ionic/react";

type AdvancedStatisticSettingsProps = {
    openGraphSettingsModal: () => void,
}

const AdvancedStatisticSettings: React.FC<AdvancedStatisticSettingsProps> = ({ openGraphSettingsModal }: AdvancedStatisticSettingsProps) => {

    return (
        <IonCard className="normalCard" style={{ overflow: "visible" }}>
            <IonCardHeader>
                <IonCardTitle className="cardTitle">Erweiterte Einstellungen</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <p>
                    Sie haben die Möglichkeit, Ihre Statistik zu personalisieren. Hierzu gehören das Vergeben von eigenen Beschriftungen, die Skalierung für die y-Achse und die Linienstärke der einzelnen Datenreihen im Graphen.
                </p>
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol>
                            <IonButton fill="clear" className="ion-float-right" onClick={() => openGraphSettingsModal()}>
                                Erweiterte Einstellungen öffnen
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default AdvancedStatisticSettings;