import React, { useEffect, useState } from 'react';
import { ItemReorderEventDetail } from '@ionic/core/components';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonReorder, IonReorderGroup, IonToast, useIonAlert } from '@ionic/react';
import { addOutline, closeOutline, pencilOutline, trashOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../components';
import { RootState } from '../../redux/reducers';
import { deleteStatisticCategory, setStatisticCategories, updateOrder } from '../../redux/statisticCategories/statisticCategories.actions';
import http from '../../utils/http-common';
import { ErrorToast } from '../statistics/StatisticsInterfaces';
import './statisticCategories.css'
import { AddStatisticCategoryModal } from './statisticCategoriesComponents/AddStatisticCategoryModal';
import { EditStatisticCategoryModal } from './statisticCategoriesComponents/EditStatisticCategoryModal';
import { StatisticCategory } from './StatisticCategoriesInterfaces';


const StatisticCategories: React.FC = () => {
    const [reorderDisabled, setReorderDisabled] = useState<boolean>(true)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
    const [messageToast, setMessageToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" });
    const [present] = useIonAlert();
    const [selectedCategory, setSelectedCategory] = useState<StatisticCategory>();

    const categories: StatisticCategory[] = useSelector((state: RootState) => state.statisticCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setStatisticCategories());
    }, [])

    const toggelReorder = () => {
        if (!reorderDisabled) {
            sendNewOrderToBackend()
        }
        setReorderDisabled(!reorderDisabled);
    }

    const sendNewOrderToBackend = async () => {
        await http.put('/reorderCustomStatisticCategories', { categories: categories })
            .then((res) => setMessageToast({ open: true, message: 'Neue Reihenfolge gespeichert.', color: 'success' }))
            .catch((err) => {
                console.log(err);
                setMessageToast({ open: true, message: 'Fehler beim Speichern der neuen Reihenfolge.', color: 'danger' })
            })
    }

    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        let newOrder = event.detail.complete(categories);

        newOrder.forEach((category, index) => {
            category.sort = index;
        });
        dispatch(updateOrder(newOrder));
    }

    const onDelete = (e, id) => {
        dispatch(deleteStatisticCategory(id))
            //@ts-ignore
            .then(() => setMessageToast({ open: true, message: 'Kategorie gelöscht', color: 'success' }))
            .catch(() => setMessageToast({ open: true, message: 'Fehler beim Löschen der Kategorie', color: 'danger' }))
    }

    return (
        <IonPage>
            <Header name="Statistik Kategorien" back={true} />
            <IonContent>
                <div className="content">
                    <IonCard className='statisticCategoryCard'>
                        <IonCardHeader >
                            <IonCardTitle>Kategorien</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <div style={{ textAlign: 'right' }}>
                                <IonButton fill='clear' onClick={toggelReorder} >{reorderDisabled ? "ANORDNEN" : "FERTIG"}</IonButton>
                                <IonButton disabled={!reorderDisabled} onClick={() => setIsAddDialogOpen(true)}>
                                    <IonIcon icon={addOutline} />
                                </IonButton>
                            </div>
                            <IonReorderGroup disabled={reorderDisabled} onIonItemReorder={doReorder}>
                                {categories.map((category) =>
                                    <IonItem key={category.id} className="reorderItem">
                                        <IonLabel>{category.label}</IonLabel>
                                        {reorderDisabled && <div>
                                            <IonButton fill='clear' onClick={() => {
                                                present({
                                                    cssClass: 'my-css',
                                                    header: 'Kategorie Löschen',
                                                    message: `Möchten Sie die Kategorie <i>${category.label}</i> wirklich löschen?`,
                                                    buttons: [
                                                        'Abbrechen',
                                                        { text: 'Löschen', handler: (d) => onDelete(d, category.id) },
                                                    ],
                                                })
                                            }}>
                                                <IonIcon icon={trashOutline} />
                                            </IonButton>
                                            <IonButton fill='clear' onClick={() => {
                                                setSelectedCategory(category);
                                                setIsEditDialogOpen(true);
                                            }}>
                                                <IonIcon icon={pencilOutline} />
                                            </IonButton>
                                        </div>}
                                        <IonReorder slot="end" />
                                    </IonItem>
                                )}
                            </IonReorderGroup>
                        </IonCardContent>
                    </IonCard>
                </div>

                {/* Modals */}
                <AddStatisticCategoryModal showModal={isAddDialogOpen} setShowModal={setIsAddDialogOpen} setMessageToast={setMessageToast} />
                <EditStatisticCategoryModal showModal={isEditDialogOpen} setShowModal={setIsEditDialogOpen} setMessageToast={setMessageToast} category={selectedCategory} />

                <IonToast
                    isOpen={messageToast.open}
                    message={messageToast.message}
                    color={messageToast.color}
                    duration={3000}
                    onDidDismiss={() => setMessageToast(prevState => ({
                        ...prevState,
                        open: false
                    }))}
                    buttons={[
                        {
                            icon: closeOutline,
                            role: 'cancel',
                        }
                    ]}
                />
            </IonContent>

        </IonPage>
    );
};

export default StatisticCategories;
