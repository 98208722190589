/**
 *
 * Dialog Fenster für sogenannte "Third Row", also die Datenreihen, die wahrscheinlich niemals jemand benutzen will
 * Achtung: Infinite Scroll wird von Ionic React noch nicht supported, daher funktioniert es nicht einwandfrei
 *
 */

import React, { useState, useEffect } from "react";
import { IonModal, IonButton, IonHeader, IonTitle, IonToolbar, IonCheckbox, IonContent, IonButtons, IonIcon, IonInfiniteScroll, IonItem, IonLabel, IonSearchbar, IonGrid, IonRow, IonCol, IonFooter } from "@ionic/react";
import { closeOutline } from 'ionicons/icons';

import "../Statistics.css";
import http from "../../../utils/http-common";

type StatisticsModalProps = {
    id: number,
    name: string,
    isOpen: boolean,
    callback: () => void,
    checked: object,
    setChecked: (checked: object) => void,
}

const StatisticsModal: React.FC<StatisticsModalProps> = ({ id, name, isOpen, callback, checked, setChecked }: StatisticsModalProps) => {
    const [items, setItems] = useState([]);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const amount = 25;
    const [page, setPage] = useState<number>(1);
    const [searchStr, setSearchStr] = useState<string>("");
    const [finalSearch, setFinalSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (id && name) {
            fetchData(true);
        }
    }, [finalSearch, id, name]);

    const fetchData = async (reset?: boolean) => {
        if (id && name) {
            setLoading(true);
            const data = reset ? [] : items;
            const url: string = finalSearch !== "" ? `/fetchStatisticEvenMoreFilter?statistics_attribute_id=${id}&name=${name}&page=${page}&search=${finalSearch}` :
                `/fetchStatisticEvenMoreFilter?statistics_attribute_id=${id}&name=${name}&page=${page}`

            await http
                .get(url)
                .then(function (response) {
                    if (response.data.data.length > 0) {
                        //console.log(response)
                        setItems([...data, ...response.data.data]);
                        setDisableInfiniteScroll(response.data.data.length < amount || response.data.total === amount)
                        setPage((p) => p + 1)
                    } else {
                        setItems([])
                        setDisableInfiniteScroll(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => setLoading(false))
        }

    }

    const searchOnEnter = (e) => {
        if (e.key === 'Enter') {
            setPage(1);
            setFinalSearch(searchStr);
        }
    }

    const handleCheckbox = (code, content) => {

        if (!loading) {
            if (checked[name] && checked[name].some(e => e.id === code)) {
                //remove
                setChecked((checked) => {
                    checked[name] = checked[name].filter(item => { return item.id !== code })
                    return ({ ...checked })
                })
            } else {
                //add
                setChecked((checked) => {
                    checked[name].push({
                        id: code,
                        name: content
                    })
                    return ({ ...checked })
                })
            }
        }
    }

    async function searchNext($event: CustomEvent<void>) {
        await fetchData();
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    const isChecked = (code) => {
        if (name && checked[name]) {
            if (checked[name].some(e => e.id === code)) {
                return true
            }
            return false
        } return false;
    }

    return (
        <IonModal isOpen={isOpen} className="customModal" /* onWillPresent={() => initialFetchData()} */>
            <IonHeader >
                <IonToolbar color="primary">
                    <IonTitle>Alle Datenreihen</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={callback}>
                            <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar value={searchStr} onIonChange={e => setSearchStr(e.detail.value!)} enterkeyhint="search" placeholder="Suche" onKeyDown={e => searchOnEnter(e)} debounce={0}></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonGrid className="noPaddingGrid">
                    {items.length > 0 ?
                        <IonRow>
                            {items.map((item, index) => (
                                <IonCol size="12" key={index} className="checkboxCol">
                                    <IonItem lines="none" className="checkboxItemModal ion-no-padding">
                                        <IonLabel className="ion-text-wrap" style={{ paddingTop: 4 }}>{item.content}</IonLabel>
                                        <IonCheckbox
                                            color="secondary"
                                            slot="start"
                                            value={item.code}
                                            checked={isChecked(item.code)}
                                            /* checked={checked[name] && checked[name].some(e => e.id === item.code)} */
                                            className="checkbox"
                                            onIonChange={() => handleCheckbox(item.code, item.content)}
                                        />
                                        <IonLabel className="ion-text-wrap" style={{ paddingTop: 4 }} slot="end">{item.code}</IonLabel>
                                    </IonItem>
                                </IonCol>

                            ))}
                        </IonRow> : "Keine Datenreihen gefunden."}
                </IonGrid>
                <div>
                    <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                        <div style={{ textAlign: "center", padding: "15px 0", opacity: 0.5 }}>Weitere Datenreihen werden geladen...</div>
                    </IonInfiniteScroll>
                </div>
            </IonContent>


            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={callback}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default StatisticsModal;
