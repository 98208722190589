import React from "react";
import axios from "axios";
import { logoutUser } from "../redux/user/user.actions";
import store from "../redux/store";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { get } from "./storage";

const http = axios.create({
  /* Wenn vor Ort: */
  //baseURL: "https://marge6-php74.grips.local/holzwirtschaft/web/desh-mitglieder-v2/public/api/",
  // baseURL: "https://marge6-php80.grips.local/holzwirtschaft/web/desh-mitglieder-v2/backend/public/api/",
  /* Wenn via VPN: */
  //baseURL: "https://192.168.0.234:32769/holzwirtschaft/web/desh-mitglieder-v2/public/api/",
  //baseURL: "https://192.168.0.234:32775/holzwirtschaft/web/desh-mitglieder-v2/backend/public/api/",
  // baseURL: "http://localhost:8000/api",
  //baseURL: "https://marge6-php82-desh-mb.grips.local/api",
  /* Online: */
  //baseURL:"https://dev.saegeindustrie.de/api",
  baseURL: "https://schnittholz.saegeindustrie.de/api",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
});


http.interceptors.request.use(
  async function (config) {
    const token = await get("token");
    //console.log("in http", token)
    if (token) {
      const parsedToken = jwt_decode(token)
      const expirationTime = parsedToken.exp * 1000 - 60000;
      config.headers["Authorization"] = "Bearer " + token;
      if (Date.now() >= expirationTime) {
        store.dispatch(logoutUser());
        return <Redirect to="/login" />;
      }
    } else {
      delete config.headers["Authorization"];
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


export default http;
