/**
 *
 * Card Container für das Diagramm
 *
 */

import React, { useEffect, useState } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonRow } from "@ionic/react";

import "../Statistics.css";
import { Statistic } from "../StatisticsInterfaces";

type DiagrammCardProps = {
    data: Statistic,
    subtitle: string,
}

const DiagrammCard: React.FC<DiagrammCardProps> = ({ data, subtitle, children }) => {
    const [show, setShow] = useState<boolean>(false);
    useEffect(() => {
        setShow(true);
    }, [])

    return (
        <IonCard className="normalCard">
            {/* nur maximal 8 Datenreihen sind noch übersichtlich in Diagramm darstellbar */}
            {data.datasets.length <= 8 ?
                <>
                    <IonCardHeader>
                        <IonRow>
                            <IonCol>
                                <IonCardTitle className="cardTitle">Diagramm</IonCardTitle>
                                <IonCardSubtitle style={{ marginTop: 8 }}>{subtitle}</IonCardSubtitle>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>
                    {show &&
                        children
                    }
                </>
                :
                <>
                    <IonCardHeader>
                        <IonCardTitle className="cardTitle">Diagramm</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Um eine übersichtliche grafische Darstellung zu gewährleisten, dürfen maximal 8 Datenreihen angezeigt werden. Mit den von Ihnen gewählten Filtereinstellungen handelt es sich derzeit um {data.datasets.length} anzuzeigende Datenreihen. Wenn Sie die Daten als Liniendiagramm sehen und downloaden möchten, reduzieren Sie bitte diese Anzahl.
                    </IonCardContent>
                </>}
        </IonCard>
    )
}

export default DiagrammCard;