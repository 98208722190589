/**
 *
 * Tabelle mit Ergebnissen
 * kommt von React Material Design (MUI) Datatables, nicht von Ionic
 * https://github.com/gregnb/mui-datatables
 *
 */

import React from "react";
import MUIDataTable from "mui-datatables";
import { IonCard, IonCardContent } from "@ionic/react";
import TableLegend from "./TableLegend";

type StatisticsTableProps = {
    tableData: object,
    tableColumns: object
}

const StatisticsTable: React.FC<StatisticsTableProps> = ({ tableData, tableColumns }: StatisticsTableProps) => {
    return (
        <IonCard className="normalCard">
            <IonCardContent>
                <MUIDataTable
                    title={"Tabelle"}
                    data={tableData}
                    columns={tableColumns}
                    options={{
                        pagination: false,
                        selectableRows: 'none',
                        filter: false,
                        print: false,
                        viewColumns: false,
                        textLabels: {
                            body: {
                                noMatch: "Entschuldigung, keine passenden Datensätze gefunden",
                                toolTip: "Sortieren",
                                columnHeaderTooltip: column => `Sortieren nach ${column.label}`
                            },
                            toolbar: {
                                search: "Suche",
                                downloadCsv: "Download CSV",
                            },
                        },
                        downloadOptions: {
                            filename: 'datenreihe.csv',
                            separator: ';',
                            filterOptions: {
                                useDisplayedColumnsOnly: true,
                                useDisplayedRowsOnly: true,
                            }
                        },
                        onDownload: (buildHead: (columns) => string, buildBody: (data) => string, columns, data) => {
                            data.map(function (a, v) {
                                a.data.map(function (x, y) {
                                    data[v].data[y] = ' ' + x;
                                });
                            })

                            return "\uFEFF" + buildHead(columns) + buildBody(data);
                        }
                    }}
                />
                <TableLegend />
            </IonCardContent>

        </IonCard>
    )
}

export default StatisticsTable;
