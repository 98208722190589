/**
 *
 * Auflistung der von Benutzern erstellten Statistiken
 * Kategoriserung nach "Meine Statistiken", "Mitarbeiter Statistiken" und "Mitglieder Statistiken" (kommen alle in dem selben Request)
 *
 */

import React, { useEffect, useState } from "react"
import {
    IonToast,
} from "@ionic/react";

import http from "../../../utils/http-common";
import { CustomStatisticInterface } from "../StatisticsInterfaces";
import { User } from "../../profile/ProfileInterfaces";
import { StatisticCategory } from "../../statisticCategories/StatisticCategoriesInterfaces";
import AccordionCard from "./AccordionCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { setCustomStatistics } from "../../../redux/statistics/statistics.actions";
import { isObjectEmpty } from "../../../utils/general-helpers";
import { CustomLoading } from "../../../components";

type Tab2Props = {
    destatisState: boolean,
    identifier: string,
    user: User,
    temporaryLoggedIn: boolean
}

const Tab2: React.FC<Tab2Props> = ({ destatisState, identifier, user, temporaryLoggedIn }: Tab2Props) => {
    const [statistics, setStatistics] = useState<CustomStatisticInterface[]>([]);
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const categories: StatisticCategory[] = useSelector((state: RootState) => state.statistics.customStatisticCategories);
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => state.statistics.customStatistics);

    /* useEffect(() => {
        dispatch(setCustomStatistics());
    }, [stateUpdate]); */

    useEffect(() => {
        let mounted = true;
        /* console.log("ineffect2") */
        if (mounted) {
            dispatch(setCustomStatistics());
        }

        return function cleanup() {
            mounted = false
        }
    }, [])

    useEffect(() => {
        if (!isObjectEmpty(response)) {
            if (identifier === "user") {
                //@ts-ignore
                setStatistics(response.user);
            } else if (identifier === "employee") {
                //@ts-ignore
                setStatistics(response.employee);
            }
            else if (identifier === "member") {
                //@ts-ignore
                setStatistics(response.member);
            }
        }

    }, [identifier, response])

    // löschen einer Statistik
    const deleteCustomStatistic = async (id: number) => {
        setLoading(true)
        await http
            .get(`/deleteCustomStatistic?id=${id}`)
            .then(function () {
                dispatch(setCustomStatistics());
                setIsToastOpen(true)
                //setStateUpdate(!stateUpdate);
            })
            .catch(function (error) {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            {categories.map((category: StatisticCategory) => {
                const filtered = statistics.filter(el =>
                    el.custom_category_id === category.id
                );
                return (
                    <AccordionCard key={category.id} label={category.label} deleteHandler={deleteCustomStatistic} statistics={filtered} destatisState={destatisState} isDestatisStatistic={false} user={user} temporaryLoggedIn={temporaryLoggedIn} />
                )
            })}

            <AccordionCard label={"Ohne Kategorie"} statistics={statistics.filter(el =>
                el.custom_category_id === null
            )} destatisState={destatisState} isDestatisStatistic={false} deleteHandler={deleteCustomStatistic} user={user} temporaryLoggedIn={temporaryLoggedIn} />


            {/* Message Toast wenn Statistik gelöscht */}
            <IonToast
                isOpen={isToastOpen}
                onDidDismiss={() => setIsToastOpen(false)}
                message="Statistik wurde gelöscht"
                duration={2000}
                color="success"
            />

            {/* Loading Indicator */}
            <CustomLoading isOpen={loading} />
        </div>
    )
}

export default Tab2;