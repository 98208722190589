/**
 *
 * Statistik Detailseite mit ALLEN Funktionalitäten
 * WENN DU IN DIESER DATEI ETWAS ÄNDERN MUSST, DANN GOOD LUCK
 * 
 * Hier passieren folgende Dinge:
 * - das komplette State Management für alle hier verwendeten Komponenten, da die Values für den Request hier benötigt werden
 * - es wird komplett frontendseitig berechnet, welche Ergebnis-Datenreihen und -Aggregate gibt
 *      - die sind abhängig von Anzahl der Datenreihen, Columns, 4&9Steller usw. und multiplizieren sich oft in den seltsamsten Konstellationen
 *  - handelt es sich um eine CustomStatistik eines Users, werden die Werte mit den gespeicherten Werten vorbefüllt
 *  - Einstellung von Datenreihen, columns, Aggregatem, eigenen Beschriftungen, Skalierung, Farbe und Lineistärkre der Datenreihen usw.
 *  - Fetchen der Statistik mit Anzeige Diagramm (plus png Download) und Tabelle (csv Download)
 *  - Speichern der Statistik mit allen vorgenommenen Einstellungen
 *  - usw.
 *
 */

import React, { useEffect, useRef, useState } from "react";
import {
    IonButton, IonCardContent, IonCol, IonContent, IonIcon,
    IonPage, IonRow, IonToast
} from "@ionic/react";
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { downloadOutline, saveOutline } from 'ionicons/icons';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


//Eigene Importe
import { CustomLoading, ErrorBoundary, Header } from "../../../components";
import "../../Style.css";
import "../Statistics.css";
import http from "../../../utils/http-common";
import { formatDateForAPI, isObjectEmpty } from "../../../utils/general-helpers";
import { Scale, Statistic, StatisticInterface, AttributeInterface, Aggregate, ErrorToast, CustomStatisticLabels, Datarow } from "../StatisticsInterfaces"; // Benötigte Interfaces für Typescript
import { initialAggregates, options, colorPalette } from "../StatisticsData"; // Benötigte vordefinierte Datenreihen
import {
    StatisticsPrint, StatisticsModal, SaveStatisticModal, Aggregates, StatisticsTable, AdvancedGraphSettings, DatarowCard, GraphSettingsModal,
    ApplyFilterCard, SpaceOfTimeCard, DiagrammCard, AggregateSelectDialog
} from "../statisticsComponents"; //Other Statistic Components
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { ForbiddenPage } from "../..";
import { timePeriodCalculation } from "../statisticsComponents/TimePeriodCalculation";
import useWindowDimensions from "../../../utils/screen-width";
import { setCustomStatistics } from "../../../redux/statistics/statistics.actions";

type StatisticDetailProps = {
    editMode: boolean,
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


/* Haupt-Komponente */
const StatisticsDetail: React.FC<StatisticDetailProps> = ({ editMode }: StatisticDetailProps) => {
    /* const [expanded, setExpanded] = useState<boolean[]>([]); */
    const [columnExpanded, setColumnExpanded] = useState<boolean[]>([]); //Refactoring??
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statisticData, setStatisticData] = useState<StatisticInterface | null>(null)
    const { id } = useParams<{ id: string }>();
    const [showResults, setShowResults] = useState<boolean>(false);
    const filterCount = 8;
    const chartRef = useRef<ChartJS>(null);
    const componentRef = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<Statistic>({
        labels: [],
        datasets: []
    });
    const [startYear, setStartYear] = useState<Date>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [endYear, setEndYear] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [copyright, setCopyright] = useState<string>("");
    const [checkedObj, setCheckedObj] = useState({});
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalId, setModalId] = useState<number>();
    const [modalName, setModalName] = useState<string>("");
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [graphOptions, setGraphOptions] = useState(options);
    const [yScale, setYScale] = useState<Scale>({ y_scale_min: undefined, y_scale_max: undefined })
    const [compare, setCompare] = useState<boolean>(false);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [checkedColumns, setCheckedColumns] = useState({});
    const [isAggregatable, setIsAggregatable] = useState<boolean>(false);
    const [errorToast, setErrorToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" })
    const [labels, setLabels] = useState<CustomStatisticLabels>({ title: "", subtitle: "", footer: "", });
    const [aggregates, setAggregates] = useState<Aggregate[]>(initialAggregates);
    const [result, setResult] = useState<Datarow[]>([]);
    const [aggregateResult, setAggregateResult] = useState<Aggregate[]>([]);
    const [isGraphSettingsModalOpen, setIsGraphSettingsModalOpen] = useState<boolean>(false);
    const [metaData, setMetaData] = useState({ source: "", base_identifier: id })
    const [datarowCount, setDataRowCount] = useState<undefined | number>(undefined);
    const [aggregatableIndex, setAggregatableIndex] = useState<number>();
    const [notAggregatable, setNotAggregatable] = useState<number>();
    const [saveFor, setSaveFor] = useState({ employee: false, member: false, user: true });
    const user = useSelector((state: RootState) => state.user.user)
    const [statisticUserId, setStatisticUserId] = useState<string | number>("");
    const [changeIndicator, setChangeIndicator] = useState<boolean>(false);
    const [showAggregateSlectDialog, setShowAggregateSelectDialog] = useState<boolean>(false);
    const [currentAggregateIndex, setCurrentAggregateIndex] = useState<number>(0);
    const [readableAggregateNames, setReadableAggregateNames] = useState<string[][]>([[], [], []]);
    const [selectedRadioButton, setSelectedRadioButton] = useState<string>("month12");
    const [compareLineType, setCompareLineType] = useState<string | null>("transparent");
    const { height, width } = useWindowDimensions();
    const [category, setCategory] = useState<number>();
    const dispatch = useDispatch();

    //console.log(checkedObj);

    // in editMode Statistik und CustomStatistik nötig
    // sonst nur normale übergeordnete Statistik Daten
    useEffect(() => {
        if (id) {
            if (editMode === true) {
                fetchCustomStatistic();
            } else {
                getStatisticCategory(id);
            }
        }
    }, [editMode, id])

    useEffect(() => {
        setGraphOptions((graphOptions) => {
            if (width > 576) {
                graphOptions.aspectRatio = 2;
            } else {
                graphOptions.aspectRatio = 1;
            }
            return ({ ...graphOptions })
        })
    }, [width])

    const fetchCustomStatistic = async () => {
        await http
            .get(`/fetchCustomStatistic?statistic_id=${id}`)
            .then(function (response) {
                setMetaData({ source: response.data.statistic.source, base_identifier: response.data.statistic.base_identifier })
                setLabels({ title: response.data.statistic.title, subtitle: response.data.statistic.subtitle || "", footer: response.data.statistic.footer || "", });
                setYScale({ y_scale_min: response.data.statistic.y_scale_min || undefined, y_scale_max: response.data.statistic.y_scale_max || undefined })
                updateYScaleGraphOptions(response.data.statistic.y_scale_min || undefined, response.data.statistic.y_scale_max || undefined);
                getAndMapDefaultStatisticValues(response.data.statistic)
                setSaveFor({ employee: response.data.statistic.employee, member: response.data.statistic.member, user: true })
                setStatisticUserId(response.data.statistic.user_id);
                setCategory(response.data.statistic.custom_category_id);

                if (response.data.statistic.period_type !== null) {
                    setSelectedRadioButton(response.data.statistic.period_type);
                    let { start, end } = timePeriodCalculation(response.data.statistic.period_type, response.data.statistic.period_start, response.data.statistic.period_end);
                    setStartYear(start);
                    setEndYear(end);
                }
                if (response.data.statistic.compare === 1) setCompare(true);
                if (response.data.statistic.compare_line_type) setCompareLineType(response.data.statistic.compare_line_type);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    // im edit Mode müssen gespeicherte Values mit der Datenstruktur in der Komponente irgendwie gemapped werden
    const getAndMapDefaultStatisticValues = async (customStatistic) => {
        setIsLoading(true);
        await http
            .get(`/fetchStatisticType?identifier=${customStatistic.base_identifier}`)
            .then(function (response) {
                //console.log("statisticData", response.data.statistic)
                //console.log("customStatistic", customStatistic)

                setResult(customStatistic.statisticFilters)

                //general statistic information
                setStatisticData(response.data.statistic)

                // Checked Datarows and aggregates and columns
                let initialCheckedObjs = {};
                let notAggregatableIndex;
                let aggrIndex;
                let notAggregatableValueName;
                let initialCheckedColumns = {};
                response.data.statistic.attributes.forEach((attribute, index) => {
                    initialCheckedObjs[attribute.value] = []
                    let dataRowIdentifier;

                    // weil bei 4- & 9- Stellern Name mit * abgeschnitten wird
                    if (attribute.value.includes("*")) {
                        dataRowIdentifier = attribute.value.substr(0, attribute.value.indexOf("*"));
                    } else {
                        dataRowIdentifier = attribute.value
                    }

                    if (attribute.is_aggregatable === true) {
                        setAggregatableIndex(parseInt(index) + 1)
                        aggrIndex = parseInt(index) + 1
                    } else {
                        setNotAggregatable((parseInt(index) + 1))
                        notAggregatableIndex = parseInt(index) + 1;
                        notAggregatableValueName = attribute.value;
                    }

                    //columns
                    if (customStatistic.statisticFilters.length > 0) {
                        if (attribute.has_column_filter) {
                            initialCheckedColumns[attribute.value] = []
                            customStatistic.statisticFilters.forEach((filter) => {
                                initialCheckedObjs[attribute.value].findIndex(x => x.id === filter.filter_1) === -1 &&
                                    attribute.values.forEach((value) => {
                                        if (value.code === filter.filter_1) {
                                            initialCheckedObjs[attribute.value].push({
                                                id: value.code,
                                                name: value.content
                                            })
                                        }
                                    });

                                initialCheckedColumns[attribute.value].findIndex(x => x.id === filter.column_1) === -1 &&
                                    attribute.columns.forEach((column) => {
                                        if (column.code === filter.column_1) {
                                            initialCheckedColumns[attribute.value].push({
                                                id: column.code,
                                                name: column.content
                                            })
                                        }
                                    });
                            })

                        }
                        //no columns
                        else {
                            customStatistic.statisticFilters.forEach((filter) => {
                                let tempFilterArray = [filter.filter_1, filter.filter_2, filter.filter_3];
                                tempFilterArray.forEach((filterId) => {
                                    if (filterId && filterId.startsWith(dataRowIdentifier)) {
                                        initialCheckedObjs[attribute.value].findIndex(x => x.id === filterId) === -1 &&
                                            attribute.values.forEach((value) => {
                                                if (value.code === filterId) {
                                                    initialCheckedObjs[attribute.value].push({
                                                        id: value.code,
                                                        name: value.content
                                                    })
                                                }
                                            });
                                    }
                                })

                            })
                        }
                    }
                    // keine Datenreihen sondern nur Aggregate
                    else if (customStatistic.statisticFilters.length === 0 && customStatistic.statisticAggregates !== null && customStatistic.statisticAggregates.length > 0) {

                        //columns
                        if (attribute.has_column_filter) {
                            initialCheckedColumns[attribute.value] = []
                            customStatistic.statisticAggregates.forEach((aggregate) => {
                                initialCheckedColumns[attribute.value].findIndex(x => x.id === aggregate.column_1) === -1 &&
                                    attribute.columns.forEach((column) => {
                                        if (column.code === aggregate.column_1) {
                                            initialCheckedColumns[attribute.value].push({
                                                id: column.code,
                                                name: column.content
                                            })
                                        }
                                    });
                            })
                        } else {

                            if (attribute.is_aggregatable === false) {
                                customStatistic.statisticAggregates.forEach((aggregate) => {
                                    initialCheckedObjs[attribute.value].findIndex(x => x.id === aggregate["filter_" + (parseInt(index) + 1)]) === -1 &&
                                        attribute.values.forEach((value) => {
                                            if (value.code === aggregate["filter_" + + (parseInt(index) + 1)]) {
                                                initialCheckedObjs[attribute.value].push({
                                                    id: value.code,
                                                    name: value.content
                                                })
                                            }
                                        });

                                })
                            }


                        }
                    }

                })
                setCheckedObj(initialCheckedObjs)
                setCheckedColumns(initialCheckedColumns)

                // amount of datarows
                setDataRowCount(response.data.statistic.attributes.length);

                //aggregates
                checkForAggregation(response.data.statistic)
                if (customStatistic.statisticAggregates) {
                    setAggregateResult(customStatistic.statisticAggregates)
                    let tempAggregates = [...aggregates];
                    if (response.data.statistic.attributes.length > 1) {

                        let index = 0;
                        customStatistic.statisticAggregates.forEach((customAggregate) => {
                            if (customAggregate["filter_" + notAggregatableIndex] === initialCheckedObjs[notAggregatableValueName][0].id) {
                                tempAggregates[index] = {
                                    aggregate_method: customAggregate.aggregate_method,
                                    aggregate_name: customAggregate.aggregate_name,
                                    checked: true,
                                    initial_name: "Aggregatfunktion #" + (index + 1),
                                    ["filter_" + aggrIndex]: customAggregate["filter_" + aggrIndex]
                                }
                                index++
                            }

                        })


                        setAggregates(tempAggregates)
                    } else {
                        if (isObjectEmpty(initialCheckedColumns)) {
                            tempAggregates = [...aggregates];
                            customStatistic.statisticAggregates.forEach((customAggregate, index) => {
                                tempAggregates[index] = {
                                    aggregate_method: customAggregate.aggregate_method,
                                    aggregate_name: customAggregate.aggregate_name,
                                    checked: true,
                                    initial_name: "Aggregatfunktion #" + (index + 1),
                                    ["filter_" + aggrIndex]: customAggregate["filter_" + aggrIndex]
                                }
                            })
                        } else {
                            let index = 0;
                            // erste checked Column
                            let firstCheckedColumn = initialCheckedColumns[Object.keys(initialCheckedColumns)[0]][0].id
                            customStatistic.statisticAggregates.forEach((customAggregate) => {
                                if (customAggregate.column_1 === firstCheckedColumn) {
                                    tempAggregates[index] = {
                                        aggregate_method: customAggregate.aggregate_method,
                                        aggregate_name: "",
                                        checked: true,
                                        initial_name: "Aggregatfunktion #" + (index + 1),
                                        ["filter_" + aggrIndex]: customAggregate["filter_" + aggrIndex]
                                    }
                                    index++
                                }

                            })
                        }
                        setAggregates(tempAggregates)
                    }
                }



            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                setIsLoading(false)
            })
    }

    // API request für übergeordnete Statistik Daten (also welche Datenreihen etc gibt es überhapt)
    const getStatisticCategory = async (id: string) => {
        setIsLoading(true);
        await http
            .get(`/fetchStatisticType?identifier=${id}`)
            .then(function (response) {
                //console.log(response.data.statistic)
                checkForAggregation(response.data.statistic)

                let initialCheckedObjs = {};
                response.data.statistic.attributes.forEach((attribute) => {
                    initialCheckedObjs[attribute.value] = []
                    attribute.values.forEach((value) => {
                        if (value.preselect === true) {
                            initialCheckedObjs[attribute.value].push({
                                id: value.code,
                                name: value.content
                            })
                        }
                    })
                })
                setCheckedObj(initialCheckedObjs)

                let initialCheckedColumns = {};
                response.data.statistic.attributes.forEach((attribute, index) => {
                    if (attribute.has_column_filter === true) {
                        initialCheckedColumns[attribute.value] = []
                        attribute.columns.forEach((column) => {
                            if (column.preselect === true) {
                                initialCheckedColumns[attribute.value].push({
                                    id: column.code,
                                    name: column.content
                                })
                            }
                        })
                    }
                    if (attribute.is_aggregatable === true) {
                        setAggregatableIndex(parseInt(index) + 1)
                    } else {
                        setNotAggregatable(parseInt(index) + 1)
                    }

                })
                setCheckedColumns(initialCheckedColumns)
                setDataRowCount(response.data.statistic.attributes.length);
                setLabels({ title: response.data.statistic.label, subtitle: response.data.statistic.subtitle, footer: "" })
                setMetaData({ ...metaData, ['source']: response.data.statistic.source })
                setStatisticData(response.data.statistic);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
            })
    }


    // Berechnung der resultierenden Datenreihen
    useEffect(() => {
        let tempResult = [];
        if (datarowCount) {
            switch (datarowCount) {
                case 1:
                    for (const [checkedKey, checkedValues] of Object.entries(checkedObj)) {
                        //@ts-ignore
                        checkedValues.forEach((checkedDatarow) => {
                            if (checkedColumns.hasOwnProperty(checkedKey)) {
                                checkedColumns[checkedKey].forEach((column) => {
                                    tempResult.push({
                                        filter_1: checkedDatarow.id,
                                        filter_2: null,
                                        filter_3: null,
                                        column_1: column.id,
                                        filter_name: checkedDatarow.name + ", " + column.name,
                                    })
                                })
                            } else {
                                tempResult.push({
                                    filter_1: checkedDatarow.id,
                                    filter_2: null,
                                    filter_3: null,
                                    filter_name: checkedDatarow.name,
                                    column_1: null
                                })
                            }
                        })
                    }
                    break;
                case 2:
                    /* First DataRow */
                    checkedObj[Object.keys(checkedObj)[0]].forEach(firstDRChecked => {
                        /* Second Datarow */
                        checkedObj[(Object.keys(checkedObj)[1])].forEach(secondDRChecked => {
                            /* column for first row and not for second? */
                            if (checkedColumns.hasOwnProperty(Object.keys(checkedObj)[0]) && !checkedColumns.hasOwnProperty(Object.keys(checkedObj)[1])) {
                                checkedColumns[Object.keys(checkedObj)[0]].forEach((column) => {
                                    tempResult.push({
                                        filter_1: firstDRChecked.id,
                                        filter_2: secondDRChecked.id,
                                        filter_3: null,
                                        column_1: column.id,
                                        filter_name: firstDRChecked.name + ", " + secondDRChecked.name + ", " + column.name
                                    })
                                })
                            }
                            /* column for second row and not for first? */
                            else if (!checkedColumns.hasOwnProperty(Object.keys(checkedObj)[0]) && checkedColumns.hasOwnProperty(Object.keys(checkedObj)[1])) {
                                checkedColumns[Object.keys(checkedObj)[1]].forEach((column) => {
                                    tempResult.push({
                                        filter_1: firstDRChecked.id,
                                        filter_2: secondDRChecked.id,
                                        filter_3: null,
                                        column_2: column.id,
                                        filter_name: firstDRChecked.name + ", " + secondDRChecked.name + ", " + column.name
                                    })
                                })
                            } else if (checkedColumns.hasOwnProperty(Object.keys(checkedObj)[0]) && checkedColumns.hasOwnProperty(Object.keys(checkedObj)[1])) {
                                console.log("nur maximal eine column erlaubt")
                            } else {
                                tempResult.push({
                                    filter_1: firstDRChecked.id,
                                    filter_2: secondDRChecked.id,
                                    filter_3: null,
                                    column_1: null,
                                    filter_name: firstDRChecked.name + ", " + secondDRChecked.name
                                })
                            }
                        });


                    });
                    break;
                case 3:
                    /* TODO */
                    break;
                default:
                //console.log("Ungültige Anzahl an Datenreihen");
            }

            tempResult.forEach((entry, index) => {
                tempResult[index] = { ...entry }
                tempResult[index].line_width = 3;
                tempResult[index].line_color = getColor();
            })
            tempResult = compareResults(tempResult, result);
            setResult(tempResult)
        }

    }, [checkedObj, checkedColumns, datarowCount])

    let colorIndex: number = 0;
    const getColor = () => {
        const maxColorIndex: number = colorPalette.length;
        if (colorIndex < maxColorIndex - 1) {
            colorIndex++
        } else {
            colorIndex = 0
        }
        return colorPalette[colorIndex]
    }

    const compareResults = (tempResult: any, finalResult) => {
        let secondTemp = [];
        tempResult.forEach(row => {
            if (finalResult.some((entry => (entry.filter_1 === row.filter_1 && entry.filter_2 === row.filter_2 && entry.filter_3 === row.filter_3 && entry.column_1 === row.column_1)))) {
                // Change
                secondTemp.push(finalResult.filter(entry => (entry.filter_1 === row.filter_1 && entry.filter_2 === row.filter_2 && entry.filter_3 === row.filter_3 && entry.column_1 === row.column_1))[0])
            } else {
                // add
                secondTemp.push(row)
            }
            // removed automatically due to new temp array 
        });

        return secondTemp;
    }

    const compareAggregateResults = (tempResult: any, finalResult) => {
        let secondTemp = [];

        tempResult.forEach(row => {
            if (finalResult.some((entry => entry.initial_name === row.initial_name))) {
                //change
                let tempRow = finalResult.filter(entry => entry.initial_name === row.initial_name)[0]
                tempRow.aggregate_method = row.aggregate_method;
                tempRow["filter_" + aggregatableIndex] = row["filter_" + aggregatableIndex];
                secondTemp.push(tempRow)
            } else {
                // add
                secondTemp.push(row)
            }
        });
        return secondTemp;
    }

    // muss hier stehen, weil warum auch immer zwei Arrays im einfachen Vergleich nicht identisch sind. Obwohl sie es sind!
    //@ts-ignore
    Array.prototype.equals = function (array) {
        if (!array)
            return false;

        if (this.length != array.length)
            return false;

        for (var i = 0, l = this.length; i < l; i++) {
            if (this[i] instanceof Array && array[i] instanceof Array) {
                if (!this[i].equals(array[i]))
                    return false;
            }
            else if (this[i] != array[i]) {
                return false;
            }
        }
        return true;
    }

    const formatCheckedAggregates = () => {
        let checkedAggregates = []
        aggregates.forEach((aggregate) => {
            if (aggregate.checked) {
                let { checked, ...rest } = aggregate;
                rest.aggregate_name = rest.initial_name;
                checkedAggregates.push(rest);
            }
        });
        return checkedAggregates;
    }

    // Berechnung der resultierenden Aggregate
    useEffect(() => {
        let tempAggregates = [];

        if (isAggregatable) {
            let formattedAggregates = formatCheckedAggregates()
            if (formattedAggregates.length > 0) {
                if (statisticData) {
                    switch (datarowCount) {
                        case 1:
                            //@ts-ignore
                            if (statisticData.attributes[0].has_column_filter && checkedColumns[statisticData.attributes[0].value]) {
                                //@ts-ignore
                                checkedColumns[statisticData.attributes[0].value].forEach((checkedColumn) => {
                                    formattedAggregates.forEach((formattedAggregate) => {
                                        tempAggregates.push({
                                            initial_name: checkedColumn.name + ", " + formattedAggregate.initial_name,
                                            aggregate_name: checkedColumn.name + ", " + formattedAggregate.initial_name,
                                            aggregate_method: formattedAggregate.aggregate_method,
                                            ["filter_" + aggregatableIndex]: formattedAggregate["filter_" + aggregatableIndex],
                                            column_1: checkedColumn.id,
                                        })
                                    })
                                })
                            } else {
                                tempAggregates = formattedAggregates;
                            }
                            break;
                        case 2:
                            statisticData.attributes.forEach((attribute: AttributeInterface) => {
                                if (!attribute.is_aggregatable) {
                                    checkedObj[attribute.value].forEach((checked) => {
                                        formattedAggregates.forEach((formattedAggregate) => {
                                            tempAggregates.push({
                                                initial_name: checked.name + ", " + formattedAggregate.initial_name,
                                                aggregate_name: checked.name + ", " + formattedAggregate.initial_name,
                                                aggregate_method: formattedAggregate.aggregate_method,
                                                ["filter_" + aggregatableIndex]: formattedAggregate["filter_" + aggregatableIndex],
                                                ["filter_" + notAggregatable]: checked.id,
                                            })
                                        })
                                    })
                                }
                            })
                            break;
                        case 3: break;
                        default:
                    }
                }
            }
            tempAggregates.forEach((entry, index) => {
                tempAggregates[index] = { ...entry }
                tempAggregates[index].line_width = 3;
                tempAggregates[index].line_color = getColor();
            })
            tempAggregates = compareAggregateResults(tempAggregates, aggregateResult);
            setAggregateResult(tempAggregates)
        }

    }, [aggregates, checkedColumns, checkedObj])


    // check if result has any attribute with is_aggregatable === true
    const checkForAggregation = (res) => {
        res.attributes.forEach((attribute) => {
            if (attribute.is_aggregatable === true) {
                if (isAggregatable === false) {
                    setIsAggregatable(true);
                }
            }
        })
    }

    // Fetchen der Daten nach "Daten anzeigen"
    const fetchStatistic = async () => {
        setIsLoading(true)
        if (checkAggregates()) {

            if (aggregateResult.length < 1 && result.length < 1) {
                openErrorToast("Bitte wählen Sie mindestens eine Datenreihe (ggf. inklusive Tabllenspalte) oder ein Aggregat aus.", "danger")
                setIsLoading(false)
            } else {
                let requestParams = { ...metaData, ...labels, ...yScale }
                //@ts-ignore
                requestParams.filter = JSON.stringify(result)
                //@ts-ignore
                requestParams.aggregates = JSON.stringify(aggregateResult);
                fetchStatisticCall(requestParams)
            }

        } else {
            openErrorToast("Bitte füllen Sie die gewählte/n Aggregatfunktion/en vollständig aus.", "danger")
            setIsLoading(false)
        }

    }

    // Hier ist der eigentlich Request für die Statistik
    const fetchStatisticCall = async (requestParams) => {
        await http
            .get(`/fetchStatistic?startyear=${formatDateForAPI(startYear)}&endyear=${formatDateForAPI(endYear)}&compare=${compare}&compare_line_type=${compareLineType}`, {
                params: requestParams
            })
            .then(function (response) {
                //console.log(response)

                if (response.data.data.length > 0) {
                    response.data.labels.forEach(function (label: string, index: number) {
                        if (label.toString().includes('-')) {
                            response.data.labels[index] = label.split('-');
                        }

                    });
                    data.labels = response.data.labels;
                    data.datasets = response.data.data;

                    // don't know why - need to save in var before set...
                    let tmpTblColumns = response.data.tableData.labels;
                    let tmpTblData = response.data.tableData.data;

                    tmpTblColumns.forEach(function (col) {

                        if (col.options !== undefined && col.options.sticky !== undefined && col.options.sticky === true) {
                            col.options.setCellProps = () => ({
                                style: {
                                    position: "sticky",
                                    left: "0",
                                    background: "white",
                                    zIndex: 100
                                }
                            });

                            col.options.setCellHeaderProps = () => ({
                                style: {
                                    position: "sticky",
                                    left: 0,
                                    background: "white",
                                    zIndex: 101
                                }
                            })
                        }
                    })

                    setTableData(tmpTblData);
                    setTableColumns(tmpTblColumns);
                    setData(data);
                    setCopyright(response.data.copyright);
                    setShowResults(true);

                } else {
                    console.error("API liefert keine Daten zurück");
                    openErrorToast(statisticData ? `${statisticData.source} liefert keine Daten zurück.` : "API liefert keine Daten zurück.", "danger")
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                setChangeIndicator(false);
            });
    }

    // Error Handling für User Feedback mit Message Toast
    const openErrorToast = (message: string, color: string) => {
        setErrorToast({ open: true, message: message, color: color })
    }
    const closeErrorToast = () => {
        setErrorToast({ open: false, message: "", color: "" })
    }

    const checkAggregates = () => {
        let errorArray = [];
        aggregates.forEach((aggregate) => {
            if (aggregate.checked) {
                if ((aggregate["filter_" + aggregatableIndex] && aggregate["filter_" + aggregatableIndex].length === 0) || aggregate.aggregate_method === "") {
                    errorArray.push(aggregate)
                }
            }
        })

        if (errorArray.length === 0) {
            return true;
        } else { return false; }
    }

    const handleAggregateChange = (i: number, checked: any, type: string) => {
        if (!showAggregateSlectDialog) {
            //@ts-ignore
            setAggregates((aggregates) => {
                aggregates[i][type] = checked;
                return ([...aggregates])
            })

            handleUserFeedbackOnChange();
        }

    }

    // Download des Statistik Graphen als PNG
    function printDocument() {
        setIsLoading(true);
        const node = componentRef.current;

        domtoimage.toBlob(node, {
            width: 1576,
            height: 1167
        })
            .then(function (blob) {
                domtoimage.toBlob(node, {
                    width: 1576,
                    height: 1167
                }).then(blob2 => {
                    saveAs(blob2, 'Statistik.png');
                }).catch(function (error) {
                    console.error('Fehler beim Download', error);
                })

            }).catch(function (error) {
                console.error('Fehler beim Download', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // Statistik Modal (Third Row)
    const openModal = (id: number, name: string) => {
        setModalId(id);
        setModalName(name);
        setShowModal(true);
    }
    const closeModal = () => {
        //TODO User Feedback
        setShowModal(false);
        handleUserFeedbackOnChange();
    }

    // Speichern Modal
    const openSaveModal = () => {
        if (checkAggregates()) {
            setShowSaveModal(true)
        } else {
            openErrorToast("Bitte füllen Sie die gewählte/n Aggregatfunktion/en vollständig aus.", "danger")
        }
    }

    // Erweiterte Einstellungen Modal
    const openGraphSettingsModal = () => {
        setIsGraphSettingsModalOpen(true);
    }
    const closeGraphSettingsModal = () => {
        setIsGraphSettingsModalOpen(false);
    }

    const applyAdvancedSettings = (settings, customDatarows, yScaleState, customAggregates, compareType) => {
        setLabels(settings)
        setResult(customDatarows)
        setYScale(yScaleState)
        setAggregateResult(customAggregates)
        setIsGraphSettingsModalOpen(false);
        setCompareLineType(compareType);

        updateYScaleGraphOptions(yScaleState.y_scale_min, yScaleState.y_scale_max);
        handleUserFeedbackOnChange();
    }

    const handleUserFeedbackOnChange = () => {
        if (showResults && !changeIndicator) {
            setChangeIndicator(true)
            openErrorToast("Hinweis: Damit Ihre Änderungen angezeigt werden, drücken Sie bitte erneut den Button <strong>DIAGRAMM ANZEIGEN</strong>", "secondary")
        }
    }

    const updateYScaleGraphOptions = (min, max) => {
        setGraphOptions((graphOptions) => {
            if (min !== undefined) {
                graphOptions.scales.y.min = parseInt(min);
            } else {
                graphOptions.scales.y.min = null;
            }
            if (max !== undefined) {
                graphOptions.scales.y.max = parseInt(max);
            } else {
                graphOptions.scales.y.max = null;
            }
            return ({ ...graphOptions })
        })
    }



    const onAfterSave = (errorReport: string) => {
        setShowSaveModal(false);
        if (errorReport === "success") {
            if (editMode === true) {
                dispatch(setCustomStatistics());
            }
            openErrorToast("Statistik wurde gespeichert", "success")
        } else if (errorReport === "error") {
            openErrorToast("Fehler beim Speichern", "danger")
        }
    }

    // Role-Management
    const checkEmployeeCondition = () => {
        if (editMode === false) {
            if (user.member) {
                return true;
            } else { return false }
        } else {
            if (user.member) {
                return true;
            } else if (user.employee) {
                if (user.id === statisticUserId) {
                    return false
                } else { return true; }
            } else if (user.admin) {
                return false;
            }
        }
    }

    // Aggregate Select Dialog
    const handleAggregateSelectModalClose = () => {
        setShowAggregateSelectDialog(false)
    }
    const handleAggregateSelectModalOpen = (id: number, name: string, index: number) => {
        setModalId(id);
        setModalName(name);
        setCurrentAggregateIndex(index);
        setShowAggregateSelectDialog(true)
    }

    if (statisticData && checkEmployeeCondition()) {
        return <ForbiddenPage />
    } else {
        return (
            <IonPage>
                <Header back={true} name="Statistik" />
                <IonContent>
                    {statisticData &&
                        <>
                            <div className="content">
                                <h4 className="headline">{statisticData.label || ""}</h4>

                                <IonButton fill="clear" className="ion-float-right" onClick={() => openSaveModal()}>
                                    <IonIcon slot="icon-only" icon={saveOutline} />
                                </IonButton>

                                <div style={{ marginBottom: "40px" }}>{statisticData.identifier}</div>

                                <div>
                                    {/* Datenreihen */}
                                    {statisticData.attributes.map((attribute: AttributeInterface, index) => {
                                        return (
                                            <DatarowCard
                                                key={attribute.id}
                                                attribute={attribute}
                                                index={index}
                                                //expanded={expanded}
                                                //setExpanded={setExpanded}
                                                columnExpanded={columnExpanded}
                                                setColumnExpanded={setColumnExpanded}
                                                openShowAllModal={openModal}
                                                setChecked={setCheckedObj}
                                                checked={checkedObj}
                                                checkedColumns={checkedColumns}
                                                setCheckedColumns={setCheckedColumns}
                                                isModalOpen={showModal}
                                                handleUserFeedback={handleUserFeedbackOnChange}
                                            />
                                        )
                                    })}

                                    {/* Aggregate */}
                                    {isAggregatable && <Aggregates dataRows={statisticData && statisticData.attributes} aggregates={aggregates} setAggregates={handleAggregateChange} aggregatableIndex={aggregatableIndex} openAggregateModal={handleAggregateSelectModalOpen} readableAggregateNames={readableAggregateNames} />}


                                    {/* Zeitraum */}
                                    <SpaceOfTimeCard compare={compare} setCompare={setCompare} startYear={startYear} setStartYear={setStartYear} endYear={endYear} setEndYear={setEndYear} selectedRadioButton={selectedRadioButton} setSelectedRadioButton={setSelectedRadioButton} interval={statisticData.interval} />

                                    {/* Advanced Settings */}
                                    <AdvancedGraphSettings openGraphSettingsModal={openGraphSettingsModal} />

                                    {/* Filter anwenden */}
                                    <ApplyFilterCard filterCount={filterCount} applyFilter={fetchStatistic} resultSet={result && aggregateResult ? result.length + aggregateResult.length : 0} />

                                    {/* Diagramm */}
                                    {showResults &&
                                        <DiagrammCard data={data} subtitle={labels.subtitle}>
                                            <IonCardContent>
                                                <ErrorBoundary>
                                                    {/* @ts-ignore */}
                                                    <Line data={data} options={graphOptions} ref={chartRef} redraw />
                                                </ErrorBoundary>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                                    <IonButton style={{ margin: "20px 10px" }} onClick={printDocument}>
                                                        <IonIcon slot="start" icon={downloadOutline} /> Download
                                                    </IonButton>
                                                </div>
                                                <IonRow style={{ fontSize: 12 }}>
                                                    <IonCol size="6" >
                                                        {labels.footer}
                                                    </IonCol>
                                                    <IonCol size="6" style={{ textAlign: "right" }}>
                                                        © Deutsche Säge- und Holzindustrie Bundesverband e. V., {new Date().getFullYear()}<br />
                                                        Quelle: {copyright || ""}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </DiagrammCard>}

                                    {/* Tabelle */}
                                    {showResults &&
                                        <StatisticsTable tableData={tableData} tableColumns={tableColumns} />
                                    }
                                </div>
                            </div>

                            {/* Print Component */}
                            <div style={{ position: "absolute", left: "-9999px" }}>
                                <StatisticsPrint ref={componentRef} data={data} options={graphOptions} customLabels={labels} copyright={copyright || ""} />
                            </div>
                        </>
                    }




                    {/* Modals */}
                    <StatisticsModal id={modalId} name={modalName} isOpen={showModal} callback={closeModal} checked={checkedObj} setChecked={setCheckedObj} />
                    <SaveStatisticModal isOpen={showSaveModal} callback={onAfterSave} labels={labels} meta={metaData} datarows={result} aggregates={aggregateResult} yScale={yScale} editMode={editMode} id={id} saveFor={saveFor} startYear={startYear} endYear={endYear} compare={compare} period_type={selectedRadioButton} compareLineType={compareLineType} oldCategory={category} />
                    <GraphSettingsModal isOpen={isGraphSettingsModalOpen} closeModal={closeGraphSettingsModal} labels={labels} applySettings={applyAdvancedSettings} datarows={result} yScaleState={yScale} aggregates={aggregateResult} compareLineType={compareLineType} compare={compare} />
                    <AggregateSelectDialog id={modalId} name={modalName} isOpen={showAggregateSlectDialog} callback={handleAggregateSelectModalClose} aggregates={aggregates} setAggregates={setAggregates} aggregatableIndex={aggregatableIndex} currentAggregateIndex={currentAggregateIndex} setReadableAggregateNames={setReadableAggregateNames} />

                    {/* Message Toasts */}
                    <IonToast
                        isOpen={errorToast.open}
                        onDidDismiss={() => closeErrorToast()}
                        message={errorToast.message}
                        duration={3000}
                        color={errorToast.color}
                    />
                </IonContent>
                <CustomLoading isOpen={isLoading} />
            </IonPage >
        )
    }
}

export default StatisticsDetail;
