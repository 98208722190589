import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useHistory } from "react-router";
import { HorizontalNewsfeedCard, NewsfeedCard } from ".";

import "../Newsfeed.css";
import { Article } from "../NewsfeedInterfaces";

interface NewsfeedGridLayoutProps {
    articles: Article[],
    displayMode: number,
    width: number,
}

const NewsfeedGridLayout: React.FC<NewsfeedGridLayoutProps> = ({ articles, displayMode, width }: NewsfeedGridLayoutProps) => {
    const history = useHistory();

    const navigateToNewsfeed = (e, id) => {
        e.preventDefault();
        history.push(`/mitgliederbereich/newsfeed-artikel/${id}`)
    }

    const calculateColumns = (displayMode, i) => {
        switch (displayMode) {
            case 1: return {
                size: "12",
                sizeXs: "12",
                sizeSm: "12",
                sizeMd: "6",
                sizeLg: "6",
                sizeXl: "6",
            }
            case 2: return {
                size: "12",
                sizeXs: "12",
                sizeSm: "12",
                sizeMd: "6",
                sizeLg: "6",
                sizeXl: "4",
            }
            case 3:
                switch ((i + 1) % 10) {
                    case 1:
                    case 7: return {
                        size: "12",
                        sizeXs: "12",
                        sizeSm: "12",
                        sizeMd: "6",
                        sizeLg: "6",
                        sizeXl: "8",
                    }
                    default: return {
                        size: "12",
                        sizeXs: "12",
                        sizeSm: "12",
                        sizeMd: "6",
                        sizeLg: "6",
                        sizeXl: "4",
                    }
                }
            case 4: return {
                size: "12",
                sizeXs: "12",
                sizeSm: "12",
                sizeMd: "12",
                sizeLg: "12",
                sizeXl: "12",
            }

        }

    }


    return (
        <IonGrid style={{ padding: width < 576 ? 0 : 5, margin: width < 576 ? 0 : "0 -10px"}}>
            <IonRow /* style={{margin: "0 -5px 0 -5px"}} */>
                {articles.map((article, i) => (

                    <IonCol
                        {...calculateColumns(displayMode, i)}
                        key={article.id}
                        onClick={(e) => navigateToNewsfeed(e, article.id)}
                        style={{ padding: width < 576 ? 0 : 5 }}
                    >
                        {displayMode === 4 && width > 720 ? <HorizontalNewsfeedCard article={article} /> : <NewsfeedCard article={article} displayMode={displayMode} i={i} width={width}/>}
                    </IonCol>

                ))} 
            </IonRow>
        </IonGrid>
    )
}

export default NewsfeedGridLayout;