import { SET_CURRENT_TOKEN, SET_CURRENT_USER, USER_LOADING, SET_MESSAGE_TOKEN } from "./user.types";

const isEmpty = require("is-empty");

const initialState = {
    isAuthenticated: null,
    token: "",
    loading: false,
    temporaryLogin: 0,
    user: {},
    fcm_token: {
        token: "",
        timestamp: ""
    },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_TOKEN:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload.token),
                token: action.payload.token,
                temporaryLogin: action.payload.temporaryLogin
            };
        case USER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_MESSAGE_TOKEN: return {
            ...state,
            fcmMessageToken: action.payload
        }
        default:
            return state;
    }
}