import React from "react";
import { IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { EditFields, User } from "../ProfileInterfaces";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { professions, saluations, titles } from "./profile-helper";
import { format } from "date-fns";

type ProfileEditModalProps = {
    isOpen: boolean;
    closeModal: Function,
    user: User,
    onSave: (values: EditFields) => void,
}

type Inputs = {
    first_name: string,
    name: string,
    email: string,
    salutation: number,
    title: number,
    birthdate: string,
    phone: string,
    mobile: string,
    no_sms: number,
    fax: string,
    street: string,
    zip_code: string,
    city: string,
    country: string,
    organisation: string,
    function: null | number,
    function_other: string,
    expert_user_values: number[],
};


const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ isOpen, closeModal, user, onSave }: ProfileEditModalProps) => {
    /* const [errors, setErrors] = useState({}); */

    const { control, handleSubmit, formState: { errors } } = useForm<Inputs>({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const onSubmit: SubmitHandler<Inputs> = data => {
        data.no_sms = user.no_sms;
        onSave(data);
    };

    return (
        <IonModal isOpen={isOpen} className="profileEditModal" backdropDismiss={false}  >
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Daten bearbeiten</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={(e) => closeModal(false)}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form style={{ width: "100%" }}>
                    <IonItemDivider className="ion-no-padding" mode="md">Persönliche Angaben</IonItemDivider>
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Anrede</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonSelect
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                interface="alert"
                                                cancelText="Abbrechen"
                                            >
                                                {saluations.map((salutation) => <IonSelectOption value={salutation.id} key={salutation.id}>{salutation.name}</IonSelectOption>)}
                                            </IonSelect>
                                        )}
                                        control={control}
                                        defaultValue={user.salutation}
                                        name="salutation"
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Titel</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonSelect
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                interface="alert"
                                                cancelText="Abbrechen"
                                            >
                                                {titles.map((title) => <IonSelectOption value={title.id} key={title.id}>{title.name}</IonSelectOption>)}
                                            </IonSelect>
                                        )}
                                        control={control}
                                        defaultValue={user.title}
                                        name="title"
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Vorname*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.first_name}
                                        name="first_name"
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                        }}
                                    />
                                </IonItem>
                                {errors.first_name && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.first_name.message}</div>}
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Nachname*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.name}
                                        name="name"
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                        }}
                                    />
                                </IonItem>
                                {errors.name && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.name.message}</div>}
                            </IonCol>

                            <IonCol size="12">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">E-Mail*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="email"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.email}
                                        name="email"
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                            pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Bitte geben Sie ein gültige E-Mail-Adresse ein" }
                                        }}
                                    />
                                </IonItem>
                                {errors.email && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.email.message}</div>}
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding customSpaceOfTimeItem">
                                    <IonLabel position="stacked">Geburtsdatum</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <div>
                                                <IonInput id="date" value={format(new Date(value), "dd.MM.yyyy")}></IonInput>
                                                <IonPopover trigger="date" size="cover" arrow={false} id="birthdayPopover">
                                                    <IonDatetime
                                                        presentation="date"
                                                        onIonChange={(e) => {
                                                            //console.log(e.detail.value!.substring(0, 10))
                                                            //@ts-ignore
                                                            onChange(e.detail.value!.substring(0, 10))
                                                        }}
                                                        locale="de-DE"
                                                        value={value}
                                                    />
                                                </IonPopover>
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={user.birthdate !== "0000-00-00" ? user.birthdate : null}
                                        name="birthdate"
                                    />

                                </IonItem>
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Telefon</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="tel"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.phone}
                                        name="phone"
                                    />
                                </IonItem>
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Mobil</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="tel"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.mobile}
                                        name="mobile"
                                    />
                                </IonItem>
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Fax</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="tel"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.fax}
                                        name="fax"
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>


                    {/* Adresse */}
                    <IonItemDivider className="ion-no-padding" mode="md" style={{ marginTop: 16 }}>Adresse</IonItemDivider>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Straße und Hausnummer</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.street}
                                        name="street"
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="4" sizeMd="4" sizeLg="4" sizeXl="4">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Postleitzahl</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.zip_code}
                                        name="zip_code"
                                    />
                                </IonItem>
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="4" sizeMd="4" sizeLg="4" sizeXl="4">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Stadt</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.city}
                                        name="city"
                                    />
                                </IonItem>
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="4" sizeMd="4" sizeLg="4" sizeXl="4">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Land</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.country}
                                        name="country"
                                    />
                                </IonItem>
                            </IonCol>

                        </IonRow>
                    </IonGrid>

                    {/* Fachliches */}
                    <IonItemDivider className="ion-no-padding" mode="md" style={{ marginTop: 16 }}>Fachliche Angaben</IonItemDivider>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Organisation*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                                disabled
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.organisation}
                                        name="organisation"
                                    />
                                </IonItem>
                            </IonCol>

                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Funktion</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonSelect
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                interface="alert"
                                                cancelText="Abbrechen"
                                            >
                                                {professions.map((profession) => <IonSelectOption value={profession.id} key={profession.id}>{profession.name}</IonSelectOption>)}
                                            </IonSelect>
                                        )}
                                        control={control}
                                        defaultValue={user.function}
                                        name="function"
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Funktion (andere)</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        defaultValue={user.function_other}
                                        name="function_other"
                                    />
                                </IonItem>
                            </IonCol>

                            <IonCol size="12">
                                <IonItem className="ion-no-padding">
                                    <IonLabel position="stacked">Experte für</IonLabel>

                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonSelect
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                interface="alert"
                                                cancelText="Abbrechen"
                                                multiple={true}
                                            >
                                                {user.expert_values.map((expert) => <IonSelectOption value={expert.id} key={expert.id}>{expert.name}</IonSelectOption>)}
                                            </IonSelect>
                                        )}
                                        control={control}
                                        defaultValue={user.expert_user_values}
                                        name="expert_user_values"
                                    />
                                </IonItem>
                            </IonCol>


                            {/* <IonCol size="12">
                                <IonItem className="ion-no-padding multipleSelectItem" lines="none">
                                    <IonLabel position="stacked">Experte für</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Select
                                                onChange={onChange }
                                                className="multipleSelect"
                                                sx={{
                                                    icon: { pr: 16 }
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                multiple
                                                variant="standard"
                                                renderValue={(selected) => {
                                                    let temp = [];
                                                    user.expert_values.map(expert => {
                                                        if (selected.indexOf(expert.id) > -1) temp.push(expert.name)
                                                    })
                                                    return temp.join(', ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {user.expert_values.map((expert) =>
                                                    <MenuItem value={expert.id} key={expert.id}>
                                                        <Checkbox checked={value.indexOf(expert.id) > -1} />
                                                        <ListItemText primary={expert.name} />
                                                    </MenuItem>)
                                                }
                                            </Select>
                                        )}
                                        control={control}
                                        defaultValue={user.expert_user_values}
                                        name="expert_user_values"
                                    />

                                </IonItem>
                            </IonCol> */}


                        </IonRow>
                    </IonGrid>



                    <div className="requiredLabel ion-float-right">*Pflichtfeld</div>
                </form>

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => closeModal(false)}>Abbrechen</IonButton>
                        <IonButton onClick={handleSubmit(onSubmit)} /* onClick={() => setShow2ndModal(true)} */ color="primary" fill="solid" type="submit">Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>

        </IonModal>
    )
}

export default ProfileEditModal;