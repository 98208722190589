import React, { useState } from "react";
import { IonButton, IonCard, IonCardContent, IonLabel } from "@ionic/react";

import "../Newsfeed.css";
import { Author } from "../NewsfeedInterfaces";

type AuthorInfoProps = {
    author: Author,
    width: number
}

const AuthorInfo: React.FC<AuthorInfoProps> = ({ author, width }: AuthorInfoProps) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);

    return (
        <IonCard style={width > 1900 ? { marginTop: 44 } : { marginTop: 10 }} className="articleDetailCard">

            <IonCardContent>
                <div className="authorContainer" >
                    <img className="authorImage" src={author.profile_pic ? author.profile_pic : "./assets/images/profile_placeholder.svg"} loading="lazy" alt="Profilbild des Autors"/>

                    <IonLabel className="authorlabel">
                        <h3 style={{ color: "black", fontSize: ".95rem" }}>{author.vorname} {author.name}</h3>
                        <p>{author.position}</p>
                    </IonLabel>

                    <div className="authorButtons">
                        {author.active === 1 && <IonButton size="small" href={`mailto:${author.email}`}>Kontakt</IonButton>}
                        {author.activities && <IonButton size="small" color="tertiary" onClick={() => setShowInfo(!showInfo)}>{showInfo ? "weniger Infos" : "mehr Infos"}</IonButton>}
                    </div>

                    {showInfo && author.activities && <div className="authorActivities">
                        <div>
                            <h4>Tätigkeitsschwerpunkte</h4>
                            <p dangerouslySetInnerHTML={{ __html: author.activities }}></p>
                        </div>
                    </div>}
                </div>

            </IonCardContent>
        </IonCard>
    )
}

export default AuthorInfo;