import React from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonButton, IonCard, IonCardContent, IonContent, IonIcon, IonPage, IonToast, useIonAlert } from '@ionic/react';
import { format } from 'date-fns';
import { addOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomLoading, Header, Tooltip } from '../../components';
import { addAndEditJob, andAndEditJobImage, deleteJob, setJobLabels, setJobs } from '../../redux/jobs/jobs.actions';
import { RootState } from '../../redux/reducers';
import { isObjectEmpty } from '../../utils/general-helpers';
import { ProfileImageModal } from '../profile/profileComponents';
import { ErrorToast } from '../statistics/StatisticsInterfaces';
import { JobDialog } from './jobComponents/JobDialog';
import JobTable from './jobComponents/JobTable';
import { initialJobValues } from './JobData';
import { Job, JobFields, JobLabel } from './JobInterfaces';


const JobsOverview: React.FC = () => {
  const dispatch = useDispatch();
  const jobs: Job[] = useSelector((state: RootState) => state.jobs.jobs);
  const user = useSelector((state: RootState) => state.user.user);
  const loading: boolean = useSelector((state: RootState) => state.jobs.loading);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<JobFields>(initialJobValues);
  const types: JobLabel[] = useSelector((state: RootState) => state.jobs.jobTypes);
  const jobNames: JobLabel[] = useSelector((state: RootState) => state.jobs.jobNames);
  const [title, setTitle] = useState<string>("");
  const [messageToast, setMessageToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" });
  const [present] = useIonAlert();
  const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!isObjectEmpty(user)) {
      dispatch(setJobs());
      dispatch(setJobLabels());
      setFormValues({...formValues, contact: {
        id: user.contact.contact_id,
        firma: user.contact.contact_name,
        ort: user.contact.contact_city
      }})
    }
  }, [user, dispatch])


  const callbackModal = () => {
    setShowImageModal(false);
  }

  const onLogoClick = (dataIndex) => {
    setCurrentImageIndex(dataIndex);
    setShowImageModal(true);
  }

  const takePhoto = async () => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
        quality: 70,
        allowEditing: true,
        promptLabelHeader: "Foto",
        promptLabelCancel: "Abbrechen",
        promptLabelPhoto: "Galerie",
        promptLabelPicture: "Kamera",
        width: 480,
        height: 480,
      })

      let blob = await fetch(cameraPhoto.dataUrl).then(r => r.blob());
      uploadJobPic(blob);

    } catch (e) {
      console.log(e)
    }
  }

  const uploadJobPic = async (base64) => {
    if (currentImageIndex !== null) {
      let data = new FormData();
      let id = jobs[currentImageIndex].id
      data.append("id", String(id));
      data.append("logo", base64);
      dispatch(andAndEditJobImage(data))
    }
  }

  const removeJobPic = async () => {
    if (currentImageIndex !== null) {
      let data = new FormData();
      data.append("id", String(jobs[currentImageIndex].id));
      data.append("logo", null);
      dispatch(andAndEditJobImage(data))
    }
  }

  const onSave = (vals: JobFields) => {
    vals.date = format(new Date(), "yyyy-MM-dd");
    vals.contact_id= vals.contact.id;
    delete vals.contact;

    dispatch(addAndEditJob(vals))
      //@ts-ignore
      .then(() => setMessageToast({ open: true, message: vals.id ? 'Stellenanzeige erfolgreich bearbeitet' : 'Stellenanzeige erfolgreich erstellt', color: 'success' }))
      .catch(() => setMessageToast({ open: true, message: vals.id ? 'Fehler beim Bearbeiten der Stellenanzeige' : 'Fehler beim Erstellen der Stellenanzeige', color: 'danger' }))

    //console.log(vals);
    setIsModalOpen(false);
  }

  const onDelete = (id: number) => {
    dispatch(deleteJob(id))
      //@ts-ignore
      .then(() => setMessageToast({ open: true, message: 'Stellenanzeige gelöscht', color: 'success' }))
      .catch(() => setMessageToast({ open: true, message: 'Fehler beim Löschen der Stellenanzeige', color: 'danger' }))
  }

  const onEditClick = (dataIndex) => {
    setFormValuesFromRow(jobs[dataIndex])
    //console.log(jobs[dataIndex])
    setTitle("Stellenanzeige bearbeiten");
    setIsModalOpen(true)
  }

  const onAddClick = () => {
    if(user.member){
      initialJobValues.contact = {
        id: user.contact.contact_id,
        firma: user.contact.contact_name,
        ort: user.contact.contact_city
      }
    }
    setFormValues(initialJobValues);
    setTitle("Stellenanzeige erstellen");
    setIsModalOpen(true)
  }

  const setFormValuesFromRow = (row) => {
    setFormValues({
      id: row.id,
      date: row.date,
      text: row.text,
      name: row.name,
      link: row.link,
      typ: row.typ,
      job_name_id: row.job_name_id,
      contact: {
        id: row.org_id,
        firma: row.org_name,
        ort: row.org_city
      }
    })
  }

  const renderAddButton = () => {
    if (temporaryLoggedIn === 1) {
      return (
        <Tooltip content='Login erforderlich'>
          <IonButton disabled>
            <IonIcon icon={addOutline} />
          </IonButton>
        </Tooltip>
      )
    } else {
      return (
        <IonButton onClick={onAddClick}>
          <IonIcon icon={addOutline} />
        </IonButton>
      )
    }
  }

  return (
    <IonPage>
      <Header name="Stellenanzeigen" />
      <IonContent fullscreen>
        <div className="content jobs">
          <IonCard className="jobCard">
            <IonCardContent>
              <div style={{ textAlign: 'right' }}>
                {renderAddButton()}
              </div>
              <JobTable jobs={jobs} isMember={user.member} editAction={onEditClick} onLogoClick={onLogoClick} deleteAction={(jobId) => {
                present({
                  cssClass: 'my-css',
                  header: 'Stellenanzeige Löschen',
                  message: `Möchten Sie die Stellenanzeige wirklich löschen?`,
                  buttons: [
                    'Abbrechen',
                    { text: 'Löschen', handler: () => onDelete(jobId) },
                  ],
                })
              }} />
            </IonCardContent>
          </IonCard>
        </div>
        
        <JobDialog
          isOpen={isModalOpen}
          handleClose={setIsModalOpen}
          title={title}
          actionText={'Speichern'}
          action={onSave}
          prevValue={formValues}
          types={types}
          jobNames={jobNames}
        />

        <IonToast
          isOpen={messageToast.open}
          message={messageToast.message}
          color={messageToast.color}
          duration={3000}
          onDidDismiss={() => setMessageToast(prevState => ({
            ...prevState,
            open: false
          }))}
          buttons={[
            {
              icon: closeOutline,
              role: 'cancel',
            }
          ]}
        />
        <ProfileImageModal isOpen={showImageModal} callback={callbackModal} photo={(currentImageIndex !== null && jobs[currentImageIndex]) ? jobs[currentImageIndex].logo : ""} updatePhoto={takePhoto} removePhoto={removeJobPic} title="Logo" />
      </IonContent>
      <CustomLoading isOpen={loading}/>
    </IonPage>
  );
};

export default JobsOverview;
