/**
 *
 * Dialog-Fenster für das Speichern einer Statistik
 * - alle gesetzten Daten werden noch ein mal Übersichtich aufgelistet
 * - User kann auswählen, für wen Statistik gespeichert werden soll
 *
 */

import React, { useEffect, useState } from "react";
import { IonModal, IonButton, IonHeader, IonTitle, IonToolbar, IonContent, IonButtons, IonIcon, IonItem, IonGrid, IonCol, IonRow, IonFooter, IonLabel, IonCheckbox, IonSelect, IonSelectOption } from "@ionic/react";
import "../Statistics.css";
import { closeOutline } from 'ionicons/icons';
import { Aggregate, CustomStatisticLabels, Datarow, Scale } from "../StatisticsInterfaces";
import http from "../../../utils/http-common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { StatisticCategory } from "../../statisticCategories/StatisticCategoriesInterfaces";
import { setStatisticCategories } from "../../../redux/statisticCategories/statisticCategories.actions";

type SaveStatisticProps = {
    isOpen: boolean,
    callback: Function,
    labels: CustomStatisticLabels,
    meta: any,
    datarows: Datarow[],
    aggregates: Aggregate[],
    yScale: Scale,
    editMode: boolean,
    id?: string | number,
    saveFor: object,
    startYear: Date | null,
    endYear: Date | null,
    compare: boolean,
    period_type: string,
    compareLineType: string,
    oldCategory?: number | null,
}

const SaveStatisticModal: React.FC<SaveStatisticProps> = ({ isOpen, callback, labels, meta, datarows, aggregates, yScale, editMode, id, saveFor, startYear, endYear, compare, period_type, compareLineType, oldCategory }: SaveStatisticProps) => {
    const [userSave, setUserSave] = useState<boolean>(true);
    const [employeeSave, setEmployeeSave] = useState<boolean>(false);
    const [memberSave, setMemberSave] = useState<boolean>(false);
    const userId = useSelector((state: RootState) => state.user.user.id);
    const [selectedCategory, setSelectedCategory] = useState<number | null>();
    const categories: StatisticCategory[] = useSelector((state: RootState) => state.statisticCategories);
    const dispatch = useDispatch();

    const closeModal = () => {
        callback();
    }

    useEffect(() => {
        //@ts-ignore
        setEmployeeSave(saveFor.employee);
        //@ts-ignore
        setMemberSave(saveFor.member)
        //@ts-ignore
        setUserSave(saveFor.user)
    }, [saveFor])

    useEffect(() => {
        dispatch(setStatisticCategories());
    }, [])

    useEffect(() => {
        setSelectedCategory(oldCategory)
    }, [oldCategory])

    // Wenn in editMode, wird Statistik geupdatet 
    // wenn nicht, wenn neue custom Statistik angelegt
    const saveStatistic = async () => {
        let data = {
            base_identifier: meta.base_identifier,
            source: meta.source,
            title: labels.title,
            subtitle: labels.subtitle,
            footer: labels.footer,
            y_scale_min: yScale.y_scale_min,
            y_scale_max: yScale.y_scale_max,
            filter: JSON.stringify(datarows),
            aggregates: JSON.stringify(aggregates),
            user: userSave ? userId : null,
            employee: employeeSave,
            member: memberSave,
            period_type: period_type,
            period_start: startYear ? format(startYear, "yyyy-MM-dd") : null,
            period_end: endYear ? format(endYear, "yyyy-MM-dd") : null,
            compare: compare ? 1 : 0,
            compare_line_type: compareLineType,
            custom_category_id: selectedCategory
        }
        if (editMode) {
            await http
                .put(`/update_custom_statistic?id=${id}`, data)
                .then(function (response) {
                    callback("success")
                })
                .catch(function (error) {
                    console.log(error)
                    callback("error");
                })
        } else {
            await http
                .post(`/insert_custom_statistic`, data)
                .then(function (response) {
                    callback("success")
                })
                .catch(function (error) {
                    console.log(error)
                    callback("error");
                })
        }
    }

    const mapStyleValues = (value) => {
        switch (value) {
            case "transparent": return "Transparent";
            case "dotted": return "Gepunktet";
            case "dashed": return "Gestrichelt";
            default: return "";
        }
    }

    return (
        <IonModal isOpen={isOpen} className="customModal">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>
                        Statistik speichern
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => closeModal()}>
                            <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

                <IonGrid>
                    <IonRow>
                        <IonCol size="12"><h5>Übersicht</h5></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="4">Statistik-Nummer:</IonCol>
                        <IonCol size="8">
                            {meta.base_identifier || ""}
                        </IonCol>
                        <IonCol size="4">Name:</IonCol>
                        <IonCol size="8">
                            {labels.title}
                        </IonCol>
                        <IonCol size="4">Untertitel:</IonCol>
                        <IonCol size="8">
                            {labels.subtitle !== "" ? labels.subtitle : "-"}
                        </IonCol>
                        <IonCol size="4">Fußzeile:</IonCol>
                        <IonCol size="8">
                            {labels.footer !== "" ? labels.footer : "-"}
                        </IonCol>
                        <IonCol size="4">Skalierung Y-Achse:</IonCol>
                        <IonCol size="8">
                            Min: {yScale.y_scale_min !== undefined ? yScale.y_scale_min : "automatisch"}<br />
                            Max: {yScale.y_scale_max !== undefined ? yScale.y_scale_max : "automatisch"}
                        </IonCol>
                        <IonCol size="4">Startzeitpunkt:</IonCol>
                        <IonCol size="8">
                            {startYear ? format(startYear, "MMM yyyy", { locale: de }) : "-"}
                        </IonCol>
                        <IonCol size="4">Endzeitpunkt:</IonCol>
                        <IonCol size="8">
                            {endYear ? format(endYear, "MMM yyyy", { locale: de }) : "-"}
                        </IonCol>
                        <IonCol size="4">Vergleich mit Vorzeitraum:</IonCol>
                        <IonCol size="8">
                            {compare ? "Ja" : "Nein"}
                        </IonCol>
                        {compare && <IonCol size="4">Linienstil Vorzeitraum:</IonCol>}
                        {compare && <IonCol size="8">{mapStyleValues(compareLineType)}</IonCol>}

                    </IonRow>

                    {datarows && datarows.length > 0 &&
                        <div style={{ marginTop: "20px" }}>
                            <IonRow><h5>Datenreihen</h5></IonRow>
                            <ul style={{ marginTop: 0 }}>
                                {datarows.map((datarow, index) => {
                                    return (
                                        <li key={index}>{datarow.filter_name}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    }

                    {aggregates && aggregates.length > 0 &&
                        <div style={{ marginTop: "20px" }}>
                            <IonRow><h5>Aggregate</h5></IonRow>
                            <ul style={{ marginTop: 0 }}>
                                {aggregates.map((aggregate, index) => {
                                    return (
                                        <li key={index}>{aggregate.aggregate_name}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    }

                    <IonRow><h5>Kategorie</h5></IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem className="ion-no-padding">
                                <IonLabel>Kategorie</IonLabel>
                                <IonSelect
                                    value={selectedCategory}
                                    onIonChange={e => setSelectedCategory(e.detail.value)}
                                    cancelText="Abbrechen"
                                >
                                    {categories.map((category) => {
                                        return (<IonSelectOption key={category.id} value={category.id}>{category.label}</IonSelectOption>)
                                    })}
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                    </IonRow>


                    <IonRow><h5>Optionen</h5></IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className="checkboxItem ion-no-padding">
                                <IonLabel>Für mich speichern</IonLabel>
                                <IonCheckbox
                                    slot="start"
                                    className="checkbox"
                                    color="secondary"
                                    checked={userSave}
                                    onIonChange={(e) => setUserSave(e.detail.checked)}
                                />
                            </IonItem>
                            <IonItem lines="none" className="checkboxItem ion-no-padding">
                                <IonLabel>Für Kollegen speichern</IonLabel>
                                <IonCheckbox
                                    slot="start"
                                    className="checkbox"
                                    color="secondary"
                                    checked={employeeSave}
                                    onIonChange={(e) => setEmployeeSave(e.detail.checked)}
                                />
                            </IonItem>
                            <IonItem lines="none" className="checkboxItem ion-no-padding">
                                <IonLabel>Für Mitglieder speichern</IonLabel>
                                <IonCheckbox
                                    slot="start"
                                    className="checkbox"
                                    color="secondary"
                                    checked={memberSave}
                                    onIonChange={(e) => setMemberSave(e.detail.checked)}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => closeModal()}>Abbrechen</IonButton>
                        <IonButton color="primary" fill="solid" onClick={() => saveStatistic()}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default SaveStatisticModal;
