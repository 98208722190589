import { IonButton, IonItem, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { BlockPicker } from 'react-color';

import './ColorPicker.css';

type ColorPickerProps = {
    color: string,
    callback?: (filedId: string, index: number) => void,
    index?: number,
}

const colorPalette = [
    '#1d405a',
    '#ee8000',
    '#b71c1c',
    '#880e4f',
    '#1565c0',
    '#006064',
    '#558b2f',
    '#fbc02d'
]

const ColorPicker: React.FC<ColorPickerProps> = ({ color, callback, index }: ColorPickerProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    }


    return (
        <div >
            <IonItem className="customItem ion-no-padding">
                <IonLabel position="stacked">Farbe*</IonLabel>
                <div style={{ display: "flex" }}>
                    <div className="colorContainer" style={{ backgroundColor: color }}></div>
                    <IonButton className="colorButton" fill="clear" onClick={handleButtonClick}>{isOpen ? "Schließen" : "Farbe ändern"}</IonButton>
                </div>
            </IonItem>

            {isOpen &&
                <div className="popover">
                    <div>
                        <BlockPicker colors={colorPalette} color={color} onChange={(color) => callback(color.hex, index)} />
                    </div>
                </div>
            }
        </div>
    )
}

export default ColorPicker;