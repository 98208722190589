import http from "../../utils/http-common";
import {
  ADD_STATISTIC_CATEGORY,
  SET_STATISTIC_CATEGORIES,
  UPDATE_STATISTIC_CATEGORY,
  DELETE_STATISTIC_CATEGORY,
} from "./statisticCategories.types";

export const setStatisticCategories = () => async (dispatch) => {
  try {
    const res = await http.get("/customStatisticCategories");
    dispatch({
      type: SET_STATISTIC_CATEGORIES,
      payload: res.data.categories,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addStatisticCategory = (label: string) => async (dispatch) => {
  try {
    const res = await http.post("/addCustomStatisticCategory", {
      label: label,
    });
    dispatch({
      type: ADD_STATISTIC_CATEGORY,
      payload: res.data.category,
    });

    return Promise.resolve(res.data.category);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteStatisticCategory = (id: number) => async (dispatch) => {
  try {
    await http.delete(`/deleteCustomStatisticCategory/${id}`);

    dispatch({
      type: DELETE_STATISTIC_CATEGORY,
      payload: { id },
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateStatisticCategory =
  (id: number, label: string) => async (dispatch) => {
    try {
      const res = await http.put(`/updateCustomStatisticCategory`, {
        id: id,
        label: label,
      });

      dispatch({
        type: UPDATE_STATISTIC_CATEGORY,
        payload: res.data.category,
      });

      return Promise.resolve(res.data.category);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const updateOrder = (categories) => (dispatch) => {
  dispatch({
    type: SET_STATISTIC_CATEGORIES,
    payload: categories,
  });
};
