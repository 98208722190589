import { IonButton, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './Login.css';
import '../Style.css';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { loginUser } from "../../redux/user/user.actions";
import { RootState } from '../../redux/reducers';
import { isObjectEmpty, validateEmail } from '../../utils/general-helpers';
import { ErrorToast } from '../statistics/StatisticsInterfaces';

const Login: React.FC<RouteComponentProps> = ({ history, match }) => {
    const [values, setValues] = useState({ email: "", password: "" });
    const dispatch = useDispatch();
    const errorsFromApi = useSelector((state: RootState) => state.errors)
    const [mailError, setMailError] = useState<boolean>(false);
    const [emptyMailError, setEmptyMailError] = useState<boolean>(false);
    const [emptyPasswordError, setEmptyPasswordError] = useState<boolean>(false);
    const isSignedIn = useSelector((state: RootState) => state.user.isAuthenticated);
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin);
    const [toast, setToast] = useState<ErrorToast>({ open: false, message: "", color: "danger" });

    useEffect(() => {
        checkIfAlreadySignedIn();
    }, [isSignedIn, temporaryLoggedIn])

    const checkIfAlreadySignedIn = () => {
        if (isSignedIn === true && temporaryLoggedIn === 0) {
            history.push("/mitgliederbereich/newsfeed")
            history.go(0);
        }
    }

    useEffect(() => {
        if (errorsFromApi.error) {
            setToast({open: true, message: errorsFromApi.error, color: "danger"})
        } else if(isObjectEmpty(errorsFromApi)) {
            setToast({open: false, message: "", color: "success"})
        }
    }, [errorsFromApi])

    const handleChange = (fieldId) => (e) => {
        if (fieldId === "email") setEmptyMailError(false);
        if (values.password === "") setEmptyPasswordError(false)
        setValues({ ...values, [fieldId]: e.target.value });
    };

    const handleOnBlur = (fieldId) => (e) => {
        //validate Email
        if (fieldId === "email") {
            if (values.email === "") { setEmptyMailError(true) }
            else {
                if (validateEmail(values[fieldId])) {
                    setMailError(false)
                } else {
                    setMailError(true);
                }
            }
        }
        if (fieldId === "password") {
            if (values.password === "") { setEmptyPasswordError(true) }
        }
    }

    const handleLogin = (e: any) => {
        e.preventDefault();
        if (!emptyMailError && !emptyPasswordError && !mailError) dispatch(loginUser(values));
    }

    const onKeyUpValue = (e: any) => {
        if (e.keyCode === 13) {
            handleLogin(e);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center ion-align-items-center ion-justify-items-center">
                <div className="loginContainer container ion-justify-items-center">
                    <IonCard className="card">
                        <img src="/assets/images/desh-Logo-blau.png" className="logo" alt="DeSH Logo" />
                        <IonCardContent >
                            <IonItem className="loginItem ion-no-padding">
                                <input
                                    value={values.email}
                                    type="email"
                                    onChange={handleChange("email")}
                                    placeholder="E-Mail"
                                    className="loginInput"
                                    onBlur={handleOnBlur("email")}
                                    required
                                />
                            </IonItem>
                            {mailError && <div style={{ textAlign: "left", color: "#eb445a" }}>Ungültige E-Mail-Adresse</div>}
                            {emptyMailError && <div style={{ textAlign: "left", color: "#eb445a" }}>Bitte geben Sie eine E-Mail-Adresse an</div>}
                            <IonItem className="loginItem ion-no-padding">
                                <input
                                    value={values.password}
                                    type="password"
                                    onChange={handleChange("password")}
                                    placeholder="Passwort"
                                    className="loginInput"
                                    onBlur={handleOnBlur("password")}
                                    required
                                    onKeyUp={onKeyUpValue.bind(this)}
                                />
                            </IonItem>

                            {emptyPasswordError && <div style={{ textAlign: "left", color: "#eb445a" }}>Bitte geben Sie ein Passwort an</div>}
                            <IonButton className="loginButton" type="submit" onClick={handleLogin} >Login</IonButton>
                            <p style={{ fontSize: 14, marginTop: 10 }}><Link to='/passwort-vergessen'>Passwort vergessen?</Link></p>
                        </IonCardContent>
                    </IonCard>
                </div>
            </IonContent>
            <IonToast
                isOpen={toast.open}
                onDidDismiss={() => setToast({open: false, message: "", color: "danger"})}
                message={toast.message}
                duration={2000}
                color={toast.color}
            />
        </IonPage>
    )
}

export default withRouter(Login);
