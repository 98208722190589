
import React from 'react';
import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "#000",
  "&.Mui-selected": {
    color: "#000"
  },
  "&.Mui-focusVisible": {
    opacity: 1,
  },
  letterSpacing: 0,
}));

export default StyledTab;