/**
 *
 * Card für Aggregate
 *
 */

import React from "react";
import {
    IonButton,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol,
    IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption
} from "@ionic/react";

import "../Statistics.css";
import { Aggregate } from "../StatisticsInterfaces";

type AggregatesProps = {
    dataRows: object,
    aggregates: Aggregate[],
    setAggregates: (i: number, checked: any, type: string) => void,
    aggregatableIndex: number,
    openAggregateModal: (id: number, name: string, index: number) => void,
    readableAggregateNames: string[][],
}

const Aggregates: React.FC<AggregatesProps> = ({ dataRows, aggregates, setAggregates, aggregatableIndex, openAggregateModal, readableAggregateNames }: AggregatesProps) => {

    // Anzeige, welche Datenreihen ausgewählt wurden 
    const displaySelected = (i) => {
        if (readableAggregateNames[i].length > 0) {
            return (<p style={{ opacity: 0.5, color: "#000000", paddingLeft: 8 }}>Gewählte Datenreihen: {readableAggregateNames[i].join(", ")}</p>);
        }
    }

    const createElements = () => {
        var elements = []
        for (let i = 0; i < aggregates.length; i++) {
            if (aggregates.length > 0 && dataRows) {
                elements.push(
                    <IonRow key={i} className="customAggregateRow">
                        <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4">
                            <IonItem lines="none" className="checkboxItem ion-no-padding" style={{ paddingTop: 6 }}>
                                <IonLabel>Aggregatfunktion #{i + 1}</IonLabel>
                                <IonCheckbox
                                    color="secondary"
                                    slot="start"
                                    className="checkbox"
                                    checked={aggregates[i].checked}
                                    onIonChange={e => setAggregates(i, e.detail.checked, "checked")}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4">
                            <IonItem className="customItem ion-no-padding">
                                <IonLabel className="customSelectLabel">Methode{aggregates[i].checked && "*"}</IonLabel>
                                <IonSelect value={aggregates.length > 0 ? aggregates[i].aggregate_method : ""} interface="popover" className="customSelect" onIonChange={e => setAggregates(i, e.detail.value, "aggregate_method")}>
                                    <IonSelectOption value="add">Addition</IonSelectOption>
                                    <IonSelectOption value="mean">Mittelwert</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4">
                            <IonButton
                                className="ion-float-right"
                                fill="clear"
                                style={{ marginTop: "11px" }}
                                onClick={() => openAggregateModal(dataRows[aggregatableIndex - 1]["id"], dataRows[aggregatableIndex - 1]["value"], i)}
                            >
                                Datenreihen wählen{aggregates[i].checked && "*"}
                            </IonButton>
                        </IonCol>
                        <IonCol size="12" className="ion-no-padding">
                            {displaySelected(i)}
                        </IonCol>
                    </IonRow>
                )
            }

        }
        return elements;
    }

    return (
        <IonCard className="normalCard">
            <IonCardHeader>
                <IonCardTitle className="cardTitle">Aggregate</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonGrid className="noPaddingGrid">
                    {createElements()}
                </IonGrid>
                <div className="requiredLabel ion-float-right">*Pflichtfeld</div>
            </IonCardContent>
        </IonCard>
    );

}

export default Aggregates;