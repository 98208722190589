/**
 *
 * Dialog-Fenster für erweiterte Einstellungen
 * Hier kann der User Titel, Untertitel, Footer-Text, Skalierung etc bearbeiten,
 * sowie die resultierenden Datenreihen und Aggregate Anpassen (Name, Linienstärke, Farbe) 
 *
 */

import React, { useState, useEffect } from "react";
import { IonModal, IonInput, IonButton, IonHeader, IonTitle, IonToolbar, IonContent, IonButtons, IonIcon, IonItem, IonLabel, IonItemDivider, IonGrid, IonCol, IonRow, IonFooter, IonCheckbox, IonSelectOption, IonSelect } from "@ionic/react";
import { closeOutline } from 'ionicons/icons';

import "../Statistics.css";
import { Datarow, CustomStatisticLabels, Scale, Aggregate } from "../StatisticsInterfaces";
import { ColorPicker } from "../../../components";

type GraphSettingsProps = {
    isOpen: boolean,
    closeModal: () => void;
    labels: CustomStatisticLabels | undefined,
    yScaleState: Scale,
    applySettings: (settings: object, customDatarows: Datarow[], yScaleState: Scale, customAggregates: Aggregate[], compareType: string) => void;
    datarows: Datarow[],
    aggregates: Aggregate[],
    compareLineType: string | null,
    compare: boolean,
}

const GraphSettingsModal: React.FC<GraphSettingsProps> = ({ isOpen, closeModal, labels, applySettings, datarows, yScaleState, aggregates, compareLineType, compare }: GraphSettingsProps) => {
    const [automaticScaling, setAutomaticScaling] = useState<boolean>();
    const [customLabels, setCustomLabels] = useState({
        title: "",
        subtitle: "",
        footer: "",
    })
    const [yScale, setYScale] = useState<Scale>({ y_scale_min: undefined, y_scale_max: undefined })
    const [customDatarows, setCustomDatarows] = useState([]);
    const [customAggregates, setCustomAggregates] = useState([]);
    const [datarowError, setDatarowError] = useState<boolean[]>([]);
    const [aggregateError, setAggregateError] = useState<boolean[]>([]);
    const [compareType, setCompareType] = useState<string|null>(null);


    // die nachfolgenden useEffect-Methoden updaten den hier intialen State mit den State-Werten, die in StatisticDetail schon geändert wurden
    useEffect(() => {
        if (labels) {
            setCustomLabels({
                title: labels.title,
                subtitle: labels.subtitle,
                footer: labels.footer,
            })
        }
    }, [labels])

    useEffect(() => { // wenn nicht gesetzt, kommt als null und nicht undefined
        if (yScaleState.y_scale_min !== undefined || yScaleState.y_scale_max !== undefined) {
            setAutomaticScaling(false)
        } else { setAutomaticScaling(true) }
        setYScale({ y_scale_min: yScaleState.y_scale_min, y_scale_max: yScaleState.y_scale_max })
    }, [yScaleState])

    useEffect(() => {
        if (datarows) {
            setCustomDatarows(datarows);
        }
    }, [datarows])

    useEffect(() => {
        if (aggregates) {
            setCustomAggregates(aggregates);
            let errors = [];
            aggregates.forEach(() => {
                errors.push(false);
            })
            setAggregateError(errors)
        }
    }, [aggregates])

    useEffect(() => {
        if(compare){
            if(compareLineType){
                setCompareType(compareLineType);
            }
        }
    }, [compare, compareLineType])


    const handleLabelChange = (fieldId) => (e) => {
        setCustomLabels({ ...customLabels, [fieldId]: e.target.value });
    }

    const handleAutomaticScaleChange = () => (e) => {
        if (e.detail.checked === true) {
            setYScale({ y_scale_min: undefined, y_scale_max: undefined })
        }
        setAutomaticScaling(e.detail.checked);
    }

    const handleScaleChange = (fieldId) => (e) => {
        if (e.target.value === "") {
            // nur bei undefined wird Graph von Chart.js automatisch skaliert
            setYScale({ ...yScale, [fieldId]: undefined })
        } else {
            setYScale({ ...yScale, [fieldId]: e.target.value })
        }
    }

    const handleDatarowChange = (fieldId: string, index: number) => (e) => {
        let temp = [...customDatarows];
        temp[index][fieldId] = e.target.value;
        setCustomDatarows(temp);
        if (fieldId === "line_width") {
            if (1 <= parseInt(e.target.value) && parseInt(e.target.value) <= 9) {
                updateDatarowError(index, false);
            } else {
                updateDatarowError(index, true);
            }
        }
    }

    const handleDatarowColorChange = (color: string, index: number) => {
        let temp = [...customDatarows];
        temp[index].line_color = color;
        setCustomDatarows(temp);
    }

    const handleAggregateChange = (fieldId: string, index: number) => (e) => {
        let temp = [...customAggregates];
        temp[index][fieldId] = e.target.value;
        setCustomAggregates(temp);
        if (fieldId === "line_width") {
            if (1 <= parseInt(e.target.value) && parseInt(e.target.value) <= 9) {
                updateAggregateError(index, false);
            } else {
                updateAggregateError(index, true);
            }
        }
    }

    const handleAggregateColorChange = (color: string, index: number) => {
        let temp = [...customAggregates];
        temp[index].line_color = color;
        setCustomAggregates(temp);
    }

    const updateAggregateError = (index, value) => {
        let tempErrors = [...aggregateError];
        tempErrors[index] = value;
        setAggregateError(tempErrors);
    }

    const updateDatarowError = (index, value) => {
        let tempErrors = [...datarowError];
        tempErrors[index] = value;
        setDatarowError(tempErrors);
    }

    const handleApplySettingsButton = () => {
        if (!datarowError.includes(true) && !aggregateError.includes(true)) {
            applySettings(customLabels, customDatarows, yScale, customAggregates, compareType);
        }
    }

    return (
        <IonModal isOpen={isOpen} className="advancedSettingsModal">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Erweiterte Einstellungen</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => closeModal()}>
                            <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {/* Beschriftungen */}
                <div>
                    <IonItemDivider className="ion-no-padding itemDivider">Beschriftungen</IonItemDivider>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonItem className="customItem ion-no-padding">
                                    <IonLabel position="stacked">Titel</IonLabel>
                                    <IonInput className="customInput" value={customLabels.title} onIonChange={handleLabelChange("title")}></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem className="customItem ion-no-padding">
                                    <IonLabel position="stacked">Untertitel</IonLabel>
                                    <IonInput className="customInput" value={customLabels.subtitle} onIonChange={handleLabelChange("subtitle")}></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem className="customItem ion-no-padding">
                                    <IonLabel position="stacked">Fußzeile</IonLabel>
                                    <IonInput className="customInput" value={customLabels.footer} onIonChange={handleLabelChange("footer")}></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>

                {/* Skalierung y-Achse */}
                <div>
                    <IonItemDivider className="ion-no-padding itemDividerMargin itemDivider">Skalierung Y-Achse</IonItemDivider>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none" className="checkboxItem ion-no-padding">
                                    <IonLabel className="ion-text-wrap" style={{ paddingTop: 2 }}>Automatische Skalierung</IonLabel>
                                    <IonCheckbox
                                        color="secondary"
                                        slot="start"
                                        checked={automaticScaling}
                                        className="checkbox"
                                        onIonChange={handleAutomaticScaleChange()}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        {automaticScaling ||
                            <IonRow>
                                <IonCol size="5" sizeSm="5" sizeXs="10">
                                    <IonItem className="customItem ion-no-padding">
                                        <IonLabel position="stacked">Min</IonLabel>
                                        <IonInput type="number" value={yScale.y_scale_min} onIonChange={handleScaleChange("y_scale_min")}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="1" sizeSm="1" sizeXs="2" style={{ textAlign: "center", paddingTop: "32px" }}>bis</IonCol>
                                <IonCol size="6" sizeSm="6" sizeXs="12">
                                    <IonItem className="customItem ion-no-padding">
                                        <IonLabel position="stacked">Max</IonLabel>
                                        <IonInput type="number" value={yScale.y_scale_max} onIonChange={handleScaleChange("y_scale_max")}></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>}

                    </IonGrid>
                </div>

                {/* Datenreihen */}
                {customDatarows.length > 0 &&
                    <div>
                        <IonItemDivider className="ion-no-padding itemDividerMargin itemDivider">Datenreihen</IonItemDivider>
                        <IonGrid>
                            {customDatarows && customDatarows.map((datarow, index) => {
                                return (
                                    <IonRow key={index}>
                                        <IonCol size="5" sizeXs="9" sizeSm="9" sizeMd="5" sizeLg="5" sizeXl="5">
                                            <IonItem className="customItem ion-no-padding">
                                                <IonLabel position="stacked">Anzeigename*</IonLabel>
                                                <IonInput type="text" value={datarow.filter_name} onIonChange={handleDatarowChange("filter_name", index)} debounce={1000}></IonInput>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size="3" sizeXs="3" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3">
                                            <IonItem className="customItem ion-no-padding">
                                                <IonLabel position="stacked">Linienstärke*</IonLabel>
                                                <IonInput type="number" value={datarow.line_width} onIonChange={handleDatarowChange("line_width", index)} /* debounce={1000} */></IonInput>
                                            </IonItem>
                                            {datarowError[index] && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>Wert muss zwischen 1 und 9 liegen</div>}
                                        </IonCol>
                                        <IonCol size="4" sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4" className="colorPickerCol">
                                            <ColorPicker color={datarow.line_color} index={index} callback={handleDatarowColorChange} />
                                        </IonCol>
                                    </IonRow>
                                )
                            })}
                        </IonGrid>
                    </div>
                }

                {/* Aggregate */}
                {customAggregates.length > 0 &&
                    <div>
                        <IonItemDivider className="ion-no-padding itemDividerMargin itemDivider">Aggregate</IonItemDivider>
                        <IonGrid>
                            {customAggregates && customAggregates.map((customAggregate, index) => {
                                return (
                                    <IonRow key={index}>
                                        <IonCol size="5" sizeXs="9" sizeSm="9" sizeMd="5" sizeLg="5" sizeXl="5">
                                            <IonItem className="customItem ion-no-padding">
                                                <IonLabel position="stacked">Anzeigename*</IonLabel>
                                                <IonInput type="text" value={customAggregate.aggregate_name} onIonChange={handleAggregateChange("aggregate_name", index)} ></IonInput>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonItem className="customItem ion-no-padding">
                                                <IonLabel position="stacked">Linienstärke*</IonLabel>
                                                <IonInput type="number" value={customAggregate.line_width} onIonChange={handleAggregateChange("line_width", index)} ></IonInput>
                                            </IonItem>
                                            {aggregateError[index] && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>Wert muss zwischen 1 und 9 liegen</div>}
                                        </IonCol>
                                        <IonCol size="4" sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4" className="colorPickerCol" >
                                            <ColorPicker color={customAggregate.line_color} callback={handleAggregateColorChange} index={index} />
                                        </IonCol>
                                    </IonRow>
                                )
                            })}
                        </IonGrid>
                    </div>
                }

                {compare &&
                    <div>
                        <IonItemDivider className="ion-no-padding itemDividerMargin itemDivider">Sonstiges</IonItemDivider>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonItem className="ion-no-padding">
                                        <IonLabel>Linienstil für Vergleich mit Vorzeitraum*</IonLabel>
                                        <IonSelect value={compareType} cancelText="Abbrechen" onIonChange={e => setCompareType(e.detail.value)}>
                                            <IonSelectOption value="transparent">transparent</IonSelectOption>
                                            <IonSelectOption value="dashed">gestrichelt</IonSelectOption>
                                            <IonSelectOption value="dotted">gepunktet</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>}
                <div className="requiredLabel ion-float-right">*Pflichtfeld</div>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => closeModal()}>Schließen</IonButton>
                        <IonButton fill="solid" color="primary" onClick={() => handleApplySettingsButton()}>Übernehmen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default GraphSettingsModal;
