import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { Header } from "../../components";

const ForbiddenPage: React.FC = () => {
    return (
        <IonPage>
            <Header name="Mitgliederbereich DeSH"/>
            <IonContent>
                <div className="content" >
                <h1><strong>Fehler 401</strong> - Nicht autorisiert</h1>
                <p>Sie haben keinen Zugriff auf diese Seite.</p>
                </div>
            </IonContent>
        </IonPage >
    )
}

export default ForbiddenPage;