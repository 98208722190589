import React from "react";

export const timePeriodCalculation = (period_type, period_start, period_end) => {
    let start;
    let end;
    switch (period_type) {
        case "month12":
            start = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            end = new Date(new Date().setMonth(new Date().getMonth() - 1));
            break;
        case "month24":
            start = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
            end = new Date(new Date().setMonth(new Date().getMonth() - 1));
            break;
        case "yearThis":
            start = new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1));
            end = new Date(new Date().setMonth(11));
            break;
        case "yearLast":
            start = new Date(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1));
            end = new Date(new Date().setFullYear(new Date().getFullYear() - 1, 11, 1));
            break;
        case "customDate":
            start = new Date(period_start);
            end = new Date(period_end);
            break;
    }
    return ( {start: start, end: end} );
}