/**
 *
 * Parent-Component für die Auflistung der Statistiken
 * Enthält je nach Benutzerrolle eine Tab-Bar, mit der zwischen den Kategorien gewechselt werden kann
 * Tab 1: Von uns definierte Destatis Statistiken
 * Tab 2: Von Benutzern erstellte Statistiken (Freigabe nach User, Employee oder Member)
 *
 */

import React, { useState, useEffect } from "react"
import { IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton, IonToast, IonToolbar } from "@ionic/react";
import { useSelector } from "react-redux";

//Eigene Importe
import { CustomLoading, Header, /* StyledTab, StyledTabs, TabPanel */ } from "../../../components"; // Tabs sind custom Komponenten
import "../../Style.css";
import "../Statistics.css";
import Tab1 from "./Tab1"; // Inahlt für Tab 1
import Tab2 from "./Tab2"; // Inahlt für Tab 2-4
import http from "../../../utils/http-common";
import { RootState } from "../../../redux/reducers";
import { isObjectEmpty } from "../../../utils/general-helpers";



// Komponente
const StatisticsOverview: React.FC = () => {
    const [currentTab, setCurrentTab] = useState<string>("3")
    const [destatisState, setDestatisState] = useState(true);
    const [showDestatisErrorToast, setShowDestatisErrorToast] = useState(false);
    let user = useSelector((state: RootState) => state.user.user);
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin);
    const loading = useSelector((state: RootState) => state.statistics.statisticsLoading);

    // Destatis ist oft offline und benötigt daher ein Error-Handling
    useEffect(() => {
        window.dispatchEvent(new CustomEvent("resize"));
        let mounted = true;
        
        const fetchData = async () => {
            await http
                .get(`/isDestatisOnline`, {})
                .then(function (response) {
                    if (mounted) {
                        setDestatisState(response.data.isDestatisOnline);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
        fetchData();

        return function cleanup() {
            mounted = false
        }
    }, []);

    useEffect(() => {
        if(!isObjectEmpty(user) && temporaryLoggedIn !== 1 && !user.member){
            setCurrentTab("0")
        }
    }, [user, temporaryLoggedIn])

    const renderTabContent = (tabValue) => {
        switch (tabValue) {
            case "0":
                return (<Tab1 destatisState={destatisState} />);
            case "1":
                return (<Tab2 destatisState={destatisState} identifier="user" user={user} temporaryLoggedIn={temporaryLoggedIn} />);
            case "2":
                return (<Tab2 destatisState={destatisState} identifier="employee" user={user} temporaryLoggedIn={temporaryLoggedIn} />);
            case "3":
                return (<Tab2 destatisState={destatisState} identifier="member" user={user} temporaryLoggedIn={temporaryLoggedIn} />);
            default:
                return (<Tab2 destatisState={destatisState} identifier="member" user={user} temporaryLoggedIn={temporaryLoggedIn} />)
        }
    }


    return (
        <IonPage>
            <Header name="Statistiken" />

            {/* Member sehen nur für Member freigegebene Statistiken (ohne Tab-Bar) */}
            {!isObjectEmpty(user) && !user.member && temporaryLoggedIn !== 1 &&
                <IonToolbar>
                    <IonSegment mode="md" style={{ padding: "0 5px" }} scrollable={true} value={currentTab} onIonChange={(e) => setCurrentTab(e.detail.value)}>
                        <IonSegmentButton value="0">
                            <IonLabel>Destatis Statistiken</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="1">
                            <IonLabel>Meine Statistiken</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="2">
                            <IonLabel>Mitarbeiter Statistiken</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="3">
                            <IonLabel>Mitglieder Statistiken</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>}

            <IonContent>
                {/* Member sehen nur für Member freigegebene Statistiken (ohne Tab-Bar) */}
                <div className="content" >
                    {/* {!isObjectEmpty(user) && !user.member && temporaryLoggedIn !== 1 ?
                        renderTabContent(currentTab) :
                        <Tab2 destatisState={destatisState} identifier="member" user={user} temporaryLoggedIn={temporaryLoggedIn} />
                    } */}
                    {renderTabContent(currentTab)}
                </div>
            </IonContent >
            <IonToast
                header="Fehler"
                color="light"
                position="top"
                isOpen={showDestatisErrorToast}
                onDidDismiss={() => setShowDestatisErrorToast(false)}
                message="Leider ist der Destatis-Server aktuell nicht erreichbar"
                duration={2000}
            />
            <CustomLoading isOpen={loading} />
        </IonPage >
    )
}

export default StatisticsOverview;
