import React, { useEffect, useState } from "react";
import { IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, } from "@ionic/react";

import "../Newsfeed.css";
import { arrowForwardOutline, bookmarksOutline, chatbubblesOutline } from "ionicons/icons";
import http from "../../../utils/http-common";
import { useHistory } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { getLatestComments } from "../../../redux/articles/article.actions";
import { format } from "date-fns/esm";

type MerklisteProps = {
    setIsDrawerOpen?: (boolean) => void,
}

const Merkliste: React.FC<MerklisteProps> = ({ setIsDrawerOpen }: MerklisteProps) => {
    const [bookmarkedArticles, setBookmarkedArtickles] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const latestComments = useSelector((state: RootState) => state.article.latestComments, shallowEqual);
    const temporaryLoggedIn = useSelector((state: RootState) => state.user.temporaryLogin, shallowEqual);

    useEffect(() => {
        getBookmarkedArticles();
        dispatch(getLatestComments());
    }, [])

    const getBookmarkedArticles = async () => {
        let fetchCounter: number = 0;
        await http
            .get('/getBookmarkArticles')
            .then((response) => {
                //console.log("bookmarked", response)
                setBookmarkedArtickles(response.data)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === 'Authorization Token not found' && error.response.status === 400 && fetchCounter <= 1) {
                        /* getBookmarkedArticles();
                        fetchCounter++; */
                    } else {
                        console.log(error.response)
                    }
                } else { console.log(error) }
            })
    }


    return (
        <div>
            {/* <IonRow>
                <IonCol> */}
            {temporaryLoggedIn === 0 && <IonCard className="bookmarkCard" style={{maxWidth: 500}}>
                <IonCardContent>
                    <IonList className="bookmarkList" lines="none">
                        <IonListHeader>
                            <IonLabel style={{ fontSize: 18, fontWeight: "500" }}>
                                Lesezeichen
                                <IonIcon icon={bookmarksOutline} style={{ paddingRight: 16, fontSize: 24 }} className="ion-float-right" />
                            </IonLabel>

                        </IonListHeader>
                        {bookmarkedArticles.length > 0 && <div>
                            {bookmarkedArticles.map((bookmarkedArticle) => {
                                return (
                                    <IonItem
                                        className="bookmarkItem"
                                        key={bookmarkedArticle.desh_member_article_id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(`/mitgliederbereich/newsfeed-artikel/${bookmarkedArticle.desh_member_article_id}`);
                                            if (setIsDrawerOpen) {
                                                setIsDrawerOpen(false);
                                            }
                                        }}
                                    >
                                        <IonLabel style={{ whiteSpace: "normal" }}>{bookmarkedArticle.headline}</IonLabel>
                                        <IonIcon slot="end" icon={arrowForwardOutline} />
                                    </IonItem>
                                )
                            })}
                        </div>
                        }


                    </IonList>
                </IonCardContent>
            </IonCard>}

            {/* Kommentare */}
            <IonCard className="bookmarkCard" style={{ marginTop: 25, maxWidth: 500 }}>
                <IonCardContent>
                    <IonList className="bookmarkList" lines="none">
                        <IonListHeader>
                            <IonLabel style={{ fontSize: 18, fontWeight: "500" }}>
                                Letzte Diskussionen
                                <IonIcon icon={chatbubblesOutline} style={{ paddingRight: 16, fontSize: 24 }} className="ion-float-right" />
                            </IonLabel>
                        </IonListHeader>
                        {latestComments.length > 0 && <div>
                            {latestComments.map((latestComment) => {
                                return (
                                    <IonItem
                                        key={latestComment.id}
                                        className="bookmarkItem"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(`/mitgliederbereich/newsfeed-artikel/${latestComment.desh_member_article_id}`);
                                            if (setIsDrawerOpen) {
                                                setIsDrawerOpen(false);
                                            }
                                        }}
                                    >
                                        <IonLabel style={{ whiteSpace: "normal" }}>
                                            <div><span className="highlightedText">{latestComment.user ? latestComment.user.first_name + " " + latestComment.user.name : "Unbekannter Benutzer"}</span> kommentierte unter <span className="highlightedText">{latestComment.headline}</span></div>
                                            <div className="timestamp">{format(new Date(latestComment.timestamp), "dd.MM.yyyy HH:mm") }</div>
                                            <div className="merkliste-comment">{latestComment.comment.length > 100 ? latestComment.comment.substring(0,100) + "..." : latestComment.comment}</div>
                                        </IonLabel>
                                        <IonIcon slot="end" icon={arrowForwardOutline} />
                                    </IonItem>
                                )
                            })}
                        </div>}
                    </IonList>
                </IonCardContent>
            </IonCard>

            {/* </IonCol>
            </IonRow> */}
        </div>
    )
}

export default Merkliste;