import React, { Suspense, useEffect, useState, lazy } from 'react';
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { JobCompany, JobFields, JobLabel } from '../JobInterfaces';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/de";

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { setJobCompanies } from '../../../redux/jobs/jobs.actions';

import JobAutocomplete from "./JobAutocomplete";
import { ErrorBoundary } from '../../../components';


const LazyCKEditor = lazy(() => import("@ckeditor/ckeditor5-react").then(module => {
    return { default: module.CKEditor }
}));



type JobDialogProps = {
    isOpen: boolean,
    handleClose: (value: boolean) => void;
    title: string,
    actionText: string,
    action: (value) => void,
    prevValue: JobFields,
    types: JobLabel[],
    jobNames: JobLabel[],
}

export const JobDialog: React.FC<JobDialogProps> = ({ isOpen, handleClose, title, actionText, action, prevValue, types, jobNames }: JobDialogProps) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<JobFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
    });
    const contacts: JobCompany[] = useSelector((state: RootState) => state.jobs.companies)
    const [contactsOpen, setContactsOpen] = useState(false);
    const contactsLoading = contactsOpen && contacts.length === 0;
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);

    const [autocompleteDisabled, setAutocompleteDisabled] = useState<boolean>(false)

    const onSubmit: SubmitHandler<JobFields> = data => action(data);

    useEffect(() => {
        // reset form with data
        reset(prevValue);
        if (user.member) {
            if (user.org_has_related_orgs) {
                setAutocompleteDisabled(false);
            } else {
                setAutocompleteDisabled(true);
            }
        } else {
            if (prevValue.id) {
                setAutocompleteDisabled(true);
            } else {
                setAutocompleteDisabled(false);
            }
        }

    }, [prevValue, user]);


    /* const getCompanyOptionById = (id: number) => {
        return contacts.find(x => x.id === id);
    } */

    useEffect(() => {
        let active = true;
        if (!contactsLoading) {
            return undefined;
        }

        if (user.member && user.org_has_related_orgs) {
            dispatch(setJobCompanies(user.organisation_id));
        } else {
            dispatch(setJobCompanies());
        }


        return () => {
            active = false;
        };
    }, [contactsLoading, dispatch])

    return (
        <IonModal isOpen={isOpen} className="modalContainer fullScreenModal" backdropDismiss={false}>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle>{title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => handleClose(false)}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <form>
                    <IonGrid>
                        <IonRow>

                            {/* Jobtitel */}
                            <IonCol size='12' >
                                <IonItem className='ion-no-padding'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Jobtitel*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                        control={control}
                                        name="name"
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                        }}
                                        defaultValue={prevValue.name}
                                    />
                                </IonItem>
                                {errors.name && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.name.message}</div>}
                            </IonCol>

                            <IonCol>
                                <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <JobAutocomplete
                                            options={contacts}
                                            loading={contactsLoading}
                                            open={contactsOpen}
                                            setOpen={setContactsOpen}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            disabled={autocompleteDisabled}
                                        />
                                    )}
                                    control={control}
                                    name="contact"
                                    rules={{
                                        required: { value: true, message: "Feld darf nicht leer sein" },
                                    }}
                                />
                                {/* @ts-ignore */}
                                {errors.contact && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.contact.message}</div>}

                            </IonCol>

                            {/* Link */}
                            <IonCol size='12'>
                                <IonItem className='ion-no-padding'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Link zur Stellenanzeige</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="url"
                                            />
                                        )}
                                        control={control}
                                        name="link"
                                        rules={{
                                            pattern: { value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/, message: "Keine valide URL" },
                                        }}
                                        defaultValue={prevValue.link}
                                    />
                                </IonItem>
                                {errors.link && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.link.message}</div>}
                            </IonCol>

                            {/* Stellentyp */}
                            <IonCol size="6" sizeXs='12' sizeSm='6'>
                                <IonItem className='ion-no-padding'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Stellentyp*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonSelect
                                                /* onIonChange={onChange} */
                                                /* onIonBlur={onBlur} */
                                                onIonChange={(e) => onChange(e.detail.value)}
                                                value={value}
                                                cancelText="Abbrechen"
                                                className="jobDialogSelect"
                                            >
                                                {types.map(type =>
                                                    <IonSelectOption key={type.value} value={type.value}>{type.label}</IonSelectOption>
                                                )}
                                            </IonSelect>
                                        )}
                                        control={control}
                                        name="typ"
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                        }}
                                        defaultValue={prevValue.typ}
                                    />
                                </IonItem>
                                {errors.typ && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.typ.message}</div>}
                            </IonCol>

                            {/* Jobbezeichnung */}
                            <IonCol size="6" sizeXs='12' sizeSm='6'>
                                <IonItem className='ion-no-padding'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Jobbezeichnung*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonSelect
                                                onIonChange={(e) => {
                                                    onChange(e.detail.value)
                                                }}
                                                /* onIonBlur={(e) => onBlur()} */

                                                value={value}
                                                cancelText="Abbrechen"
                                            >
                                                {jobNames.map(jobName =>
                                                    <IonSelectOption key={jobName.value} value={jobName.value}>{jobName.label}</IonSelectOption>
                                                )}
                                            </IonSelect>
                                        )}
                                        control={control}
                                        name="job_name_id"
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                        }}
                                        defaultValue={prevValue.job_name_id}
                                    />
                                </IonItem>
                                {errors.job_name_id && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.job_name_id.message}</div>}
                            </IonCol>

                            {/* Description */}
                            <IonCol size="12">
                                <IonItem className='ion-no-padding' lines='none'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Beschreibung*</IonLabel>
                                </IonItem>
                                {ClassicEditor !== undefined &&
                                    <Controller
                                        control={control}
                                        name="text"
                                        defaultValue={prevValue.text}
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <ErrorBoundary>
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <LazyCKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                items: [
                                                                    "bold",
                                                                    "italic",
                                                                    "link",
                                                                    "bulletedList",
                                                                    "numberedList",
                                                                    "|",
                                                                    "undo",
                                                                    "redo",
                                                                ],
                                                            },
                                                            language: "de",
                                                            initialData: prevValue.text
                                                        }}
                                                        //data={value}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            onChange(data)
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            onBlur()
                                                        }}
                                                    />
                                                </Suspense>
                                            </ErrorBoundary>
                                        )}
                                    />}

                                {/* </IonItem> */}
                                {errors.text && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.text.message}</div>}

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div style={{ paddingBottom: "0 5px 12px 5px" }} className="requiredLabel ion-float-right">*Pflichtfeld</div>
                </form>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton fill='clear' onClick={() => handleClose(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color='primary' onClick={handleSubmit(onSubmit)}>{actionText}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}