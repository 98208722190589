import React from 'react';
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast } from "@ionic/react"
import { closeOutline } from "ionicons/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import http from "../../../utils/http-common";

interface OrderDialogProps {
    isOpen: boolean,
    handleClose: (value: boolean) => void;
    id: string
}

interface DocumentOrder {
    amount: number,
    msg: string,
    id: string
}

const OrderDialog: React.FC<OrderDialogProps> = ({ isOpen, handleClose, id }: OrderDialogProps) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<DocumentOrder>({
        mode: "onTouched",
        reValidateMode: "onChange",
    });
    const [present, dismiss] = useIonToast();



    const onSubmit: SubmitHandler<DocumentOrder> = async data => {

        data.id = id;
        console.log(data);
        await http
            .post("/directOrder", data)
            .then((res) => {
                present({
                    message: 'Bestellanfrage gesendet',
                    duration: 1000,
                    color: "success"
                })
                setTimeout(() => { handleClose(false); reset({ amount: null, msg: "" }) }, 1000);

            })
            .catch((err) => {
                console.log(err)
                present({
                    message: 'Fehler beim Senden der Bestellanfrage. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                    duration: 3000,
                    color: "danger"
                })
            })
        //handleClose(false);
    }



    return (
        <IonModal isOpen={isOpen} className="modalContainer">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>
                        Bestellanfrage senden
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => handleClose(false)}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <form>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonText>
                                    Über dieses Formular können Sie uns Ihre Bestellanfrage für das ausgewählte Dokument zukommen lassen. Wir setzen uns dann mit Ihnen in Verbindung.
                                </IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem className='ion-no-padding'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Anzahl*</IonLabel>
                                    <Controller
                                        rules={{
                                            required: { value: true, message: "Feld darf nicht leer sein" },
                                            /* pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: "Eingabe muss eine Zahl sein" }, */
                                        }}
                                        name="amount"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                type="number"
                                            />
                                        )}
                                    />
                                </IonItem>
                                {errors.amount && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.amount.message}</div>}
                            </IonCol>

                            <IonCol size="12">
                                <IonItem className='ion-no-padding'>
                                    <IonLabel position='stacked' className='ion-text-wrap'>Nachricht</IonLabel>
                                    <Controller
                                        name="msg"
                                        control={control}
                                        rules={{
                                            maxLength: { value: 500, message: "Nachricht zu lang, max. 500 Zeichen erlaubt" }
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonTextarea
                                                onIonChange={onChange}
                                                onIonBlur={onBlur}
                                                value={value}
                                                placeholder="Teilen Sie uns Ihre Wünsche und Anmerkungen für die Bestellung mit"
                                                rows={6}
                                                cols={20}
                                            />
                                        )}
                                    />
                                </IonItem>
                                {errors.msg && <div style={{ textAlign: "left", color: "#eb445a", fontSize: 14 }}>{errors.msg.message}</div>}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div style={{ paddingBottom: "0 5px 12px 5px" }} className="requiredLabel ion-float-right">*Pflichtfeld</div>
                </form>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton fill='clear' onClick={() => handleClose(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color='primary' onClick={handleSubmit(onSubmit)}>Senden</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default OrderDialog;